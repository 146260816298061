import { gql } from "@apollo/client";


export const GET_FLIGHT_CHECK_INS_QUERY = gql`
query GetFlightCheckIns(
  $guids: [String],
  $boardingPassGuid: String,
  $spaceRequestGuid: String,
  $flightGuid: String,
  $pageNumber: Int,
  $resultsPerPage: Int
) {
  flightCheckIns(
    guids: $guids,
    boardingPassGuid: $boardingPassGuid,
    spaceRequestGuid: $spaceRequestGuid
    flightGuid: $flightGuid
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt
    
    boardingPass {
      guid
      boarded
      owner {
        guid
        firstName
        middleName
        lastName
        email
        dodId

        sponsor {
          guid
          rank {
            service
            name
            grade
          }
          retired
        }
      }
    }

    travelerWeight
    carryOnWeight
  }
}
`;

export const CREATE_FLIGHT_CHECK_IN_MUTATION = gql`
mutation CreateFlightCheckIn(
  $boardingPassGuid: String!,
  $travelerWeight: Float!,
  $carryOnWeight: Float!
) {
  createFlightCheckIn(
    boardingPassGuid: $boardingPassGuid,
    travelerWeight: $travelerWeight,
    carryOnWeight: $carryOnWeight
  )
}
`;

export const DELETE_FLIGHT_CHECK_IN_MUTATION = gql`
mutation DeleteFlightCheckIn(
  $guid: String!
) {
  deleteFlightCheckIn(
    guid: $guid
  )
}
`;
