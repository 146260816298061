import moment from "moment";
import { GetTravelAuthorizations_travelAuthorizations, ReviewStatus, TravelAuthorizationCategory } from "../__generated__/api";

export const getLatestTravelAuthorizationReview = (auth: GetTravelAuthorizations_travelAuthorizations) => {
    return auth.reviewHistory.slice().pop();
};

export const organizeTravelAuths = (auths: GetTravelAuthorizations_travelAuthorizations[]) => {
    const unsubmitted: GetTravelAuthorizations_travelAuthorizations[] = [];
    const submitted: GetTravelAuthorizations_travelAuthorizations[] = [];
    const underReview: GetTravelAuthorizations_travelAuthorizations[] = [];
    const approvedActive: GetTravelAuthorizations_travelAuthorizations[] = [];
    const approvedExpired: GetTravelAuthorizations_travelAuthorizations[] = [];
    const denied: GetTravelAuthorizations_travelAuthorizations[] = [];
    auths.forEach((auth) => {
        const [latestReview] = auth.reviewHistory.slice(-1);
        if (latestReview === undefined) return;
        const { status } = latestReview;
        if (status === ReviewStatus.NOT_SUBMITTED) {
            unsubmitted.push(auth);
        }
        else if (status === ReviewStatus.SUBMITTED) {
            submitted.push(auth);
        }
        else if (status === ReviewStatus.APPROVED && moment().isBefore(moment(auth.endDate))) {
            approvedActive.push(auth);
        }
        else if (status === ReviewStatus.APPROVED && moment().isSameOrAfter(moment(auth.endDate))) {
            approvedExpired.push(auth);
        }
        else if (status === ReviewStatus.DENIED) {
            denied.push(auth);
        }
    });
    return ({
        unsubmitted,
        submitted,
        underReview,
        approvedActive,
        approvedExpired,
        denied,
    });
};

export const TRAVEL_CATEGORY_DETAILS: { [key in TravelAuthorizationCategory]: { briefDescription: string, detailedDescription: string, } } = {
    [TravelAuthorizationCategory.CATEGORY_I]: {
        briefDescription: "Emergency Leave Unfunded Travel.",
        detailedDescription: "Transportation by the most expeditious routing only for bona fide immediate family emergencies, as determined by DoDI 1327.06 and Military Service regulations.  This travel privilege will not be used in lieu of funded travel entitlements.",
    },
    [TravelAuthorizationCategory.CATEGORY_II]: {
        briefDescription: "Accompanied Environmental and Morale Leave.",
        detailedDescription: "Sponsors in an EML status and their dependents traveling with them, also in an EML status.",
    },
    [TravelAuthorizationCategory.CATEGORY_III]: {
        briefDescription: "Ordinary Leave, Relatives, House Hunting Permissive TDY, Medal of Honor Holders, and Foreign Military.",
        detailedDescription: "Uniformed services members in ordinary leave or pass status. Uniformed services members traveling under permissive TDY orders for house hunting incident to a pending PCS. Relatives who are permanent members of the household and only dependent upon a Military Service member, a DoD civilian employee, or an ARC full-time employee serving with a DoD Component overseas. Dependents of a uniformed services member when accompanied by their sponsor in ordinary leave or pass status.",
    },
    [TravelAuthorizationCategory.CATEGORY_IV]: {
        briefDescription: "Unaccompanied Environmental and Morale Leave.",
        detailedDescription: "Unaccompanied dependents traveling under the EML Program. DoD Education Activity teachers and their dependents (accompanied or unaccompanied) traveling during the summer under the EML Program. Unaccompanied dependents of deployed active-duty uniformed services members when the deployment is for at least 30 consecutive days.   ",
    },
    [TravelAuthorizationCategory.CATEGORY_V]: {
        briefDescription: "Permissive TDY (Non-House Hunting), Students, Dependents, Post Deployment/Mobilization Respite Absence, and Others.",
        detailedDescription: "Military personnel traveling on permissive TDY orders other than for house hunting. Dependents, command-sponsored, stationed OCONUS with their sponsor, who is:  (1) a uniformed services member; (2) a U.S. citizen civilian employee of the DoD (paid from either appropriated funds or NAF); or (3) an ARC full-time, paid employee serving with the DoD.  ",
    },
    [TravelAuthorizationCategory.CATEGORY_VI]: {
        briefDescription: "Retired, Dependents, Reserve, ROTC, NUPOC, CEC, Veterans with a Permanent Service-connected Disability Rated as Total and Surviving Spouses of Service Members who Died in Active Duty, Inactive Duty Training, or Annual Training Status and Retired Military Members. ",
        detailedDescription: "Retired uniformed services members and their dependents (when accompanied by their sponsor). Dependents, command-sponsored, stationed OCONUS with their sponsor, who is:  (1) a uniformed services member; (2) a U.S. citizen civilian employee of the DoD (paid from either appropriated funds or NAF); or (3) an ARC full-time, paid employee serving with the DoD.",
    },
};

export const getEnumValues = (theEnum: any) => {
    return Object.values(theEnum).filter(value => typeof value === "string") as string[];
};
