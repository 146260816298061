import { Checkbox, Flex, FlexItem, NumberInput, TextInput, Title, TitleSizes } from "@patternfly/react-core";
import { CheckIcon, PencilAltIcon } from "@patternfly/react-icons";
import { useState } from "react";

interface TableRow {
  title: string;
  value: string | number | boolean | null | undefined;
  valueType?: "string" | "number" | "boolean";
  onValueUpdated?: (newValue: string | number | boolean | null | undefined) => void;
  valueHref?: string;
}

interface Props {
  rows: TableRow[];
}


export default (props: Props) => {

  const [targetRow, setTargetRow] = useState<TableRow | undefined>(undefined);
  const [newTargetValue, setNewTargetValue] = useState<string | number | boolean | null | undefined>(undefined);

  return (
    <Flex direction={{ default: "row" }} style={{ width: "100%", border: "1px solid #d2d2d2", padding: 10, }}>
      <FlexItem style={{ marginBottom: 0, }} grow={{ default: "grow" }}>
        {props.rows.map((r) => (
          <Flex style={{ width: "100%", columnGap: 0, }} flexWrap={{ default: "nowrap" }} key={r.title}>
            <FlexItem grow={{ default: "grow" }} style={{ marginRight: 20, }}>
              <Title headingLevel="h3" size={TitleSizes.lg} style={{ whiteSpace: "nowrap", }}>
                {r.title}
              </Title>
            </FlexItem>
            <FlexItem>
              {targetRow !== r ? (
                <div style={{ overflow: "hidden" }}>
                  {r.value === null || r.value === undefined || r.value === "" ? (
                    <span style={{ fontStyle: "italic" }}>N/A</span>
                  ) : (
                    <span>
                      {r.valueHref ? (
                        <a href={r.valueHref}>{r.value}</a>
                      ) : (
                        r.value.toString()
                      )}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  {targetRow.valueType === "string" && (
                    <TextInput
                      aria-label={targetRow.title}
                      value={(newTargetValue as string) ?? ""}
                      onChange={(newVal) => {
                        setNewTargetValue(newVal);
                      }}
                    />
                  )}
                  {targetRow.valueType === "number" && (
                    <NumberInput
                      aria-label={targetRow.title}
                      onPlus={() => { setNewTargetValue(newTargetValue as number + 1); }}
                      onMinus={() => { setNewTargetValue(newTargetValue as number - 1); }}
                      value={(newTargetValue as number) ?? ""}
                      onChange={(e) => {
                        setNewTargetValue(e.currentTarget.value);
                      }}
                    />
                  )}
                  {targetRow.valueType === "boolean" && (
                    <Checkbox
                      isChecked={(newTargetValue as boolean) ?? false}
                      onChange={(newVal) => {
                        setNewTargetValue(newVal);
                      }}
                      id={`checkbox-${targetRow.title}`}
                    />
                  )}
                </>
              )}
            </FlexItem>
            <FlexItem shrink={{ default: "shrink" }}>
              {r.onValueUpdated !== undefined && (
                <>
                  {targetRow === r ? (
                    <CheckIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (r.onValueUpdated === undefined) return;
                        r.onValueUpdated(newTargetValue);
                        setTargetRow(undefined);
                        setNewTargetValue(undefined);
                      }}
                    />
                  ) : (
                    <PencilAltIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTargetRow(r);
                        setNewTargetValue(r.value);
                      }}
                    />
                  )}
                </>
              )}
            </FlexItem>
          </Flex>
        ))}
      </FlexItem>
    </Flex>
  )
};