import { gql } from "@apollo/client";


export const GET_AIRCRAFTS_QUERY = gql`
query GetAircrafts($guids: [String], $pageNumber: Int, $resultsPerPage: Int) {
  aircrafts(guids: $guids, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid
    createdAt
    lastUpdatedAt
    
    name
    designation
    manufacturer{ 
      guid 
      name
    }
  }
}
`;


export const CREATE_AIRCRAFT_QUERY = gql`
mutation CreateAircraft(
  $name: String,
  $designation: String!,
  $manufacturerGuid: String!
) {
  createAircraft(
    name: $name,
    designation: $designation,
    manufacturerGuid: $manufacturerGuid
  )
}
`;

export const UPDATE_AIRCRAFT_QUERY = gql`
mutation UpdateAircraft(
  $guid: String!,
  $name: String,
  $designation: String,
  $manufacturerGuid: String
) {
  updateAircraft(
    guid: $guid,
    name: $name,
    designation: $designation,
    manufacturerGuid: $manufacturerGuid
  )
}
`;

export const DELETE_AIRCRAFT_QUERY = gql`
mutation DeleteAircraft($guid: String!) {
    deleteAircraft(guid: $guid)
}
`;
