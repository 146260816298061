import avatarImg from "../assets/avatar.svg";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Alert, Avatar, Button, Card, Flex,
  FlexItem, NumberInput, PageSection, Text, Title,
  TitleSizes,
  Tooltip,
  Wizard
} from "@patternfly/react-core";
import { AddCircleOIcon, CheckCircleIcon, ExclamationCircleIcon, ImageIcon, PlaneDepartureIcon } from "@patternfly/react-icons";
import { Link } from "@reach/router";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../assets/af-logo-blackonwhite.png";
import CreateLuggageModal from "../Components/CreateLuggageModal";
import Empty from "../Components/Empty";
import HorizontalTable from "../Components/HorizontalTable";
import PageContainer from "../Components/PageContainer";
import ViewLuggageModal from "../Components/ViewLuggageModal";
import { CREATE_FLIGHT_CHECK_IN_MUTATION, DELETE_FLIGHT_CHECK_IN_MUTATION, GET_BOARDING_PASSES_QUERY, GET_FLIGHT_CHECK_INS_QUERY, GET_TRAVELERS_QUERY, GET_TRAVEL_AUTHORIZATIONS_QUERY } from "../graphql/queries";
import { titleize } from "../utils/string-utils";
import { CreateFlightCheckIn, DeleteFlightCheckIn, GetBoardingPasses, GetBoardingPasses_boardingPasses, GetBoardingPasses_boardingPasses_checkedLuggage, GetFlightCheckIns, GetFlightCheckIns_flightCheckIns, GetLuggage_luggage, GetTravelAuthorizations, GetTravelAuthorizations_travelAuthorizations, GetTravelers, GetTravelers_travelers, SponsorRelationship } from "../__generated__/api";


interface Props {
  guid: string | undefined;
}

export default function Passenger(props: Props) {
  const {
    guid,
  } = props;

  const apolloClient = useApolloClient();

  const [boardingPass, setBoardingPass] = useState<GetBoardingPasses_boardingPasses | undefined>(undefined);
  const [travelAuthorization, setTravelAuthorization] = useState<GetTravelAuthorizations_travelAuthorizations | undefined>(undefined);
  const [traveler, setTraveler] = useState<GetTravelers_travelers | undefined>(undefined);
  const [sponsorTraveler, setSponsorTraveler] = useState<GetTravelers_travelers | undefined>(undefined);
  const [flightCheckIn, setFlightCheckIn] = useState<GetFlightCheckIns_flightCheckIns | undefined>(undefined);
  const [dependentCheckIns, setDependentCheckIns] = useState<GetFlightCheckIns_flightCheckIns[]>([]);

  const [passengerWeight, setPassengerWeight] = useState<number>(0);
  const [carryOnWeight, setCarryOnWeight] = useState<number>(0);
  const [createLuggageModalVisible, setCreateLuggageModalVisible] = useState<boolean>(false);
  const [viewLuggageModalVisible, setViewLuggageModalVisible] = useState<boolean>(false);
  const [targetLuggage, setTargetLuggage] = useState<GetBoardingPasses_boardingPasses_checkedLuggage | undefined>(undefined);


  const { loading: fetchingBoardingPasses, refetch: refetchBoardingPasses, } = useQuery<GetBoardingPasses>(
    GET_BOARDING_PASSES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 5000,
      variables: {
        guids: [guid],
      },
      onCompleted: async (resp) => {
        const fetchedBP = resp.boardingPasses[0];
        setBoardingPass(fetchedBP);

        try {
          // get travel auth
          const travelAuthQueryResp = await apolloClient.query<GetTravelAuthorizations>({
            query: GET_TRAVEL_AUTHORIZATIONS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              guids: [fetchedBP.spaceRequest.travelAuthorization.guid],
            },
          });
          const fetchedTravelAuth = travelAuthQueryResp.data.travelAuthorizations[0];
          setTravelAuthorization(fetchedTravelAuth);

          // get sponsor as traveler of travel auth
          if (fetchedTravelAuth !== undefined) {
            const sponsorTravelerGuid = fetchedTravelAuth.travelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF)?.guid;
            const travelerResp = await apolloClient.query<GetTravelers>({
              query: GET_TRAVELERS_QUERY,
              notifyOnNetworkStatusChange: true,
              fetchPolicy: "no-cache",
              variables: {
                guids: [sponsorTravelerGuid],
              },
            });
            const fetchedTraveler = travelerResp.data.travelers[0];
            setSponsorTraveler(fetchedTraveler);
          }
        } catch (err) {
          console.error("Failed to get Traveler");
          console.log(JSON.stringify(err, null, 2));
        }

        // get owning traveler of boarding pass
        try {
          const travelersQueryResp = await apolloClient.query<GetTravelers>({
            query: GET_TRAVELERS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              guids: [fetchedBP.owner.guid],
            },
          });
          setTraveler(travelersQueryResp.data.travelers[0]);
        } catch (err) {
          console.error("Failed to get Travelers");
          console.log(JSON.stringify(err, null, 2));
        }

        // get check-in for boarding pass
        try {
          const checkInQueryResp = await apolloClient.query<GetFlightCheckIns>({
            query: GET_FLIGHT_CHECK_INS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              boardingPassGuid: guid,
            },
          });
          const fetchedCheckIn = checkInQueryResp.data.flightCheckIns[0]
          setFlightCheckIn(fetchedCheckIn);
          if (fetchedCheckIn !== undefined) {
            setPassengerWeight(fetchedCheckIn.travelerWeight);
          }
        } catch (err) {
          console.error("Failed to get Flight Check-Ins");
          console.log(JSON.stringify(err, null, 2));
        }

        // get check-in for travelers associated with SpaceRequest
        try {
          const checkInQueryResp = await apolloClient.query<GetFlightCheckIns>({
            query: GET_FLIGHT_CHECK_INS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              spaceRequestGuid: fetchedBP.spaceRequest.guid,
            },
          });
          setDependentCheckIns(checkInQueryResp.data.flightCheckIns.filter((fc) => fc.boardingPass?.guid !== guid));
        } catch (err) {
          console.error("Failed to get Flight Check-Ins");
          console.log(JSON.stringify(err, null, 2));
        }
      },
    }
  );
  const [createCheckIn, { loading: creatingCheckIn }] = useMutation<CreateFlightCheckIn>(CREATE_FLIGHT_CHECK_IN_MUTATION, { notifyOnNetworkStatusChange: true, });
  const [deleteCheckIn, { loading: deletingCheckIn }] = useMutation<DeleteFlightCheckIn>(DELETE_FLIGHT_CHECK_IN_MUTATION, { notifyOnNetworkStatusChange: true, });


  const doCreateCheckIn = async () => {
    try {
      await createCheckIn({
        variables: {
          boardingPassGuid: guid,
          travelerWeight: passengerWeight,
          carryOnWeight,
        },
      });
      await refetchBoardingPasses();
    } catch (err) {
      console.error("Failed to get create Checked Luggage");
      console.error(JSON.stringify(err, null, 2));
    }
  };

  const doDeleteCheckIn = async () => {
    try {
      await deleteCheckIn({
        variables: {
          guid: flightCheckIn?.guid,
        },
      });
      await refetchBoardingPasses();
    } catch (err) {
      console.error("Failed to get delete Flight Check-In");
      console.error(JSON.stringify(err, null, 2));
    }
  };



  const boardingPassQrRef = React.createRef<QRCode>();

  const isSponsor = () => {
    return traveler?.sponsorRelationship === SponsorRelationship.SELF;
  };


  const getLuggage = (verified: boolean) => {
    return boardingPass?.checkedLuggage.filter((l) => l.verified === verified) ?? [];
  };

  const isCheckedIn = (travelerGuid: string) => dependentCheckIns.find((ci) => ci.boardingPass?.owner.guid === travelerGuid) !== undefined;

  const getDependents = () => travelAuthorization?.travelers.filter((t) => t.sponsorRelationship !== SponsorRelationship.SELF) ?? [];

  const wizardTemplate = () => {

    if (guid === undefined || boardingPass === undefined) {
      return (
        <Alert
          isInline
          variant="danger"
          title="Boarding Pass Not Found"
        >
          <p>Failed to load boarding pass. No such ID exists: {guid}.</p>
        </Alert>
      );
    }


    const sponsorInfoTemplate = () => (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          <Flex style={{ marginBottom: 40, }}>
            <FlexItem grow={{ default: "grow" }}>
              <Alert
                title="Sponsored Traveler"
              >
                <p>
                  This passenger is sponsored by another passenger.
                  Ensure that their sponsor is present as well.
                </p>
              </Alert>
            </FlexItem>
          </Flex>

          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Sponsor Information
              </Title>
            </FlexItem>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "First Name", value: sponsorTraveler?.firstName, valueType: "string", },
                  { title: "Middle Name", value: sponsorTraveler?.middleName, valueType: "string", },
                  { title: "Last Name", value: sponsorTraveler?.lastName, valueType: "string", },
                  { title: "Email Address", value: sponsorTraveler?.email, valueType: "string", valueHref: `mailto:${sponsorTraveler?.email}` },
                  { title: "DOD ID", value: sponsorTraveler?.dodId, valueType: "string", },
                  { title: "Profile", value: "Click to View", valueType: "string", valueHref: `/passengers/${sponsorTraveler?.guid}` },
                ]}
              />
            </FlexItem>
          </Flex>

        </FlexItem>
      </Flex>
    );

    const dependentInfoTemplate = () => (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          {/* Warning if not all dependents have checked in */}
          {travelAuthorization !== undefined && dependentCheckIns.length < getDependents().length ? (
            <Flex style={{ marginBottom: 40, }}>
              <FlexItem grow={{ default: "grow" }}>
                <Alert
                  isInline
                  variant="warning"
                  title="Waiting for Dependents"
                >
                  <p>
                    Not all dependents have been checked in yet.
                  </p>
                  <p>
                    Still waiting for {getDependents().length - dependentCheckIns.length} dependent(s) to check in.
                  </p>
                </Alert>
              </FlexItem>
            </Flex>
          ) : (
            <Flex style={{ marginBottom: 40, }}>
              <FlexItem grow={{ default: "grow" }}>
                <Alert
                  isInline
                  variant="success"
                  title="All Dependents Checked In"
                >
                  <p>
                    All dependents have been checked in.
                  </p>
                </Alert>
              </FlexItem>
            </Flex>
          )}

          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Dependent Information
              </Title>
            </FlexItem>
            <FlexItem>
              <Flex style={{ rowGap: 20, }}>
                {getDependents().map((traveler) => (
                  <FlexItem key={traveler.guid}>
                    <Card style={{ width: "180px", padding: 20, position: "relative" }}>
                      <div style={{ position: "absolute", top: -8, right: -8, }}>
                        {isCheckedIn(traveler.guid) ? (
                          <Tooltip content="Passenger is checked in">
                            <CheckCircleIcon size="md" color="#4BB543" />
                          </Tooltip>
                        ) : (
                          <Tooltip content="Passenger has not checked in yet">
                            <ExclamationCircleIcon size="md" color="#FFCC00" />
                          </Tooltip>
                        )}
                      </div>
                      <Flex direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
                        <FlexItem>
                          <Avatar src={avatarImg} alt="avatar" size="xl" style={{}} />
                        </FlexItem>
                        <FlexItem>
                          <Title headingLevel="h4" size={TitleSizes["xl"]} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <Link to={`/passengers/${traveler.guid}`}>
                              {traveler.firstName} {traveler.middleName?.substring(0, -1)} {traveler.lastName}
                            </Link>
                          </Title>
                        </FlexItem>
                        <FlexItem>
                          <Text>{titleize(traveler.sponsorRelationship)}</Text>
                        </FlexItem>
                      </Flex>
                    </Card>
                  </FlexItem>
                ))}
              </Flex>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    );

    const passengerInfoTemplate = () => (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Passenger Information
              </Title>
            </FlexItem>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "First Name", value: boardingPass.owner.firstName, valueType: "string", },
                  { title: "Middle Name", value: boardingPass.owner.middleName, valueType: "string", },
                  { title: "Last Name", value: boardingPass.owner.lastName, valueType: "string", },
                  { title: "Email Address", value: boardingPass.owner.email, valueType: "string", valueHref: `mailto:${boardingPass.owner.email}` },
                  { title: "DOD ID", value: boardingPass.owner.dodId, valueType: "string", },
                ]}
              />
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem>
          <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
            Passenger Weight (lbs)
          </Title>
          <NumberInput
            isDisabled={flightCheckIn !== undefined}
            value={passengerWeight}
            onMinus={() => { setPassengerWeight(Math.max(0, passengerWeight - 1.0)); }}
            onPlus={() => { setPassengerWeight(passengerWeight + 1.0); }}
            onChange={(e) => {
              const n = parseFloat(e.currentTarget.value);
              if (isNaN(n)) {
                return;
              }
              setPassengerWeight(n);
            }}
          />
        </FlexItem>
        <FlexItem>
          <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
            Carry-On Weight (lbs)
          </Title>
          <NumberInput
            isDisabled={flightCheckIn !== undefined}
            value={carryOnWeight}
            onMinus={() => { setCarryOnWeight(Math.max(0, carryOnWeight - 1.0)); }}
            onPlus={() => { setCarryOnWeight(carryOnWeight + 1.0); }}
            onChange={(e) => {
              const n = parseFloat(e.currentTarget.value);
              if (isNaN(n)) {
                return;
              }
              setCarryOnWeight(n);
            }}
          />
        </FlexItem>
      </Flex>
    );

    const flightInfoTemplate = () => (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
            Flight Information
          </Title>
        </FlexItem>
        <FlexItem>
          <HorizontalTable
            rows={[
              { title: "Departure Airport", value: `${boardingPass.spaceRequest.flightStatus?.flight.departureAirport.name} (${boardingPass.spaceRequest.flightStatus?.flight.departureAirport.code})`, valueType: "string", },
              { title: "Departure Time", value: `${moment(boardingPass.spaceRequest.flightStatus?.flight.departureSchedule).format("MM/DD/YYYY HH:mm")}`, valueType: "string", },
              { title: "Arrival Airport", value: `${boardingPass.spaceRequest.flightStatus?.flight.arrivalAirport.name} (${boardingPass.spaceRequest.flightStatus?.flight.arrivalAirport.code})`, valueType: "string", },
              { title: "Arrival Time", value: `${moment(boardingPass.spaceRequest.flightStatus?.flight.arrivalSchedule).format("MM/DD/YYYY HH:mm")}`, valueType: "string", },
              { title: "Mission ID", value: `${boardingPass.spaceRequest.flightStatus?.flight.mission.externalId}`, valueType: "string", },
              { title: "Registered", value: moment(boardingPass.spaceRequest.createdAt).format("MM/DD/YYYY HH:mm"), valueType: "string", },
              { title: "Approved", value: moment(boardingPass.createdAt).format("MM/DD/YYYY HH:mm"), valueType: "string", },
            ]}
          />
        </FlexItem>
      </Flex>
    );

    const luggageTemplate = () => {

      const luggageItemTemplate = (cl: GetBoardingPasses_boardingPasses_checkedLuggage) => (
        <div
          onClick={() => { setTargetLuggage(cl); setViewLuggageModalVisible(true); }}
          style={{ width: 150, height: 150, position: "relative", borderRadius: 10, backgroundColor: "#f2f2f2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: 10, cursor: "pointer", }}
        >
          <div style={{ position: "absolute", top: -8, right: -8, zIndex: 1000, }}>
            {cl.verified ? (
              <>
                {cl.luggageTag || cl.luggageGroupTag ? (
                  <Tooltip content="Verified and Tracked">
                    <CheckCircleIcon size="md" color="#4BB543" />
                  </Tooltip>
                ) : (
                  <Tooltip content="Missing Tracking Tag">
                    <ExclamationCircleIcon size="md" color="#FFCC00" />
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip content="Not Verified">
                <ExclamationCircleIcon size="md" color="#FF0000" />
              </Tooltip>
            )}
          </div>
          {cl.photoFilePathS3 === null ? (
            <>
              <ImageIcon size="lg" color="#a2a2a2" />
              <Text style={{ color: "#a2a2a2" }}>No Image</Text>
            </>
          ) : (
            <>
              <img src={`${process.env.REACT_APP_S3_URL}/${cl.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 10, }} />
            </>
          )}
        </div>
      );

      return (
        <Flex direction={{ default: "column" }}>
          <FlexItem>
            <Flex direction={{ default: "column" }} style={{ marginBottom: 40, }}>
              <FlexItem>
                <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                  Unverified Luggage
                  {flightCheckIn === undefined && (
                    <Button variant="plain" onClick={() => { setCreateLuggageModalVisible(true); }}>
                      <AddCircleOIcon size="md" />
                    </Button>
                  )}
                </Title>
              </FlexItem>
              <FlexItem>
                {getLuggage(false).length === 0 ? (
                  <Empty
                    title={"No Unverified Luggage"}
                    description={"Passenger has no unverified luggage"} />
                ) : (
                  <Flex direction={{ default: "row", }} alignItems={{ default: "alignItemsFlexStart" }}>
                    {getLuggage(false).map((cl) => (
                      <FlexItem key={cl.guid}>
                        {luggageItemTemplate(cl)}
                      </FlexItem>
                    ))}
                  </Flex>
                )}
              </FlexItem>
            </Flex>
          </FlexItem>
          <FlexItem>
            <Flex direction={{ default: "column" }} style={{ marginBottom: 0, }}>
              <FlexItem>
                <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                  Verified Luggage
                </Title>
              </FlexItem>
              <FlexItem>
                {getLuggage(true).length === 0 ? (
                  <Empty
                    title={"No Verified Luggage"}
                    description={"Passenger has no verified luggage"} />
                ) : (
                  <Flex>
                    {getLuggage(true).map((cl) => (
                      <FlexItem key={cl.guid}>
                        {luggageItemTemplate(cl)}
                      </FlexItem>
                    ))}
                  </Flex>
                )}
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      );
    };

    const boardingPassTemplate = () => (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
            Boarding Pass
          </Title>
        </FlexItem>
        <FlexItem>
          {/* Boarding Pass */}
          <Flex id="boarding-pass" direction={{ default: "column", }} style={{ width: 400, boxShadow: "0 0 10px rgb(0,0,0,50%)" }}>
            {/* Blue Banner */}
            <FlexItem style={{ backgroundColor: "#29A8EB", height: 40, borderTopLeftRadius: 10, borderTopRightRadius: 10, }}>
              <Flex direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} style={{ height: "100%", paddingLeft: 10, paddingRight: 10, }}>
                <FlexItem>
                  <Text style={{ color: "#FFFFFF", textAlign: "center", }}>Upgrade</Text>
                </FlexItem>
              </Flex>
            </FlexItem>
            {/* Traveler */}
            <FlexItem style={{ paddingLeft: 20, paddingRight: 20, }}>
              <Flex direction={{ default: "row" }} justifyContent={{ default: "justifyContentCenter" }} style={{ paddingBottom: 20, borderBottomColor: "#D3D6DA", borderBottomWidth: 2, borderBottomStyle: "dashed", }}>
                <FlexItem shrink={{ default: "shrink" }} style={{ marginRight: 0, textAlign: "center", }}>
                  <Text style={{ color: "#707884", fontSize: 32, fontWeight: 700, }}>
                    {boardingPass.owner.firstName}
                    {" "}
                    {boardingPass.owner.middleName?.substring(0, 1)}
                    {". "}
                    {boardingPass.owner.lastName}
                  </Text>
                  <Text style={{ color: "#9299A0", fontSize: 12, }}>
                    {boardingPass.owner.dodId}
                  </Text>
                </FlexItem>
              </Flex>
            </FlexItem>
            <FlexItem style={{ paddingLeft: 20, paddingRight: 20, }}>
              <Flex direction={{ default: "row" }} alignItems={{ default: "alignItemsFlexEnd" }} justifyContent={{ default: "justifyContentCenter" }} style={{ paddingBottom: 20, marginRight: 0, borderBottomColor: "#D3D6DA", borderBottomWidth: 2, borderBottomStyle: "dashed", }}>
                <FlexItem style={{ marginRight: 0, textAlign: "center", width: 150, height: "100%", }}>
                  <Text style={{ color: "#29A8EB", fontSize: 12, fontWeight: 400, }}>
                    {boardingPass.spaceRequest.flightStatus?.flight.departureAirport.name}
                  </Text>
                  <Text style={{ color: "#707884", fontSize: 32, fontWeight: 500, marginTop: 5, marginBottom: 5, }}>
                    {boardingPass.spaceRequest.flightStatus?.flight.departureAirport.code}
                  </Text>
                  <Text style={{ color: "#9299A0", fontSize: 12, fontWeight: 500, }}>
                    Departing
                  </Text>
                  <Text style={{ color: "#9299A0", fontSize: 10, }}>
                    {moment(boardingPass.spaceRequest.flightStatus?.flight.departureSchedule).format("HH:mm A")}
                  </Text>
                </FlexItem>
                <FlexItem alignSelf={{ default: "alignSelfCenter" }} style={{ marginRight: 0, textAlign: "center", height: "100%", }}>
                  <PlaneDepartureIcon size="xl" color="#D3D6DA" />
                </FlexItem>
                <FlexItem style={{ marginRight: 0, textAlign: "center", width: 150, height: "100%", }}>
                  <Text style={{ color: "#29A8EB", fontSize: 12, fontWeight: 400, }}>
                    {boardingPass.spaceRequest.flightStatus?.flight.arrivalAirport.name}
                  </Text>
                  <Text style={{ color: "#707884", fontSize: 32, fontWeight: 500, marginTop: 5, marginBottom: 5, }}>
                    {boardingPass.spaceRequest.flightStatus?.flight.arrivalAirport.code}
                  </Text>
                  <Text style={{ color: "#9299A0", fontSize: 12, fontWeight: 500, }}>
                    Arriving
                  </Text>
                  <Text style={{ color: "#9299A0", fontSize: 10, }}>
                    {moment(boardingPass.spaceRequest.flightStatus?.flight.arrivalSchedule).format("HH:mm A")}
                  </Text>
                </FlexItem>
              </Flex>
            </FlexItem>
            <FlexItem style={{ paddingBottom: 20, }}>
              <Flex justifyContent={{ default: "justifyContentCenter" }}>
                <FlexItem>
                  <QRCode
                    size={250}
                    id="boarding-pass-qr"
                    value={boardingPass.guid}
                    logoImage={logo}
                    logoWidth={60}
                    qrStyle="squares"
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    quietZone={0}
                    ref={boardingPassQrRef}
                    style={{
                      width: "100% !important",
                    }}
                  />
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem>
          <Button
            variant="primary"
            onClick={() => {
              const bp = document.querySelector("#boarding-pass");
              if (!bp)
                return;
              const popup = window.open();
              if (!popup)
                return;
              html2canvas(bp as HTMLElement).then(canvas => {
                popup.document.body.appendChild(canvas);
                popup.print();
              });
            }}
          >
            Print Boarding Pass
          </Button>
        </FlexItem>
      </Flex>
    );

    const finalizeTemplate = () => {
      if (flightCheckIn !== undefined) {
        return (
          <Flex direction={{ default: "column" }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Check-In Details
              </Title>
            </FlexItem>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "Check-In Time", value: moment(flightCheckIn.createdAt).format("MM/DD/YYYY HH:mm A"), valueType: "string", },
                  { title: "Traveler Weight (lbs)", value: flightCheckIn.travelerWeight, valueType: "number", },
                  { title: "Carry-On Weight (lbs)", value: flightCheckIn.carryOnWeight, valueType: "number", },
                  { title: "Checked Luggage", value: getLuggage(true).length, valueType: "number", },
                ]}
              />
            </FlexItem>
            <FlexItem>
              <Button
                variant="danger"
                isDanger
                onClick={() => {
                  if (confirm("Are you sure you want to reset the check-in state for this traveler?")) {
                    doDeleteCheckIn();
                  }
                }}
              >
                Reset Check-In State
              </Button>
            </FlexItem>
          </Flex>
        );
      }
      return (
        <Flex direction={{ default: "column" }}>
          <FlexItem>
            <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
              Finalize
            </Title>
          </FlexItem>
          <FlexItem>
            <Text>
              If all of the information on the previous pages is accurate, the boarding pass has been printed, and the luggage tags have been printed and applied, you can finalize the process by pressing "Complete".
              Doing so will add the passenger to the flight manifest, which will be checked again at the gate.
            </Text>
          </FlexItem>
        </Flex>
      );
    };

    const wizardSteps = [
      ...(isSponsor() ? [] : [({ name: "Sponsor Info", component: sponsorInfoTemplate(), hideCancelButton: true, })]),
      ...(isSponsor() && true ? [({ name: "Dependent Info", component: dependentInfoTemplate(), hideCancelButton: true, })] : []),
      { name: 'Passenger Info', component: passengerInfoTemplate(), hideCancelButton: true, },
      { name: "Flight Info", component: flightInfoTemplate(), hideCancelButton: true, },
      { name: 'Manage Luggage', component: luggageTemplate(), hideCancelButton: true, },
      { name: 'Print Boarding Pass', component: boardingPassTemplate(), hideCancelButton: true, },
      { name: flightCheckIn === undefined ? "Finalize" : "Review", component: finalizeTemplate(), hideCancelButton: true, nextButtonText: 'Complete', },
    ];

    return (
      <Wizard
        title={`Check In ${boardingPass?.owner.firstName} ${boardingPass?.owner.lastName}`}
        description="Ensure all of the information is accurate, that all baggage has tracking tags, and that passenger has brought all required documents."
        steps={wizardSteps}
        onSave={() => {
          doCreateCheckIn();
        }}
        hideClose
        footer={flightCheckIn === undefined ? undefined : <></>}
      />
    );
  };

  return (
    <PageContainer>
      <PageSection>

        {createLuggageModalVisible && (
          <CreateLuggageModal
            boardingPassGuid={boardingPass?.guid}
            onClose={() => {
              setCreateLuggageModalVisible(false);
            }}
            onCreated={() => {
              refetchBoardingPasses();
            }}
          />
        )}


        {viewLuggageModalVisible && (
          <ViewLuggageModal
            luggageGuid={targetLuggage?.guid}
            onClose={() => {
              setViewLuggageModalVisible(false);
              setTargetLuggage(undefined);
              refetchBoardingPasses();
            }}
            onCreated={() => {
              refetchBoardingPasses();
            }}
            readonly={flightCheckIn !== undefined}
          />
        )}

        {/* If already checked in */}
        {flightCheckIn !== undefined && (
          <Flex style={{ marginBottom: 40, }}>
            <FlexItem grow={{ default: "grow" }}>
              <Alert
                title="Passenger Already Checked-In"
              >
                <p>
                  This passenger has already been checked-in.
                  Some information may be uneditable.
                  You can reset the state of this check-in at the bottom of the page.
                </p>
              </Alert>
            </FlexItem>
          </Flex>
        )}

        {wizardTemplate()}
      </PageSection>
    </PageContainer>
  );
}
