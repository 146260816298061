import { Avatar, Card, CardFooter, Flex, FlexItem, Text, TextVariants, Title, TitleSizes } from "@patternfly/react-core";
import { Link } from "@reach/router";
import avatarImg from "../../assets/avatar.svg";
import { titleize } from "../../utils/string-utils";
import Empty from "../Empty";

interface Props {
  travelers: any[];
  showRelationship?: boolean;

  noneTitle: string;
  noneMessage: string;
}

export default (props: Props) => {
  const {
    travelers,
    showRelationship,
    noneTitle,
    noneMessage,
  } = props;

  const travelerCardTemplate = (traveler: any) => (
    <FlexItem key={traveler.guid}>
      <Card style={{ width: "180px", padding: 20, }}>
        <Flex direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
          <FlexItem>
            <Avatar src={avatarImg} alt="avatar" size="xl" style={{}} />
          </FlexItem>
          <FlexItem>
            <Title headingLevel="h4" size={TitleSizes["xl"]} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Link to={`/passengers/${traveler.guid}`}>
                {traveler.firstName} {traveler.middleName?.substring(0, -1)} {traveler.lastName}
              </Link>
            </Title>
          </FlexItem>
          {showRelationship && (
            <FlexItem>
              <Text>{titleize(traveler.sponsorRelationship)}</Text>
            </FlexItem>
          )}
        </Flex>
      </Card>
    </FlexItem>
  );

  return (
    <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", padding: 20, }}>
      {travelers.length === 0 ? (
        <Empty
          title={noneTitle}
          description={noneMessage}
        />
      ) : (
        <Flex direction={{ default: "row" }} style={{ rowGap: 20, }}>
          {travelers.map(travelerCardTemplate)}
        </Flex>
      )}
    </div>
  );
}
