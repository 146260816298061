import { useMutation, useQuery } from "@apollo/client";
import { Button, Flex, FlexItem, PageSection } from "@patternfly/react-core";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { useUser } from "../contexts";
import { CREATE_LUGGAGE_PALLET_QUERY, GET_AIRPORTS_QUERY, GET_LUGGAGE_PALLET_QUERY } from "../graphql/queries";
import { CreateLuggagePallet, GetAirports, GetLuggagePallets, GetLuggagePallets_luggagePallets } from "../__generated__/api";


export default function Pallets() {

  const { me } = useUser();

  const [pallets, setPallets] = useState<GetLuggagePallets_luggagePallets[]>([]);

  const [airportGuid, setAirportGuid] = useState<string | undefined>(undefined);

  useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        // TODO this is hard-coded now as the first airport,
        // but should be the airport of the owning instance
        // e.g. all terminals at WRI are automatically set to WRI
        setAirportGuid(resp.airports[0].guid);
        console.log(`SET DEFAULT AIRPORT TO ${resp.airports[0].name}`);
      },
    }
  );
  const { refetch: refetchLuggagePallets, } = useQuery<GetLuggagePallets>(
    GET_LUGGAGE_PALLET_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setPallets(resp.luggagePallets);
      },
    }
  );
  const [createPallet, { loading: creatingPallet, }] = useMutation<CreateLuggagePallet>(CREATE_LUGGAGE_PALLET_QUERY, { notifyOnNetworkStatusChange: true, });

  const doCreatePallet = async () => {
    try {
      const resp = await createPallet({
        variables: {
          creatingUserGuid: me?.guid,
          currentAirportGuid: airportGuid,
        }
      });
      navigate(`/pallets/${resp.data?.createLuggagePallet}`);

      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to get create LuggagePallet");
      console.log(JSON.stringify(err, null, 2));
    }
  };


  return (
    <PageContainer>
      <PageSection>

        <Flex style={{ marginBottom: 20, }}>
          <FlexItem>
            <Button
              disabled={creatingPallet}
              variant="primary"
              onClick={() => { doCreatePallet(); }}
            >
              Create Pallet
            </Button>
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Missions Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>ID</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>
              <Th># Items</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pallets.map((pallet) => (
              <Tr key={pallet.guid}>
                <Td>{pallet.guid}</Td>
                <Td>{moment(pallet.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(pallet.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{pallet.luggageTags.length}</Td>
                <Td>
                  <Link to={`/pallets/${pallet.guid}`}>
                    <Button isSmall>Edit</Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>

      </PageSection>
    </PageContainer >
  );
}
