import { useMutation, useQuery } from "@apollo/client";
import {
  Button, Flex, FlexItem, Label, OverflowMenu, OverflowMenuContent, OverflowMenuGroup, OverflowMenuItem, PageSection, SearchInput, Tooltip
} from "@patternfly/react-core";
import { CheckCircleIcon, ExclamationCircleIcon } from "@patternfly/react-icons";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import _ from "lodash";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import PageContainer from "../Components/PageContainer";
import { GET_BOARDING_PASSES_QUERY, GET_FLIGHT_CHECK_INS_QUERY, TOGGLE_BOARDED_MUTATION } from "../graphql/queries";
import { GetBoardingPasses, GetBoardingPasses_boardingPasses, GetFlightCheckIns, GetFlightCheckIns_flightCheckIns, ToggleBoarded } from "../__generated__/api";


enum FilterMode {
  All,
  Boarded,
  AwatingBoarding,
}

export default function Passengers() {
  const [checkIns, setCheckIns] = useState<GetFlightCheckIns_flightCheckIns[]>([]);
  const [allBoardingPasses, setAllBoardingPasses] = useState<GetBoardingPasses_boardingPasses[]>([]);

  const [filteredBoardingPasses, setFilteredBoardingPasses] = useState<GetBoardingPasses_boardingPasses[]>([]);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.AwatingBoarding);
  const [boardingPassSearchValue, setBoardingPassSearchValue] = useState<string>("");
  const [passengerNameSearchValue, setPassengerNameSearchValue] = useState<string>("");
  const [passengerIdSearchValue, setPassengerIdSearchValue] = useState<string>("");
  const [passengerEmailSearchValue, setPassengerEmailSearchValue] = useState<string>("");
  const [departureAirportCodeSearchValue, setDepartureAirportCodeSearchValue] = useState<string>("");
  const [arrivalAirportCodeSearchValue, setArrivalAirportCodeSearchValue] = useState<string>("");

  const boardingPassRef = createRef<HTMLInputElement>();

  useQuery<GetFlightCheckIns>(
    GET_FLIGHT_CHECK_INS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 1000,
      onCompleted: (resp) => {
        setCheckIns(resp.flightCheckIns);
      },
    }
  );
  const { loading: fetchingBoardingPasses, refetch: refetchBoardingPasses, } = useQuery<GetBoardingPasses>(
    GET_BOARDING_PASSES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 1000,
      onCompleted: (resp) => {
        setAllBoardingPasses(resp.boardingPasses);
      },
    }
  );
  const [toggleBoarded] = useMutation<ToggleBoarded>(TOGGLE_BOARDED_MUTATION, { notifyOnNetworkStatusChange: true, });

  const doToggleBoarded = async (guid: string, state: boolean) => {
    try {
      await toggleBoarded({
        variables: {
          boardingPassGuid: guid,
          state,
        },
      });
      await refetchBoardingPasses();
    } catch (err) {
      console.error("Failed to get change Boarding Pass state");
      console.error(JSON.stringify(err, null, 2));
    }
  };

  const getFilteredBoardingPasses = (mode: FilterMode) => {

    const boardingPassesWithCheckIn = allBoardingPasses.filter((bp) => checkIns.find((ci) => ci?.boardingPass?.guid === bp.guid) !== undefined);

    let newFiltered = [];
    switch (mode) {
      case FilterMode.All:
        newFiltered = boardingPassesWithCheckIn;
        break;
      case FilterMode.Boarded:
        newFiltered = boardingPassesWithCheckIn.filter((ci) => ci.boarded);
        break;
      case FilterMode.AwatingBoarding:
        newFiltered = boardingPassesWithCheckIn.filter((ci) => !ci.boarded);
        break;
    }

    let newSorted = _.orderBy(
      newFiltered,
      ["firstName"],
      ["asc"]
    );

    if (boardingPassSearchValue !== "") {
      newSorted = newSorted.filter((bp) => bp.guid === boardingPassSearchValue);
    }
    if (passengerNameSearchValue !== "") {
      newSorted = newSorted.filter((bp) => {
        return (
          `${bp.owner.firstName.toLowerCase()} ${bp.owner.middleName?.toLowerCase()} ${bp.owner.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
          || `${bp.owner.firstName.toLowerCase()} ${bp.owner.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
        );
      });
    }
    if (passengerIdSearchValue !== "") {
      newSorted = newSorted.filter((bp) => bp.owner.dodId.includes(passengerIdSearchValue));
    }
    if (passengerEmailSearchValue !== "") {
      newSorted = newSorted.filter((bp) => bp.owner.email.includes(passengerEmailSearchValue.toLowerCase()));
    }
    if (departureAirportCodeSearchValue !== "") {
      newSorted = newSorted.filter((bp) => bp.spaceRequest.flightStatus?.flight.departureAirport.code.toLowerCase().includes(departureAirportCodeSearchValue.toLowerCase()));
    }
    if (arrivalAirportCodeSearchValue !== "") {
      newSorted = newSorted.filter((bp) => bp.spaceRequest.flightStatus?.flight.arrivalAirport.code.toLowerCase().includes(arrivalAirportCodeSearchValue.toLowerCase()));
    }

    return newSorted;
  };

  useEffect(() => {
    boardingPassRef.current?.focus();
  }, []);

  useEffect(() => {
    const newSorted = getFilteredBoardingPasses(filterMode);
    setFilteredBoardingPasses(newSorted);
  }, [allBoardingPasses]);

  useEffect(() => {
    let newFiltered = getFilteredBoardingPasses(filterMode);
    setFilteredBoardingPasses(newFiltered);
  }, [passengerNameSearchValue, passengerIdSearchValue, passengerEmailSearchValue, departureAirportCodeSearchValue, arrivalAirportCodeSearchValue]);

  const filterDisplayedData = (mode: FilterMode) => {
    setFilterMode(mode);
    setFilteredBoardingPasses(getFilteredBoardingPasses(mode));
    boardingPassRef.current?.focus();
  };

  return (
    <PageContainer>
      <PageSection>
        <Flex style={{ marginBottom: 10 }}>
          <FlexItem>
            <Label>Quick Filters</Label>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.All ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.All);
              }}
            >
              All ({getFilteredBoardingPasses(FilterMode.All).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.AwatingBoarding ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.AwatingBoarding);
              }}
            >
              Awaiting Boarding ({getFilteredBoardingPasses(FilterMode.AwatingBoarding).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.Boarded ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.Boarded);
              }}
            >
              Boarded ({getFilteredBoardingPasses(FilterMode.Boarded).length})
            </Button>
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, rowGap: 10, }} >
          <FlexItem>
            <Label>Passenger</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Passenger's Name"
              value={passengerNameSearchValue}
              onChange={(newVal) => { setPassengerNameSearchValue(newVal); }}
              onClear={() => { setPassengerNameSearchValue(""); }}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Passenger's DOD ID"
              value={passengerIdSearchValue}
              onChange={(newVal) => { setPassengerIdSearchValue(newVal); }}
              onClear={() => { setPassengerIdSearchValue(""); }}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Passenger's Email"
              value={passengerEmailSearchValue}
              onChange={(newVal) => { setPassengerEmailSearchValue(newVal); }}
              onClear={() => { setPassengerEmailSearchValue(""); }}
            />
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, rowGap: 10, }} >
          <FlexItem>
            <Label>Flight</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Departure Airport"
              value={departureAirportCodeSearchValue}
              onChange={(newVal) => { setDepartureAirportCodeSearchValue(newVal); }}
              onClear={() => { setDepartureAirportCodeSearchValue(""); }}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Arrival Airport"
              value={arrivalAirportCodeSearchValue}
              onChange={(newVal) => { setArrivalAirportCodeSearchValue(newVal); }}
              onClear={() => { setArrivalAirportCodeSearchValue(""); }}
            />
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, rowGap: 10, }} >
          <FlexItem>
            <Label>Boarding</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Boarding Pass"
              value={boardingPassSearchValue}
              onChange={(newVal) => { setBoardingPassSearchValue(newVal); }}
              onClear={() => { setBoardingPassSearchValue(""); }}
              ref={boardingPassRef}
            />
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Passengers Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>DOD ID</Th>
              <Th>Departure Airport</Th>
              <Th>Departure</Th>
              <Th>Arrival Airport</Th>
              <Th>Arrival</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredBoardingPasses.map((bp) => {
              return (
                <Tr key={bp.guid}>
                  <Td>{bp.owner.firstName}</Td>
                  <Td>{bp.owner.lastName}</Td>
                  <Td>{bp.owner.dodId}</Td>
                  <Td>{bp.spaceRequest.flightStatus?.flight.departureAirport.code}</Td>
                  <Td>{moment(bp.spaceRequest.flightStatus?.flight.departureSchedule).format("MM/DD/YYYY HH:mm")}</Td>
                  <Td>{bp.spaceRequest.flightStatus?.flight.arrivalAirport.code}</Td>
                  <Td>{moment(bp.spaceRequest.flightStatus?.flight.arrivalSchedule).format("MM/DD/YYYY HH:mm")}</Td>
                  <Td>
                    {!bp.boarded ? (
                      <Tooltip content="Passenger has not boarded yet">
                        <ExclamationCircleIcon size="md" color="#FFCC00" />
                      </Tooltip>
                    ) : (
                      <Tooltip content="Passenger has boarded">
                        <CheckCircleIcon size="md" color="#4BB543" />
                      </Tooltip>
                    )}
                  </Td>
                  <Td isActionCell>
                    <OverflowMenu breakpoint="lg">
                      <OverflowMenuContent>
                        <OverflowMenuGroup groupType="button">
                          <OverflowMenuItem>
                            {bp.boarded ? (
                              <Button isSmall variant="danger" onClick={() => { doToggleBoarded(bp.guid, false); }}>Set Not Boarded</Button>
                            ) : (
                              <Button isSmall variant="primary" onClick={() => { doToggleBoarded(bp.guid, true); }}>Set Boarded</Button>
                            )}
                          </OverflowMenuItem>
                        </OverflowMenuGroup>
                      </OverflowMenuContent>
                    </OverflowMenu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableComposable>
      </PageSection>
    </PageContainer>
  );
}
