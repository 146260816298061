import { gql } from "@apollo/client";


export const REGISTER_FOR_FLIGHT_MUTATION = gql`
mutation RegisterForFlight($travelAuthGuid: String!, $flightGuid: String!) {
  registerForFlight(travelAuthGuid: $travelAuthGuid, flightGuid: $flightGuid)
}
`;

export const UNREGISTER_FROM_FLIGHT_MUTATION = gql`
mutation UnregisterFromFlight($travelAuthGuid: String!, $flightGuid: String!) {
  unregisterFromFlight(travelAuthGuid: $travelAuthGuid, flightGuid: $flightGuid)
}
`;

export const SET_REGISTERED_FLIGHT_STATUS_MUTATION = gql`
mutation SetRegisteredFlightStatus($travelAuthGuid: String!, $flightGuid: String!, $flightStatus: FlightStatusType!) {
  setRegisteredFlightStatus(travelAuthGuid: $travelAuthGuid, flightGuid: $flightGuid, flightStatus: $flightStatus)
}
`;

export const GET_SPACE_REQUESTS_QUERY = gql`
query GetSpaceRequests(
  $guids: [String],
  $ownerGuid: String,
  $flightGuid: String,
  $pageNumber: Int,
  $resultsPerPage: Int
) {
  spaceRequests(
    guids: $guids,
    ownerGuid: $ownerGuid,
    flightGuid: $flightGuid,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt
    travelAuthorization {
      guid
      startDate
      endDate
      category
      travelers {
        guid
        firstName
        middleName
        lastName
        email
        dodId
        sponsorRelationship
      }
    }
    flightStatus {
      guid
      createdAt
      status
      flight {
        guid
        spaceAvailable
        mission {
          externalId
        }
        departureSchedule
        departureActual
        departureAirport {
          guid
          name
          code
          address {
            guid
            addressLine1
            addressLine2
            addressLine3
            city
            state
            postCode
          }
        }
        arrivalSchedule
        arrivalActual
        arrivalAirport {
          guid
          name
          code
          address {
            guid
            addressLine1
            addressLine2
            addressLine3
            city
            state
            postCode
          }
        }
      }
    }
  }
}
`;

export const GENERATE_ROLL_CALL_QUERY = gql`
query GenerateRollCall($flightGuid: String!) {
  generateRollCall(flightGuid: $flightGuid) {
    guid
    createdAt
    travelAuthorization {
      guid
      category
      travelers {
        guid
        firstName
        lastName
        sponsorRelationship
      }
    }
  }
} 
`;