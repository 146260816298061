import {
  Avatar,
  Dropdown,
  DropdownGroup,
  DropdownItem, DropdownToggle, Text, Toolbar,
  ToolbarContent, ToolbarGroup, ToolbarItem
} from "@patternfly/react-core";
import { useKeycloak } from "@react-keycloak/web";
import imgAvatar from "../../assets/avatar.svg";
import React, { useState } from "react";
import { useTraveler, useUser } from "../../contexts";
import { useMutation } from "@apollo/client";
import { ResetDatabase } from "../../__generated__/api";
import { RESET_DATABASE_QUERY } from "../../graphql/queries";




export const HeaderToolbar = () => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const [resetDatabase] = useMutation<ResetDatabase>(RESET_DATABASE_QUERY, { notifyOnNetworkStatusChange: true, });

  const {
    keycloak,
  } = useKeycloak();

  const { me } = useUser();
  const { meTraveler } = useTraveler();

  const isDev = true; // TODO ???

  const doResetDatabase = async () => {
    try {
      await resetDatabase();
      keycloak.logout({ redirectUri: window.location.href.split(/[?#]/)[0], });
    } catch (err) {
      console.log("Failed to reset database");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const userDropdownItems = [
    <DropdownGroup key="headerDropDownGroup1">
      <DropdownItem key="headerDropDownGroup1Item1">
        My Profile
      </DropdownItem>
      <DropdownItem onClick={() => { keycloak.logout({ redirectUri: window.location.href.split(/[?#]/)[0], }); }} key="headerDropDownGroup1Item3">
        Logout
      </DropdownItem>
      {isDev && (<>
        <DropdownItem onClick={() => keycloak.token && navigator.clipboard.writeText(keycloak.token)}>
          Dev - Token
        </DropdownItem>
        <DropdownItem onClick={() => { doResetDatabase(); }}>
          Dev - Reset Database
        </DropdownItem>
      </>)}
    </DropdownGroup>
  ];

  return (
    <Toolbar id="toolbar" isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarGroup
          variant='icon-button-group'
          alignment={{ default: "alignRight" }}
          spacer={{ default: "spacerNone", md: "spacerMd" }}
        >
          <ToolbarItem visibility={{ default: "hidden", md: "visible" }}>
            <Text style={{ marginRight: 15, }}>
              {meTraveler?.lastName}
            </Text>
            <Dropdown
              position='right'
              isOpen={isUserDropdownOpen}
              dropdownItems={userDropdownItems}
              toggle={
                <DropdownToggle
                  icon={<Avatar src={imgAvatar} alt='Avatar' />}
                  onToggle={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                >
                </DropdownToggle>
              }
            />
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar >
  );
};
