import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Bullseye, Spinner } from "@patternfly/react-core";
import { RouteComponentProps, Router } from "@reach/router";
import { useKeycloak } from "@react-keycloak/web";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { TravelerProvider, UserProvider } from "../contexts";
import Aircraft from "./Aircraft";
import Aircrafts from "./Aircrafts";
import Airport from "./Airport";
import Airports from "./Airports";
import AirportTerminalData from "./AirportTerminalData";
import Boarding from "./Boarding";
import CheckIn from "./CheckIn";
import CheckIns from "./CheckIns";
import Dashboard from "./Dashboard";
import Flight from "./Flight";
import FoundLuggageInquiry from "./FoundLuggageInquiry";
import Login from "./Login";
import LostAndFound from "./LostLuggageInquiries";
import LostLuggageInquiry from "./LostLuggageInquiry";
import Mission from "./Mission";
import Missions from "./Missions";
import Pallet from "./Pallet";
import Pallets from "./Pallets";
import Passenger from "./Passenger";
import Passengers from "./Passengers";
import RollCall from "./RollCall";
import RollCallManifest from "./RollCallManifest";
import RollCalls from "./RollCalls";
import TrackLuggage from "./TrackLuggage";
import TrackPallet from "./TrackPallet";
import TravelAuthorizations from "./TravelAuthorizations";

// Routes
const DashboardRoute = (props: RouteComponentProps) => <Dashboard />;

const MissionsRoute = (props: RouteComponentProps) => <Missions />;
const MissionRoute = (props: RouteComponentProps<{ missionGuid: string; }>) => <Mission guid={props.missionGuid} />;
const FlightRoute = (props: RouteComponentProps<{ missionGuid: string; flightGuid: string; }>) => <Flight missionGuid={props.missionGuid} flightGuid={props.flightGuid} />;

const PassengersRoute = (props: RouteComponentProps) => <Passengers />;
const PassengerRoute = (props: RouteComponentProps<{ passengerGuid: string; }>) => <Passenger guid={props.passengerGuid} />;

const RollCallsRoute = (props: RouteComponentProps) => <RollCalls />;
const RollCallRoute = (props: RouteComponentProps<{ flightGuid: string; }>) => <RollCall guid={props.flightGuid} />;
const RollCallManifestRoute = (props: RouteComponentProps<{ flightGuid: string; }>) => <RollCallManifest guid={props.flightGuid} />;

const TravelAuthorizationsRoute = (props: RouteComponentProps) => <TravelAuthorizations />;

const AircraftsRoute = (props: RouteComponentProps) => <Aircrafts />;
const AircraftRoute = (props: RouteComponentProps<{ aircraftGuid: string; }>) => <Aircraft guid={props.aircraftGuid} />;
const AirportsRoute = (props: RouteComponentProps) => <Airports />;
const AirportRoute = (props: RouteComponentProps<{ airportGuid: string; }>) => <Airport guid={props.airportGuid} />;
const AirportTerminalDataRoute = (props: RouteComponentProps<{ airportGuid: string; }>) => <AirportTerminalData guid={props.airportGuid} />;

const LostLuggageInquiriesRoute = (props: RouteComponentProps) => <LostAndFound />;
const LostLuggageInquiryRoute = (props: RouteComponentProps<{ inquiryGuid: string; }>) => <LostLuggageInquiry guid={props.inquiryGuid} />;
const FoundLuggageInquiryRoute = (props: RouteComponentProps<{ inquiryGuid: string; }>) => <FoundLuggageInquiry guid={props.inquiryGuid} />;

const CheckInsRoute = (props: RouteComponentProps) => <CheckIns />;
const CheckInRoute = (props: RouteComponentProps<{ boardingPassGuid: string; }>) => <CheckIn guid={props.boardingPassGuid} />;

const BoardingRoute = (props: RouteComponentProps) => <Boarding />;

const TrackLuggageRoute = (props: RouteComponentProps) => <TrackLuggage />;

const PalletsRoute = (props: RouteComponentProps) => <Pallets />;
const PalletRoute = (props: RouteComponentProps<{ palletGuid: string; }>) => <Pallet guid={props.palletGuid} />;
const TrackPalletRoute = (props: RouteComponentProps) => <TrackPallet />;


export const AppRouter: React.FC = (props) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <Bullseye>
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  if (!keycloak?.token) {
    return <Login />;
  }

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: process.env.REACT_APP_API_URL,
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    }) as any,
  });

  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <TravelerProvider>
          <Router style={styles.fullHeight}>
            <DashboardRoute path='/' />

            <MissionsRoute path='/missions' />
            <MissionRoute path='/missions/:missionGuid' />
            <FlightRoute path='/missions/:missionGuid/flights/:flightGuid' />

            <PassengersRoute path='/passengers' />
            <PassengerRoute path='/passengers/:passengerGuid' />

            <RollCallsRoute path='/roll-call' />
            <RollCallRoute path='/roll-call/:flightGuid' />
            <RollCallManifestRoute path='/roll-call/:flightGuid/manifest' />

            <TravelAuthorizationsRoute path='/travel-authorizations' />

            <AircraftsRoute path='/aircrafts' />
            <AircraftRoute path='/aircrafts/:aircraftGuid' />

            <AirportsRoute path='/airports' />
            <AirportRoute path='/airports/:airportGuid' />
            <AirportTerminalDataRoute path='/airports/:airportGuid/amc-gram' />

            <LostLuggageInquiriesRoute path='/lost-and-found' />
            <LostLuggageInquiryRoute path='/lost-and-found/lost/:inquiryGuid' />
            <FoundLuggageInquiryRoute path='/lost-and-found/found/:inquiryGuid' />

            <CheckInsRoute path='/check-in' />
            <CheckInRoute path='/check-in/:boardingPassGuid' />

            <BoardingRoute path='/boarding' />

            <TrackLuggageRoute path='/track-luggage' />

            <PalletsRoute path='/pallets' />
            <PalletRoute path='/pallets/:palletGuid' />
            <TrackPalletRoute path='/track-pallet' />
          </Router>
        </TravelerProvider>
      </UserProvider>
    </ApolloProvider>
  );
};

const styles: {
  [key: string]: React.CSSProperties
} = {
  fullHeight: {
    height: "100%",
  }
};
