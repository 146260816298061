import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  List,
  ListItem, PageSection,
  Select,
  SelectOption,
  Spinner, TextInput
} from "@patternfly/react-core";
import { Link, navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { DELETE_AIRCRAFT_QUERY, GET_AIRCRAFTS_QUERY, GET_AIRCRAFT_MANUFACTURERS_QUERY, UPDATE_AIRCRAFT_QUERY } from "../graphql/queries";
import { DeleteAircraft, GetAircraftManufacturers, GetAircraftManufacturers_aircraftManufacturers, GetAircrafts, GetAircrafts_aircrafts, UpdateAircraft } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function Aircraft(props: Props) {
  const {
    guid,
  } = props;

  const [aircraft, setAircraft] = useState<GetAircrafts_aircrafts | undefined>(undefined);
  const [aircraftManufacturers, setAircraftManufacturers] = useState<GetAircraftManufacturers_aircraftManufacturers[]>([]);

  const [manufacturerDropdownOpen, setManufacturerDropdownOpen] = useState<boolean>(false);

  const [newName, setNewName] = useState<string>();
  const [newDesignation, setNewDesignation] = useState<string | null>();
  const [newManufacturerGuid, setNewManufacturerGuid] = useState<string>();
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);


  const { loading: fetchingAircraft, refetch: refetchAircraft, } = useQuery<GetAircrafts>(
    GET_AIRCRAFTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        guids: [guid],
      },
      onCompleted: (resp) => {
        const fetched = resp.aircrafts[0];
        setAircraft(fetched);
      },
    }
  );
  const { loading: fetchingAircraftManufacturers, refetch: refetchAircraftManufacturers, } = useQuery<GetAircraftManufacturers>(
    GET_AIRCRAFT_MANUFACTURERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAircraftManufacturers(resp.aircraftManufacturers);
      },
    }
  );
  const [deleteAircraft, { loading: deletingAircraft, }] = useMutation<DeleteAircraft>(DELETE_AIRCRAFT_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateAircraft, { loading: updatingAircraft, }] = useMutation<UpdateAircraft>(UPDATE_AIRCRAFT_QUERY, { notifyOnNetworkStatusChange: true, });


  const resetForm = () => {
    if (aircraft === undefined) return;
    setNewName(aircraft?.name ?? "");
    setNewDesignation(aircraft.designation);
    setNewManufacturerGuid(aircraft.manufacturer.guid);
  };

  useEffect(() => {
    if (aircraft !== undefined) {
      resetForm();
    }
  }, [aircraft]);

  const doDeleteAircraft = async () => {
    try {
      await deleteAircraft({
        variables: {
          guid,
        }
      });
      navigate("/aircrafts/");
    } catch (err) {
      console.log("Failed to get delete Aircraft");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateAircraft = async () => {
    try {
      await updateAircraft({
        variables: {
          guid,

          name: newName,
          designation: newDesignation,
          manufacturerGuid: newManufacturerGuid,
        }
      });
      refetchAircraft();
    } catch (err) {
      console.log("Failed to get update Aircraft");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <ConfirmationModal
          title="Delete Aircarft"
          contents={(
            <>
              <p>
                Are you sure you want to delete this aircraft and all associated data?
              </p>
              <p>
                Warning: this action can not be undone.
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteModalVisible(false);
            doDeleteAircraft();
          }}
          visible={deleteModalVisible}
        />
        <ConfirmationModal
          title="Confirm Aircraft Changes"
          contents={(
            <>
              <p>
                Are you sure you want to save your changes to this aircraft?
              </p>
              <List>
                {aircraft?.name !== newName && (<ListItem>{aircraft?.name} {"->"} {newName}</ListItem>)}
                {aircraft?.designation !== newDesignation && (<ListItem>{aircraft?.designation} {"->"} {newDesignation}</ListItem>)}
                {aircraft?.manufacturer.guid !== newManufacturerGuid && (<ListItem>{aircraft?.manufacturer?.name ?? "Invalid"} {"->"} {aircraftManufacturers.find((m) => m.guid === newManufacturerGuid)?.name ?? "Invalid"}</ListItem>)}
              </List>
            </>
          )}
          onClose={(): void => {
            setUpdateModalVisible(false);
          }}
          onConfirm={(): void => {
            setUpdateModalVisible(false);
            doUpdateAircraft();
          }}
          visible={updateModalVisible}
        />

        {fetchingAircraft ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || aircraft === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Aircraft Not Found"
              >
                <p>Failed to load aircraft. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>
                <Grid style={{ marginBottom: 40, }}>
                  <GridItem span={4}>
                    <Form>
                      <FormGroup
                        label="Name"
                        type="string"
                        fieldId="name"
                      >
                        <TextInput
                          value={newName}
                          onChange={(val) => { setNewName(val); }}
                          aria-label="Aircraft Name Text Input"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Designation"
                        type="string"
                        fieldId="designation"
                      >
                        <TextInput
                          value={newDesignation ?? ""}
                          onChange={(val) => { setNewDesignation(val); }}
                          aria-label="Aircraft Designation Text Input"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Manufacturer"
                        fieldId="manufacturer"
                      >
                        <Select
                          isOpen={manufacturerDropdownOpen}
                          onToggle={(expanded) => { setManufacturerDropdownOpen(expanded); }}
                          selections={newManufacturerGuid}
                        >
                          {aircraftManufacturers.map((manufacturer) => (
                            <SelectOption
                              key={manufacturer.guid}
                              value={manufacturer.guid}
                              onClick={() => { setNewManufacturerGuid(manufacturer.guid); }}
                            >
                              {manufacturer.name}
                            </SelectOption>
                          ))}
                        </Select>
                      </FormGroup>
                      <Button
                        disabled={updatingAircraft}
                        onClick={() => { setUpdateModalVisible(true); }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </GridItem>
                </Grid>

                <Flex style={{ marginTop: 20, }}>
                  <FlexItem>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDeleteModalVisible(true);
                      }}
                      disabled={deletingAircraft}
                    >
                      Delete Aircraft
                    </Button>
                  </FlexItem>
                </Flex>
              </>
            )}
          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
