import { useContext } from "react";
import { TravelerContext } from "./TravelerContext";

export const useTraveler = () => {
    const {
        meTraveler,
    } = useContext(TravelerContext);

    return {
        meTraveler,
    };
};
