import { createContext } from "react";
import { GetMe_me } from "../../__generated__/api";


export interface IUserContext {
    me?: GetMe_me | null
}


export const UserContext = createContext<IUserContext>({
    me: null,
});
