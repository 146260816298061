import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Alert, Button, Flex, FlexItem, Label, OverflowMenu, OverflowMenuContent, OverflowMenuGroup, OverflowMenuItem, PageSection, SearchInput, Tooltip
} from "@patternfly/react-core";
import { CheckCircleIcon, ExclamationCircleIcon } from "@patternfly/react-icons";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import PageContainer from "../Components/PageContainer";
import { GET_FLIGHTS_QUERY, SET_REGISTERED_FLIGHT_STATUS_MUTATION, TOGGLE_FLIGHT_ROLL_CALL_QUERY } from "../graphql/queries";
import { FlightStatusType, GenerateRollCall_generateRollCall, GetFlights, GetFlights_flights, SetRegisteredFlightStatus, ToggleFlightRollCall } from "../__generated__/api";


enum FilterMode {
  All,
  Upcoming,
  TBD,
  Complete,
}


const RollCall = () => {
  const apolloClient = useApolloClient();

  const [allFlights, setAllFlights] = useState<GetFlights_flights[]>([]);

  const [filteredFlights, setFilteredFlights] = useState<GetFlights_flights[]>([]);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.All);
  const [departureCodeSearchValue, setDepartureCodeSearchValue] = useState<string>("");
  const [arrivalCodeSearchValue, setArrivalCodeSearchValue] = useState<string>("");

  const departureCodeSearchRef = createRef<HTMLInputElement>();

  const [lastGeneratedRollCall, setLastGeneratedRollCall] = useState<GenerateRollCall_generateRollCall[]>([]);
  const [lastGeneratedRollCallFlight, setLastGeneratedRollCallFlight] = useState<GetFlights_flights | undefined>(undefined);
  const [generatedRollCallModalOpen, setGeneratedRollCallModalOpen] = useState<boolean>(false);

  const [resetSeatsFlight, setResetSeatsFlight] = useState<GetFlights_flights | undefined>(undefined);
  const [resetSeatsModalOpen, setResetSeatsModalOpen] = useState<boolean>(false);

  const { refetch: refetchDepartingFlights } = useQuery<GetFlights>(
    GET_FLIGHTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 1000,
      onCompleted: async (resp) => {
        setAllFlights(resp.flights);
      },
    }
  );
  const [setRegisteredFlightStatus, { loading: isSettingRegisteredFlightStatus }] = useMutation<SetRegisteredFlightStatus>(SET_REGISTERED_FLIGHT_STATUS_MUTATION, { notifyOnNetworkStatusChange: true });
  const [toggleFlightRollCall, { loading: isTogglingFlightRollCall }] = useMutation<ToggleFlightRollCall>(TOGGLE_FLIGHT_ROLL_CALL_QUERY, { notifyOnNetworkStatusChange: true });

  const [expandedFlightGuids, setExpandedFlightGuids] = React.useState<string[]>([]);
  const setFlightExpanded = (request: GetFlights_flights, isExpanding = true) =>
    setExpandedFlightGuids(prevExpanded => {
      const otherExpandedFlightGuids = prevExpanded.filter(t => t !== request.guid);
      return isExpanding ? [...otherExpandedFlightGuids, request.guid] : otherExpandedFlightGuids;
    });
  const isFlightExpanded = (request: GetFlights_flights) => expandedFlightGuids.includes(request.guid);

  const [tabIndex, setTabIndex] = useState<string | number>(0);


  const isFlightAwaitingRollCall = (flight: GetFlights_flights) => (
    flight.rollCallTime
    && !flight.hasRollCallStarted
  );

  const isFlightRollCallLate = (flight: GetFlights_flights) => (
    isFlightAwaitingRollCall(flight)
    && moment(flight.rollCallTime).isBefore(moment(), "minute")
  );

  const isFlightRollCallUpcoming = (flight: GetFlights_flights) => (
    isFlightAwaitingRollCall(flight)
    && moment(flight.rollCallTime).isSameOrAfter(moment(), "minute") // is in the future
    // && moment(flight.rollCallTime).isBefore(moment().add(1, "hour"), "minute") // is within 1 hour of now
  );

  const isFlightRollCallSoon = (flight: GetFlights_flights) => isFlightAwaitingRollCall(flight) && moment(flight.rollCallTime).isBefore(moment().add(1, "hour"), "minute");

  const getDelayedRollCallFlights = () => allFlights.filter((flight) => isFlightRollCallLate(flight));
  const getSoonFlights = () => allFlights.filter((flight) => isFlightRollCallUpcoming(flight) && isFlightRollCallSoon(flight));

  const doToggleRollCall = async (flightGuid: string, state: boolean) => {
    try {
      await toggleFlightRollCall({
        variables: {
          guid: flightGuid,
          state,
        },
      });
      await refetchDepartingFlights();
    } catch (err) {
      console.log("Failed to toggle Flight roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doAcceptGeneratedRollCall = async () => {
    try {
      for (const sr of lastGeneratedRollCall) {
        await setRegisteredFlightStatus({
          variables: {
            travelAuthGuid: sr.travelAuthorization?.guid,
            flightGuid: lastGeneratedRollCallFlight?.guid,
            flightStatus: FlightStatusType.ROLL_CALL,
          },
        });
      }
      await refetchDepartingFlights();
    } catch (err) {
      console.log("Failed to get accept generated roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doResetSeats = async () => {
    if (resetSeatsFlight === undefined) return;
    await doToggleRollCall(resetSeatsFlight.guid, false);
    try {
      for (const flst of resetSeatsFlight.flightStatuses) {
        await setRegisteredFlightStatus({
          variables: {
            travelAuthGuid: flst.spaceRequest.travelAuthorization?.guid,
            flightGuid: resetSeatsFlight?.guid,
            flightStatus: FlightStatusType.BACKLOG,
          },
        });
      }
      await refetchDepartingFlights();
    } catch (err) {
      console.log("Failed to reset seats for flight");
      console.log(JSON.stringify(err, null, 2));
    }
  };



  const getFilteredFlights = (mode: FilterMode) => {
    let newFiltered: GetFlights_flights[] = [];
    switch (mode) {
      case FilterMode.All:
        newFiltered = allFlights;
        break;
      case FilterMode.Upcoming:
        newFiltered = allFlights.filter((fl) => fl.rollCallTime && moment(fl.rollCallTime).isSameOrAfter(moment()));
        break;
      case FilterMode.TBD:
        newFiltered = allFlights.filter((fl) => !fl.rollCallTime);
        break;
      case FilterMode.Complete:
        newFiltered = allFlights.filter((fl) => fl.hasRollCallStarted);
        break;
    }

    let newSorted = _.orderBy(
      newFiltered,
      (item) => item.departureAirport.code,
      ["asc"]
    );


    if (departureCodeSearchValue !== "") {
      newSorted = newSorted.filter((p) => p.departureAirport.code.toLowerCase().includes(departureCodeSearchValue.toLowerCase()));
    }
    if (arrivalCodeSearchValue !== "") {
      newSorted = newSorted.filter((p) => p.arrivalAirport.code.toLowerCase().includes(arrivalCodeSearchValue.toLowerCase()));
    }

    return newSorted;
  };

  useEffect(() => {
    departureCodeSearchRef.current?.focus();
  }, []);

  useEffect(() => {
    const newSorted = getFilteredFlights(filterMode);
    setFilteredFlights(newSorted);
  }, [allFlights]);

  useEffect(() => {
    let newFiltered = getFilteredFlights(filterMode);
    setFilteredFlights(newFiltered);
  }, [departureCodeSearchValue, arrivalCodeSearchValue]);

  const filterDisplayedData = (mode: FilterMode) => {
    setFilterMode(mode);
    setFilteredFlights(getFilteredFlights(mode));
    departureCodeSearchRef.current?.focus();
  };


  const flightTableRowTemplate = (flight: GetFlights_flights) => {
    let rowColor = undefined;
    if (isFlightAwaitingRollCall(flight)) {
      if (isFlightRollCallUpcoming(flight)) {
        if (isFlightRollCallSoon(flight)) {
          rowColor = "#00F0005F";
        }
      }
      else if (isFlightRollCallLate(flight)) {
        rowColor = "#FF00005F";
      }
    }

    return (
      <Tbody
        key={flight.guid}
        style={{
          backgroundColor: rowColor,
        }}
      >
        <Tr>
          <Td title={flight.departureAirport.name}>{flight.departureAirport.code}</Td>
          <Td title={flight.arrivalAirport.name}>{flight.arrivalAirport.code}</Td>
          <Td>{moment(flight.departureSchedule).format("MM/DD/YYYY HH:mm")}</Td>
          <Td>{moment(flight.arrivalSchedule).format("MM/DD/YYYY HH:mm")}</Td>
          <Td>{flight.rollCallTime ? moment(flight.rollCallTime).format("MM/DD/YYYY HH:mm") : "-"}</Td>
          <Td>
            <Td>
              {!flight.hasRollCallStarted ? (
                <Tooltip content="Roll Call has not started yet">
                  <ExclamationCircleIcon size="md" color="#FFCC00" />
                </Tooltip>
              ) : (
                <Tooltip content="Roll Call has already been started">
                  <CheckCircleIcon size="md" color="#4BB543" />
                </Tooltip>
              )}
            </Td>
          </Td>
          <Td isActionCell>
            <OverflowMenu breakpoint="lg">
              <OverflowMenuContent>
                <OverflowMenuGroup groupType="button">
                  <OverflowMenuItem>
                    <Link to={`/roll-call/${flight.guid}`}>
                      <Button isSmall>Manage Flight</Button>
                    </Link>
                  </OverflowMenuItem>
                </OverflowMenuGroup>
              </OverflowMenuContent>
            </OverflowMenu>
          </Td>
        </Tr>
      </Tbody >
    );
  };

  return (
    <PageContainer>
      <PageSection>



        <Flex style={{ marginBottom: 10 }}>
          <FlexItem>
            <Label>Quick Filters</Label>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.All ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.All);
              }}
            >
              All ({getFilteredFlights(FilterMode.All).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.Upcoming ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.Upcoming);
              }}
            >
              Upcoming ({getFilteredFlights(FilterMode.Upcoming).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.TBD ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.TBD);
              }}
            >
              TBD ({getFilteredFlights(FilterMode.TBD).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.Complete ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.Complete);
              }}
            >
              Completed ({getFilteredFlights(FilterMode.Complete).length})
            </Button>
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, }}>
          <FlexItem>
            <Label>Search</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Departure Airport Code"
              value={departureCodeSearchValue}
              onChange={(newVal) => { setDepartureCodeSearchValue(newVal); }}
              onClear={() => { setDepartureCodeSearchValue(""); }}
              ref={departureCodeSearchRef}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Arrival Airport Code"
              value={arrivalCodeSearchValue}
              onChange={(newVal) => { setArrivalCodeSearchValue(newVal); }}
              onClear={() => { setArrivalCodeSearchValue(""); }}
            />
          </FlexItem>
        </Flex>

        <div style={{ marginBottom: 20 }}>
          {getSoonFlights().length > 0 && (
            <Alert
              isInline
              variant="default"
              title="Action Required"
            >
              There are {getSoonFlights().length} flight(s) scheduled to begin Roll Call within the hour.
            </Alert>
          )}
          {getDelayedRollCallFlights().length > 0 && (
            <Alert
              isInline
              variant="danger"
              title="Action Required"
            >
              There are {getDelayedRollCallFlights().length} flight(s) past their scheduled Roll Call time.
            </Alert>
          )}
        </div>

        <TableComposable aria-label="Flights table" >
          <Thead>
            <Tr>
              <Th width={10}>Departure Airport</Th>
              <Th width={10}>Arrival Airport</Th>
              <Th>Departure Date</Th>
              <Th>Arrival Date</Th>
              <Th>Roll Call Date</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          {filteredFlights.map(flightTableRowTemplate)}
        </TableComposable>

      </PageSection>
    </PageContainer>
  );
};

export default RollCall;
