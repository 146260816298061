import { gql } from "@apollo/client";


export const GET_AIRPORTS_QUERY = gql`
query GetAirports($guids: [String], $pageNumber: Int, $resultsPerPage: Int) {
  airports(guids: $guids, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid
    createdAt
    lastUpdatedAt
    
    name
    code

    location {
      lat
      lon
    }
    address {
      addressLine1
      addressLine2
      addressLine3
      city
      state
      postCode
    }
  }
}
`;

export const CREATE_AIRPORT_QUERY = gql`
mutation CreateAirport(
  $name: String!, 
  $code: String!,

  $lat: Float!,
  $lon: Float!,

  $addressLine1: String!,
  $addressLine2: String,
  $addressLine3: String,
  $city: String!,
  $state: String!,
  $postCode: String!
) {
    createAirport(
      name: $name,
      code: $code,

      lat: $lat,
      lon: $lon,

      addressLine1: $addressLine1,
      addressLine2: $addressLine2,
      addressLine3: $addressLine3,
      city: $city,
      state: $state,
      postCode: $postCode
    )
}
`;

export const UPDATE_AIRPORT_QUERY = gql`
mutation UpdateAirport(
  $guid: String!,

  $name: String,
  $code: String,

  $lat: Float,
  $lon: Float,

  $addressLine1: String,
  $addressLine2: String,
  $addressLine3: String,
  $city: String,
  $state: String,
  $postCode: String
) {
    updateAirport(
      guid: $guid,
      
      name: $name,
      code: $code,

      lat: $lat,
      lon: $lon,

      addressLine1: $addressLine1,
      addressLine2: $addressLine2,
      addressLine3: $addressLine3,
      city: $city,
      state: $state,
      postCode: $postCode
    )
}
`;

export const DELETE_AIRPORT_QUERY = gql`
mutation DeleteAirport($guid: String!) {
    deleteAirport(guid: $guid)
}
`;

export const GET_TERMINAL_DATA_QUERY = gql`
query GetTerminalData($airportGuid: String!) {
  terminalData(airportGuid: $airportGuid) {
    guid
    createdAt
    lastUpdatedAt

    hoursOfOperation
    amenities
    localHotels
    carRentals
    rail
    parkingInfo
    moneyWithdrawal
  }
}
`;

export const UPDATE_TERMINAL_DATA_QUERY = gql`
mutation UpdateTerminalData(
  $terminalDataGuid: String!,

  $hoursOfOperation: String,
  $amenities: String,
  $parkingInfo: String,
  $moneyWithdrawal: String,

  $hotels: String,
  $carRentals: String,
  $railways: String,
) {
  updateTerminalData(
    terminalDataGuid: $terminalDataGuid,

    hoursOfOperation: $hoursOfOperation,
    amenities: $amenities,
    parkingInfo: $parkingInfo,
    moneyWithdrawal: $moneyWithdrawal,

    hotels: $hotels,
    carRentals: $carRentals,
    railways: $railways,
  )
}
`;