import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  List,
  ListItem, PageSection,
  Spinner,
  TextArea,
  TextInput
} from "@patternfly/react-core";
import { Caption, TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { CREATE_FLIGHT_QUERY, DELETE_MISSION_QUERY, GET_AIRCRAFTS_QUERY, GET_AIRPORTS_QUERY, GET_MISSIONS_QUERY, UPDATE_MISSION_QUERY } from "../graphql/queries";
import { CreateFlight, DeleteMission, GetAircrafts, GetAircrafts_aircrafts, GetAirports, GetAirports_airports, GetMissions, GetMissions_missions, UpdateMission } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function Mission(props: Props) {
  const {
    guid,
  } = props;

  const [mission, setMission] = useState<GetMissions_missions | undefined>(undefined);
  const [aircrafts, setAircrafts] = useState<GetAircrafts_aircrafts[]>([]);
  const [airports, setAirports] = useState<GetAirports_airports[]>([]);

  const [newMissionId, setNewMissionId] = useState<string>();
  const [newMissionDescription, setNewMissionDescription] = useState<string>();
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);


  const { loading: fetchingMission, refetch: refetchMission, } = useQuery<GetMissions>(
    GET_MISSIONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        guids: [guid],
      },
      onCompleted: (resp) => {
        setMission(resp.missions[0]);

        setNewMissionId(resp.missions[0].externalId);
        setNewMissionDescription(resp.missions[0].description ?? undefined);
      },
    }
  );
  const [deleteMission, { loading: deletingMission, }] = useMutation<DeleteMission>(DELETE_MISSION_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateMission, { loading: updatingMission, }] = useMutation<UpdateMission>(UPDATE_MISSION_QUERY, { notifyOnNetworkStatusChange: true, });
  const { loading: fetchingAircrafts, refetch: refetchAircrafts, } = useQuery<GetAircrafts>(
    GET_AIRCRAFTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAircrafts(resp.aircrafts);
      },
    }
  );
  const { loading: fetchingAirports, refetch: refetchAirports, } = useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAirports(resp.airports);
      },
    }
  );
  const [createFlight, { loading: creatingFlight }] = useMutation<CreateFlight>(CREATE_FLIGHT_QUERY, { notifyOnNetworkStatusChange: true, });

  const doDeleteMission = async () => {
    try {
      await deleteMission({
        variables: {
          guid,
        }
      });
      navigate("/missions/");
    } catch (err) {
      console.log("Failed to get delete Mission");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateMission = async () => {
    try {
      await updateMission({
        variables: {
          guid,

          externalId: newMissionId,
          description: newMissionDescription,
        }
      });
      refetchMission();
    } catch (err) {
      console.log("Failed to get update Mission");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doCreateFlight = async () => {
    try {
      // TODO provide error feedback
      const createFlightResp = await createFlight({
        variables: {
          missionGuid: guid,

          aircraftGuid: aircrafts[0].guid,

          spaceAvailable: 0,

          departureSchedule: new Date(),
          departureAirportGuid: airports[0].guid,

          arrivalSchedule: new Date(),
          arrivalAirportGuid: airports[1].guid,
        },
      });
      navigate(`/missions/${guid}/flights/${createFlightResp.data?.createFlight}`);
    } catch (err) {
      console.log("Failed to get create Flight");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <ConfirmationModal
          title="Delete Mission"
          contents={(
            <>
              <p>
                Are you sure you want to delete this mission and all associated data?
              </p>
              <p>
                Warning: this action can not be undone.
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteModalVisible(false);
            doDeleteMission();
          }}
          visible={deleteModalVisible}
        />
        <ConfirmationModal
          title="Confirm Mission Changes"
          contents={(
            <>
              <p>
                Are you sure you want to save your changes to this mission?
              </p>
              <List>
                {mission?.externalId !== newMissionId && (<ListItem>{mission?.externalId ?? "None"} {"->"} {newMissionId}</ListItem>)}
                {mission?.description !== newMissionDescription && (<ListItem>{mission?.description ?? "None"} {"->"} {newMissionDescription}</ListItem>)}
              </List>
            </>
          )}
          onClose={(): void => {
            setUpdateModalVisible(false);
          }}
          onConfirm={(): void => {
            setUpdateModalVisible(false);
            doUpdateMission();
          }}
          visible={updateModalVisible}
        />

        {fetchingMission ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || mission === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Mission Not Found"
              >
                <p>Failed to load mission. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>
                <Grid style={{ marginBottom: 40, }}>
                  <GridItem span={4}>
                    <Form>
                      <FormGroup
                        label="Mission ID"
                        type="string"
                        fieldId="missionId"
                      >
                        <TextInput
                          value={newMissionId}
                          onChange={(val) => { setNewMissionId(val); }}
                          aria-label="Mission Id Text Input"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Mission Description"
                        type="string"
                        fieldId="missionDescription"
                      >
                        <TextArea
                          value={newMissionDescription}
                          onChange={(val) => { setNewMissionDescription(val); }}
                          aria-label="Mission Description Textarea"
                        />
                      </FormGroup>
                      <Button
                        disabled={updatingMission}
                        onClick={() => { setUpdateModalVisible(true); }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </GridItem>
                </Grid>

                <Flex style={{ marginBottom: 10, }}>
                  <FlexItem>
                    <Button
                      onClick={() => { doCreateFlight(); }}
                    >
                      Create Flight
                    </Button>
                  </FlexItem>
                </Flex>
                <TableComposable
                  aria-label="Missions Table"
                  variant={"compact"}
                  borders
                >
                  <Caption>Registered Flights for Mission</Caption>
                  <Thead className="pf-m-fit-content">
                    <Tr>
                      <Th>Aircraft</Th>
                      <Th>Available Seats</Th>
                      <Th>Departure Airport</Th>
                      <Th>Scheduled Departure</Th>
                      <Th>Actual Departure</Th>
                      <Th>Arrival Airport</Th>
                      <Th>Scheduled Arrival</Th>
                      <Th>Actual Arrival</Th>
                      <Th>Roll Call</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mission.flights.map((flight) => (
                      <Tr key={flight.guid}>
                        <Td>{flight.aircraft.manufacturer.name} {flight.aircraft.designation} {flight.aircraft.name}</Td>
                        <Td>{flight.spaceAvailable}</Td>
                        <Td title={flight.departureAirport.name}>{flight.departureAirport.code}</Td>
                        <Td>{moment(flight.departureSchedule).format("MM/DD/YYYY HH:mm")}</Td>
                        <Td>{!flight.departureActual ? "-" : moment(flight.departureActual).format("MM/DD/YYYY HH:mm")}</Td>
                        <Td title={flight.arrivalAirport.name}>{flight.arrivalAirport.code}</Td>
                        <Td>{moment(flight.arrivalSchedule).format("MM/DD/YYYY HH:mm")}</Td>
                        <Td>{!flight.arrivalActual ? "-" : moment(flight.arrivalActual).format("MM/DD/YYYY HH:mm")}</Td>
                        <Td>{!flight.rollCallTime ? "-" : moment(flight.rollCallTime).format("MM/DD/YYYY HH:mm")}</Td>
                        <Td>
                          <Link to={`/missions/${mission.guid}/flights/${flight.guid}`}>
                            <Button>Edit</Button>
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </TableComposable>

                <Flex style={{ marginTop: 20, }}>
                  <FlexItem>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDeleteModalVisible(true);
                      }}
                      disabled={deletingMission}
                    >
                      Delete Mission
                    </Button>
                  </FlexItem>
                </Flex>
              </>
            )}
          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
