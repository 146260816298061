import { FlexItem, Card, CardHeader, Flex, CardTitle, CardBody, TextContent, TextVariants, CardFooter, Button, Text, Sidebar, SidebarContent, SidebarPanel, Tooltip } from "@patternfly/react-core";
import { OutlinedFileAltIcon, UploadIcon } from "@patternfly/react-icons";
import { FileWithPath, fromEvent } from "file-selector";
import moment from "moment";
import { useRef } from "react";
import Empty from "../Empty";

interface Props {
  documents: any[];
  onDelete?: (guid: string) => void;
  onCreate?: (file: FileWithPath | DataTransferItem) => void;
  readonly?: boolean;
}

export default (props: Props) => {
  const {
    documents,
    onDelete,
    onCreate,
    readonly,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const documentCardTemplate = (document: any) => (
    <FlexItem key={document.guid}>
      <Card style={{ position: "relative" }} >
        <CardHeader>
          <Flex style={{ width: "100%", }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <OutlinedFileAltIcon size="xl" />
            </FlexItem>
          </Flex>
        </CardHeader>
        <CardTitle>{document.fileName}</CardTitle>
        <CardBody>
          <TextContent>
            <Text component={TextVariants.small} style={{ textAlign: "center" }}>
              Uploaded {moment(document.createdAt).format("MM/DD/YYYY")}
            </Text>
          </TextContent>
        </CardBody>
        <CardFooter>
          <Flex justifyContent={{ default: "justifyContentCenter" }}>
            <FlexItem>
              <a href={`${process.env.REACT_APP_S3_URL}/${document.filePathS3}`} target="_blank">View</a>
            </FlexItem>
            {!readonly && (
              <FlexItem>
                <Button
                  variant='link'
                  isDanger
                  onClick={() => {
                    if (onDelete !== undefined) {
                      if (confirm("Are you sure you want to delete this file?")) {
                        onDelete(document.guid);
                      }
                    }
                  }}
                >
                  Delete
                </Button>
              </FlexItem>
            )}
          </Flex>
        </CardFooter>
      </Card>
    </FlexItem>
  );

  return (
    <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", padding: 20, }}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={async (e) => {
          const files = await fromEvent(e);
          const file = files[0];
          if (file === undefined) return;

          if (onCreate !== undefined) {
            onCreate(file);
          }
        }}
      />
      {documents.length === 0 ? (
        <Empty
          title={"No Documents Found"}
          description={"No documents have been uploaded."}
          action={(readonly ? undefined : (
            <Button
              variant="primary"
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              Upload Document
            </Button>
          ))}
        />
      ) : (
        <Flex direction={{ default: "row" }} flexWrap={{ default: "nowrap" }} alignItems={{ default: "alignItemsCenter" }}>
          {!readonly && (
            <FlexItem>
              <Tooltip content={<p>Click to select files</p>}>
                <div
                  style={{
                    border: "3px dashed #d2d2d2",
                    marginBottom: 0,
                    height: 128,
                    width: 128,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                >
                  <Flex justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }} direction={{ default: "column" }} style={{ height: "100%" }}>
                    <FlexItem>
                      <UploadIcon size="lg" color="#d2d2d2" />
                    </FlexItem>
                    <FlexItem>
                      <p style={{ color: "#d2d2d2" }}>Select Files</p>
                    </FlexItem>
                  </Flex>
                </div>
              </Tooltip>
            </FlexItem>
          )}
          <FlexItem grow={{ default: "grow" }}>
            <Flex direction={{ default: "row" }} style={{ rowGap: 20, paddingLeft: 20, paddingRight: 20, }}>
              {documents.map(documentCardTemplate)}
            </Flex>
          </FlexItem>
        </Flex>
      )}
    </div>
  );


}