import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  FormGroup,
  Modal,
  ModalVariant,
  PageSection,
  TextArea,
  TextInput
} from "@patternfly/react-core";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { CREATE_MISSION_QUERY, GET_MISSIONS_QUERY } from "../graphql/queries";
import { CreateMission, GetMissions, GetMissions_missions } from "../__generated__/api";

export default function Missions() {
  const [missions, setMissions] = useState<GetMissions_missions[]>([]);

  const { loading: fetchingMissions, refetch: refetchMissions, } = useQuery<GetMissions>(
    GET_MISSIONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setMissions(resp.missions);
      },
    }
  );
  const [createMission, { loading: creatingMission }] = useMutation<CreateMission>(CREATE_MISSION_QUERY, { notifyOnNetworkStatusChange: true, });

  const doCreateMission = async () => {
    try {
      const createMissionResp = await createMission({
        variables: {
          externalId: `New Mission ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        }
      });
      navigate(`/missions/${createMissionResp.data?.createMission}`);
    } catch (err) {
      console.log("Failed to get create Mission");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <Flex style={{ marginBottom: 20, }}>
          <FlexItem>
            <Button
              disabled={creatingMission}
              variant="primary"
              onClick={() => { doCreateMission(); }}
            >
              Create Mission
            </Button>
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Missions Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>Mission ID</Th>
              <Th># Flights</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {missions.map((mission) => (
              <Tr key={mission.guid}>
                <Td>{mission.externalId}</Td>
                <Td>{mission.flights.length}</Td>
                <Td>{moment(mission.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(mission.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>

                <Td>
                  <Link to={`/missions/${mission.guid}`}>
                    <Button isSmall>Edit</Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>
      </PageSection>
    </PageContainer>
  );
}
