import { useMutation, useQuery } from "@apollo/client";
import {
  ActionList,
  ActionListItem, Button,
  CodeBlock,
  CodeBlockCode, Flex,
  FlexItem, Label, PageSection, SearchInput, Text,
  TextArea,
  Title,
  TitleSizes
} from "@patternfly/react-core";
import {
  ExpandableRowContent, TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@patternfly/react-table";
import _ from "lodash";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import DocumentManager from "../Components/DocumentManager";
import HorizontalTable from "../Components/HorizontalTable";
import PageContainer from "../Components/PageContainer";
import TravelerManager from "../Components/TravelerManager";
import { useUser } from "../contexts/UserProvider";
import {
  GET_TRAVEL_AUTHORIZATIONS_QUERY,
  UPDATE_TRAVEL_AUTHORIZATION_STATUS_QUERY
} from "../graphql/queries";
import {
  getLatestTravelAuthorizationReview,
  organizeTravelAuths
} from "../utils/upgrade-utils";
import {
  GetTravelAuthorizations,
  GetTravelAuthorizations_travelAuthorizations,
  ReviewStatus,
  SponsorRelationship,
  UpdateTravelAuthorizationStatus
} from "../__generated__/api";

enum FilterMode {
  All,
  Approved,
  Pending,
  Denied,
}

const TravelAuthorizations = () => {
  const [allAuths, setAllAuths] = useState<GetTravelAuthorizations_travelAuthorizations[]>([]);

  const [filteredAuths, setFilteredAuths] = useState<GetTravelAuthorizations_travelAuthorizations[]>([]);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.Pending);
  const [passengerNameSearchValue, setPassengerNameSearchValue] = useState<string>("");
  const [passengerIdSearchValue, setPassengerIdSearchValue] = useState<string>("");
  const [passengerEmailSearchValue, setPassengerEmailSearchValue] = useState<string>("");

  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>();
  const [targetAuth, setTargetAuth] = useState<GetTravelAuthorizations_travelAuthorizations>();
  const [targetAuthOldStatus, setTargetAuthOldStatus] = useState<ReviewStatus>();
  const [targetAuthNewStatus, setTargetAuthNewStatus] = useState<ReviewStatus>();
  const [targetAuthStatusChangeReason, setTargetAuthStatusChangeReason] = useState<string>();

  const [expandedAuthGuids, setExpandedAuthGuids] = React.useState<string[]>([]);

  const passengerNameSearchRef = createRef<HTMLInputElement>();

  const setAuthExpanded = (auth: GetTravelAuthorizations_travelAuthorizations, isExpanding = true) => {
    return setExpandedAuthGuids((prevExpanded) => {
      const otherexpandedAuthGuids = prevExpanded.filter((t) => t !== auth.guid);
      return isExpanding ? [auth.guid] : otherexpandedAuthGuids;
    });
  };

  const isAuthExpanded = (auth: GetTravelAuthorizations_travelAuthorizations) => expandedAuthGuids.includes(auth.guid);

  const { me } = useUser();

  useQuery<GetTravelAuthorizations>(GET_TRAVEL_AUTHORIZATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    pollInterval: 1000,
    onCompleted: (data) => {
      setAllAuths(data.travelAuthorizations);
    },
    onError: (err) => {
      console.log("Failed to get Travel Authorizations");
      console.log(JSON.stringify(err, null, 2));
    },
  });
  const [updateTravelAuthorizationStatus] = useMutation<UpdateTravelAuthorizationStatus>(UPDATE_TRAVEL_AUTHORIZATION_STATUS_QUERY, { notifyOnNetworkStatusChange: true });

  const doUpdateTravelAuthorizationStatus = async (auth: GetTravelAuthorizations_travelAuthorizations, status: ReviewStatus) => {
    setAuthExpanded(auth, false);
    try {
      // TODO -- this is a temporary optimization
      // it is not practical currently because one user could change the status and the page wouldn't know
      // the solution is to make a claims system
      // have each agent be required to claim a travel authorization to review (this could be automatic) and set its status to UNDER_REVIEW
      // make all authorizations with an UNDER_REVIEW status READONLY unless they are claimed by the user who is viewing them
      const authsWhereRemoved = allAuths.filter((a) => a.guid !== auth.guid);
      const authClone = _.cloneDeep(auth);
      authClone.reviewHistory.push({
        createdAt: new Date(),
        guid: "",
        message: targetAuthStatusChangeReason ?? "",
        status,
      });
      setAllAuths([...authsWhereRemoved, authClone]);

      await updateTravelAuthorizationStatus({
        variables: {
          guid: auth.guid,
          status: status,

          reviewerGuid: me?.guid,
          statusMessage: targetAuthStatusChangeReason,
        },
      });
      console.log("Updated travel authorization status");
    } catch (err) {
      console.error("Failed to update travel authorization status");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const getFilteredTravelAuths = (mode: FilterMode) => {
    let newFiltered: GetTravelAuthorizations_travelAuthorizations[] = [];

    const { approvedActive, approvedExpired, denied, submitted, underReview } = organizeTravelAuths(allAuths);
    const approved = [...approvedActive, ...approvedExpired];
    const pending = [...submitted, ...underReview];

    switch (mode) {
      case FilterMode.All:
        newFiltered = allAuths;
        break;
      case FilterMode.Approved:
        newFiltered = approved;
        break;
      case FilterMode.Pending:
        newFiltered = pending;
        break;
      case FilterMode.Denied:
        newFiltered = denied;
        break;
    }

    let newSorted = _.orderBy(
      newFiltered,
      (auth) => {
        const latestReview = getLatestTravelAuthorizationReview(auth);
        return latestReview?.createdAt;
      },
      ["desc"]
    );


    if (passengerNameSearchValue !== "") {
      newSorted = newSorted.filter((auth) => {
        const sponsorTraveler = auth.travelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF);
        if (sponsorTraveler === undefined) return undefined;
        return (
          `${sponsorTraveler.firstName.toLowerCase()} ${sponsorTraveler.middleName?.toLowerCase()} ${sponsorTraveler.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
          || `${sponsorTraveler.firstName.toLowerCase()} ${sponsorTraveler.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
        );
      });
    }
    if (passengerIdSearchValue !== "") {
      newSorted = newSorted.filter((auth) => {
        const sponsorTraveler = auth.travelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF);
        if (sponsorTraveler === undefined) return undefined;
        return sponsorTraveler.dodId.includes(passengerIdSearchValue);
      });
    }
    if (passengerEmailSearchValue !== "") {
      newSorted = newSorted.filter((auth) => {
        const sponsorTraveler = auth.travelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF);
        if (sponsorTraveler === undefined) return undefined;
        return sponsorTraveler.email.includes(passengerEmailSearchValue.toLowerCase());
      });
    }

    return newSorted;
  };

  useEffect(() => {
    passengerNameSearchRef.current?.focus();
  }, []);

  useEffect(() => {
    const newFiltered = getFilteredTravelAuths(filterMode);
    setFilteredAuths(newFiltered);
  }, [allAuths]);

  useEffect(() => {
    let newFiltered = getFilteredTravelAuths(filterMode);
    setFilteredAuths(newFiltered);
  }, [passengerNameSearchValue, passengerIdSearchValue, passengerEmailSearchValue]);

  const filterDisplayedData = (mode: FilterMode) => {
    setFilterMode(mode);
    setFilteredAuths(getFilteredTravelAuths(mode));
    passengerNameSearchRef.current?.focus();
  };

  const authTableRowTemplate = (
    auth: GetTravelAuthorizations_travelAuthorizations,
    rowIndex: number
  ) => {
    const latestReview = getLatestTravelAuthorizationReview(auth);
    const sponsor = auth.sponsor;
    const traveler = sponsor.travelers.find(
      (t) => t.sponsorRelationship === SponsorRelationship.SELF
    );
    return (
      <Tbody key={auth.guid} isExpanded={isAuthExpanded(auth)}>
        {isAuthExpanded(auth) ? (
          <Tr
            onClick={() => {
              setAuthExpanded(auth, !isAuthExpanded(auth));
            }}
            style={{ backgroundColor: "lightblue", cursor: "pointer" }}
          >
            <Td dataLabel={"Date Submitted"}>
              {moment(latestReview?.createdAt).format("MM/DD/YYYY HH:mm:ss")}
            </Td>
            <Td>{auth.category?.replace("_", " ")}</Td>
            <Td
              style={{ whiteSpace: "nowrap" }}
            >{`${traveler?.firstName} ${traveler?.lastName}`}</Td>
            <Td>{latestReview?.status.replace("_", " ")}</Td>
          </Tr>
        ) : (
          <Tr
            onClick={() => {
              setAuthExpanded(auth, !isAuthExpanded(auth));
            }}
            style={{ cursor: "pointer" }}
          >
            <Td dataLabel={"Date Submitted"}>
              {moment(latestReview?.createdAt).format("MM/DD/YYYY HH:mm:ss")}
            </Td>
            <Td>{auth.category?.replace("_", " ")}</Td>
            <Td
              style={{ whiteSpace: "nowrap" }}
            >{`${traveler?.firstName} ${traveler?.lastName}`}</Td>
            <Td>{latestReview?.status.replace("_", " ")}</Td>
          </Tr>
        )}
      </Tbody>
    );
  };

  const expandedTemplate = (
    auth: GetTravelAuthorizations_travelAuthorizations,
    rowIndex: number
  ) => {
    const latestReview = getLatestTravelAuthorizationReview(auth);
    const sponsor = auth.sponsor;
    const traveler = sponsor.travelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF);
    if (traveler === undefined) return null;
    return (
      <Tbody key={auth.guid}>
        <Tr isExpanded={isAuthExpanded(auth)}>
          <Td />
          <Td dataLabel={`auth ${auth.guid} description`} colSpan={3}>
            <ExpandableRowContent>

              <Flex direction={{ default: "column" }}>
                <FlexItem>
                  <ActionList>
                    {latestReview?.status ===
                      ReviewStatus.APPROVED && (
                        <>
                          <ActionListItem >
                            <Button
                              onClick={() => {
                                setTargetAuth(auth);
                                setTargetAuthOldStatus(
                                  latestReview.status
                                );
                                setTargetAuthNewStatus(
                                  ReviewStatus.SUBMITTED
                                );
                                setConfirmationModalVisible(true);
                              }}
                            >
                              Un-Approve
                            </Button>
                          </ActionListItem>
                        </>
                      )}
                    {latestReview?.status ===
                      ReviewStatus.DENIED && (
                        <>
                          <ActionListItem>
                            <Button
                              onClick={() => {
                                setTargetAuth(auth);
                                setTargetAuthOldStatus(
                                  latestReview.status
                                );
                                setTargetAuthNewStatus(
                                  ReviewStatus.SUBMITTED
                                );
                                setConfirmationModalVisible(true);
                              }}
                            >
                              Un-Deny
                            </Button>
                          </ActionListItem>
                        </>
                      )}
                    {latestReview?.status === ReviewStatus.SUBMITTED && (
                      <>
                        <ActionListItem>
                          <Button
                            onClick={() => {
                              setTargetAuth(auth);
                              setTargetAuthOldStatus(
                                latestReview.status
                              );
                              setTargetAuthNewStatus(
                                ReviewStatus.APPROVED
                              );
                              setConfirmationModalVisible(true);
                            }}
                          >
                            Approve
                          </Button>
                        </ActionListItem>
                        <ActionListItem>
                          <Button
                            variant="danger"
                            onClick={() => {
                              setTargetAuth(auth);
                              setTargetAuthOldStatus(
                                latestReview.status
                              );
                              setTargetAuthNewStatus(
                                ReviewStatus.DENIED
                              );
                              setConfirmationModalVisible(true);
                            }}
                          >
                            Deny
                          </Button>
                        </ActionListItem>
                      </>
                    )}
                  </ActionList>
                </FlexItem>
                <FlexItem>
                  <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                    Personal Information
                  </Title>
                  <HorizontalTable
                    rows={[
                      { title: "First Name", value: traveler.firstName, },
                      { title: "Middle Name", value: traveler.middleName, },
                      { title: "Last Name", value: traveler.lastName, },
                      { title: "Email Address", value: traveler.email, valueHref: `mailto:${traveler.email}`, },
                      { title: "DOD ID", value: traveler.dodId, valueHref: `mailto:${traveler.email}`, },
                      { title: "Category", value: auth.category.replace("_", " ") },
                      { title: "Status Date", value: moment(auth.leaveStatusDate).format("MM/DD/YYYY") },
                      { title: "Start Date", value: moment(auth.startDate).format("MM/DD/YYYY") },
                      { title: "End Date", value: moment(auth.endDate).format("MM/DD/YYYY") },
                    ]}
                  />
                </FlexItem>
                <FlexItem>
                  <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                    Associated Document(s)
                  </Title>
                  <DocumentManager
                    documents={auth.travelAuthorizationDocuments}
                    readonly
                  />
                </FlexItem>
                <FlexItem>
                  <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                    Associated Traveler(s)
                  </Title>
                  <TravelerManager
                    travelers={auth.travelers}
                    noneTitle="No Associated Travelers"
                    noneMessage="This message should never appear as this should be impossible."
                  />
                </FlexItem>
                <FlexItem>
                  <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                    Review History
                  </Title>
                  <CodeBlock>
                    <CodeBlockCode>
                      {auth.reviewHistory.map((rh) => (
                        <p key={rh.guid}>
                          {moment(rh.createdAt).format("MM/DD/YYYY HH:mm:SS")}
                          {" "}
                          {rh.status.replace("_", " ")}
                        </p>
                      ))}
                    </CodeBlockCode>
                  </CodeBlock>
                </FlexItem>
              </Flex>
            </ExpandableRowContent>
          </Td>
        </Tr>
      </Tbody >
    );
  };

  const confirmModalTemplate = () => (
    <ConfirmationModal
      title="Confirm Travel Authorization Status Change"
      contents={
        <>
          <Flex direction={{ default: "column" }}>
            <FlexItem>
              <Text>
                Please confirm that you wish to change the status of
                this Travel Authorization from {targetAuthOldStatus} to{" "}
                {targetAuthNewStatus}.
              </Text>
            </FlexItem>
            <FlexItem>
              <TextArea
                aria-label="Travel Authorization status change reason"
                autoResize
                placeholder="Enter a reason here (optional)"
                onBlur={(e) =>
                  setTargetAuthStatusChangeReason(e.target.value)
                }
              />
            </FlexItem>
          </Flex>
        </>
      }
      onClose={() => {
        setConfirmationModalVisible(false);
        setTargetAuth(undefined);
        setTargetAuthOldStatus(undefined);
        setTargetAuthNewStatus(undefined);
      }}
      onConfirm={() => {
        setConfirmationModalVisible(false);
        console.log(
          `Modify travel auth status from ${targetAuthOldStatus} to ${targetAuthNewStatus} for GUID ${targetAuth?.guid}`
        );
        if (
          targetAuth !== undefined &&
          targetAuthNewStatus !== undefined
        ) {
          doUpdateTravelAuthorizationStatus(
            targetAuth,
            targetAuthNewStatus
          );
        }
      }}
      visible={confirmationModalVisible}
    />
  );

  return (
    <PageContainer>
      <PageSection>
        {confirmModalTemplate()}

        <Flex style={{ marginBottom: 10 }}>
          <FlexItem>
            <Label>Quick Filters</Label>
          </FlexItem>
          <FlexItem>
            <Button
              variant={
                filterMode === FilterMode.All ? "primary" : "tertiary"
              }
              onClick={() => {
                filterDisplayedData(FilterMode.All);
              }}
            >
              All ({getFilteredTravelAuths(FilterMode.All).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={
                filterMode === FilterMode.Approved ? "primary" : "tertiary"
              }
              onClick={() => {
                filterDisplayedData(FilterMode.Approved);
              }}
            >
              Approved ({getFilteredTravelAuths(FilterMode.Approved).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={
                filterMode === FilterMode.Pending ? "primary" : "tertiary"
              }
              onClick={() => {
                filterDisplayedData(FilterMode.Pending);
              }}
            >
              Pending Approval ({getFilteredTravelAuths(FilterMode.Pending).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={
                filterMode === FilterMode.Denied ? "primary" : "tertiary"
              }
              onClick={() => {
                filterDisplayedData(FilterMode.Denied);
              }}
            >
              Denied ({getFilteredTravelAuths(FilterMode.Denied).length})
            </Button>
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, }}>
          <FlexItem>
            <Label>Search</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by name"
              value={passengerNameSearchValue}
              onChange={(newVal) => { setPassengerNameSearchValue(newVal); }}
              onClear={() => { setPassengerNameSearchValue(""); }}
              ref={passengerNameSearchRef}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by DOD ID"
              value={passengerIdSearchValue}
              onChange={(newVal) => { setPassengerIdSearchValue(newVal); }}
              onClear={() => { setPassengerIdSearchValue(""); }}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by email"
              value={passengerEmailSearchValue}
              onChange={(newVal) => { setPassengerEmailSearchValue(newVal); }}
              onClear={() => { setPassengerEmailSearchValue(""); }}
            />
          </FlexItem>
        </Flex>
        <Flex direction={{ default: "row" }} flexWrap={{ default: "nowrap" }}>
          <FlexItem grow={{ default: "grow" }}>
            <TableComposable aria-label="Travel Authorizations table">
              <Thead>
                <Tr>
                  <Th>Submitted At</Th>
                  <Th>Category</Th>
                  <Th>Submitted By</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              {filteredAuths.map(authTableRowTemplate)}
            </TableComposable>
          </FlexItem>
          {expandedAuthGuids.length > 0 && (
            <FlexItem>
              <TableComposable aria-label="Travel Authorizations table">
                {filteredAuths.map(expandedTemplate)}
              </TableComposable>
            </FlexItem>
          )}
        </Flex>
      </PageSection>
    </PageContainer>
  );
};

export default TravelAuthorizations;
