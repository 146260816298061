import { useMutation, useQuery } from "@apollo/client";
import { Button, Flex, FlexItem, FormGroup, PageSection, Select, SelectOption, SelectVariant, Text, TextContent, TextInput, TextVariants } from "@patternfly/react-core";
import { MapMarkedAltIcon, MapPinIcon } from "@patternfly/react-icons";
import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import moment from "moment";
import React, { useState } from "react";
import AirportSelector from "../Components/AirportSelector";
import PageContainer from "../Components/PageContainer";
import { useUser } from "../contexts";
import { GET_AIRPORTS_QUERY, GET_LUGGAGE_GROUP_TAGS_QUERY, GET_LUGGAGE_TAGS_QUERY, UPDATE_LUGGAGE_GROUP_TAG_QUERY, UPDATE_LUGGAGE_TAG_QUERY } from "../graphql/queries";
import { GetAirports, GetAirports_airports, GetLuggageGroupTags, GetLuggageGroupTags_luggageGroupTags, GetLuggageTags, GetLuggageTags_luggageTags, LuggageCheckState, UpdateLuggageGroupTag, UpdateLuggageTag } from "../__generated__/api";

enum WizardStep {
  Scan,
  SelectAction,
  ViewHistory,
  Update,
}

export default function TrackLuggage() {

  const { me } = useUser();

  const [luggageTags, setLuggageTags] = useState<GetLuggageTags_luggageTags[]>([]);
  const [luggageGroupTags, setLuggageGroupTags] = useState<GetLuggageGroupTags_luggageGroupTags[]>([]);
  const [airports, setAirports] = useState<GetAirports_airports[]>([]);

  const [targetAirport, setTargetAirport] = useState<GetAirports_airports | undefined>(undefined);

  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);
  const [targetStatus, setTargetStatus] = useState<LuggageCheckState | undefined>(undefined);

  const [tagGuid, setTagGuid] = useState<string>("");

  const [step, setStep] = useState<WizardStep>(WizardStep.Scan);


  const getSingleTag = () => luggageTags.find((lt) => lt.guid === tagGuid);
  const getGroupTag = () => luggageGroupTags.find((lgt) => lgt.guid === tagGuid);

  const getTag = () => {
    const singleTag = getSingleTag();
    const groupTag = getGroupTag();

    if (singleTag !== undefined) return singleTag;
    if (groupTag !== undefined) return groupTag;

    return undefined;
  };


  useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setAirports(resp.airports);
      },
    }
  );
  const { refetch: refetchLuggageTags, } = useQuery<GetLuggageTags>(
    GET_LUGGAGE_TAGS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setLuggageTags(resp.luggageTags);
      },
    }
  );
  const { refetch: refetchLuggageGroupTags, } = useQuery<GetLuggageGroupTags>(
    GET_LUGGAGE_GROUP_TAGS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setLuggageGroupTags(resp.luggageGroupTags);
      },
    }
  );
  const [updateTag] = useMutation<UpdateLuggageTag>(UPDATE_LUGGAGE_TAG_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateGroupTag] = useMutation<UpdateLuggageGroupTag>(UPDATE_LUGGAGE_GROUP_TAG_QUERY, { notifyOnNetworkStatusChange: true, });

  const doUpdateTag = async () => {


    const singleTag = getSingleTag();
    const groupTag = getGroupTag();

    try {
      if (singleTag !== undefined) {
        await updateTag({
          variables: {
            tagGuid: singleTag.guid,
            updatingUserGuid: me?.guid,
            currentAirportGuid: targetAirport?.guid,
            newState: targetStatus,
          }
        });
      } else if (groupTag !== undefined) {
        await updateGroupTag({
          variables: {
            tagGuid: groupTag.guid,
            updatingUserGuid: me?.guid,
            currentAirportGuid: targetAirport?.guid,
            newState: targetStatus,
          }
        });
      }

      setStep(WizardStep.ViewHistory);
      setTargetAirport(undefined);
      setTargetStatus(undefined);

      await refetchLuggageTags();
      await refetchLuggageGroupTags();

      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to get update LuggageTag");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const scanTagInputRef = React.createRef<HTMLInputElement>();

  const getAirportByGuid = (guid: string) => airports.find((lt) => lt.guid === guid);

  const scanTemplate = () => (
    <Flex alignItems={{ default: "alignItemsCenter" }} direction={{ default: "row" }}>
      <FlexItem>
        {/* Header */}
        <TextContent style={{ marginBottom: 10, }}>
          <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Scan Printed Tag</Text>
        </TextContent>
        {/* Input Tag Guid */}
        <FormGroup fieldId="tag" style={{ marginBottom: 10, }}>
          <TextInput
            aria-label="Luggage Tag ID"
            validated={(getTag() !== undefined ? "success" : "error")}
            title={getTag() === undefined ? "Invalid ID" : "Valid ID"}
            placeholder="e.g. c636e49d-2216-4012-92ba-49e7010ffb34"
            autoFocus
            type="text"
            value={tagGuid}
            onChange={(val) => {
              if (val == "") {
                setTagGuid("");
                return;
              }
              setTagGuid(val);
            }}
            ref={scanTagInputRef}
          />
        </FormGroup>
        <Button
          style={{ width: "100%" }}
          variant="secondary"
          isDisabled={getTag() === undefined}
          onClick={() => {
            setStep(WizardStep.SelectAction);
          }}
        >
          Continue
        </Button>
      </FlexItem>
    </Flex>
  );

  const actionsTemplate = () => (
    <>
      <TextContent style={{ marginBottom: 40, }}>
        <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Select Action</Text>
      </TextContent>
      <Flex direction={{ default: "row" }} style={{ marginBottom: 20, }}>
        <FlexItem>
          <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <MapMarkedAltIcon size="xl" />
            </FlexItem>
            <FlexItem>
              <TextContent>
                <Text component={TextVariants.h2} style={{ textAlign: "center" }}>View History</Text>
              </TextContent>
            </FlexItem>
            <FlexItem style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                onClick={() => {
                  setStep(WizardStep.ViewHistory);
                }}
              >
                Continue
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem style={{ paddingLeft: 40, paddingRight: 40, }}>
          <TextContent style={{ marginBottom: 40, }}>
            <Text component={TextVariants.h2} style={{ textAlign: "center" }}>OR</Text>
          </TextContent>
        </FlexItem>
        <FlexItem>
          <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <MapPinIcon size="xl" />
            </FlexItem>
            <FlexItem>
              <TextContent>
                <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Update Location</Text>
              </TextContent>
            </FlexItem>
            <FlexItem style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                onClick={() => {
                  setStep(WizardStep.Update);
                }}
              >
                Continue
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </>
  );

  const historyTemplate = () => {
    const tag = getTag();
    if (tag === undefined) return null;
    return (
      <>
        <Table aria-label="Registered Luggage Details">
          <Thead>
            <Tr>
              <Th>Time</Th>
              <Th>Location</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tag.locationHistory.map((lh) => (
              <Tr key={lh.guid}>
                <Td>{moment(lh.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{lh.airport.name}</Td>
                <Td>{lh.state}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </>
    );
  };

  const updateTemplate = () => {
    return (
      <>
        <Flex direction={{ default: "column" }}>
          <FlexItem>
            <TextContent style={{}}>
              <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Select New Location</Text>
            </TextContent>
          </FlexItem>
          <FlexItem style={{ marginBottom: 20, }}>
            <FormGroup fieldId="airport">
              <AirportSelector
                onSelect={setTargetAirport}
                selectedGuid={targetAirport?.guid}
              />
            </FormGroup>
          </FlexItem>
          <FlexItem>
            <TextContent style={{}}>
              <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Select Status</Text>
            </TextContent>
          </FlexItem>
          <FlexItem style={{ marginBottom: 20, }}>
            <FormGroup fieldId="airport">
              <Select
                placeholderText="Select Status"
                variant={SelectVariant.single}
                typeAheadAriaLabel="Select status"
                onToggle={(expanded) => { setStatusDropdownOpen(expanded); }}
                onSelect={(event, selection, isPlaceholder) => {
                  if (isPlaceholder) {
                    setTargetStatus(undefined);
                    return;
                  }
                  setTargetStatus(selection.toString() as LuggageCheckState);
                  setStatusDropdownOpen(false);
                }}
                onClear={() => {
                  setTargetStatus(undefined);
                }}
                selections={targetStatus}
                isOpen={statusDropdownOpen}
                shouldResetOnSelect
              >
                <SelectOption value={LuggageCheckState.BOARDED}>Boarded</SelectOption>
                <SelectOption value={LuggageCheckState.UNBOARDED}>Unboarded</SelectOption>
              </Select>
            </FormGroup>
          </FlexItem>
          <FlexItem>
            <Button
              style={{ width: "100%" }}
              variant="primary"
              isDisabled={targetAirport === undefined || targetStatus === undefined}
              onClick={() => {
                doUpdateTag();
              }}
            >
              Save
            </Button>
          </FlexItem>
        </Flex>
      </>
    );
  };

  const stepTemplate = () => {
    switch (step) {
      case WizardStep.Scan:
        return scanTemplate();
      case WizardStep.SelectAction:
        return actionsTemplate();
      case WizardStep.Update:
        return updateTemplate();
      case WizardStep.ViewHistory:
        return historyTemplate();
    }
  };

  const previousStep = () => {
    if (step === WizardStep.SelectAction) {
      setStep(WizardStep.Scan);
    }
    else if (step === WizardStep.Update || step === WizardStep.ViewHistory) {
      setStep(WizardStep.SelectAction);
    }
  };

  return (
    <PageContainer>
      <PageSection>

        <Flex style={{ width: "100%", height: "100%" }} direction={{ default: "column" }}>

          {step !== WizardStep.Scan && (
            <FlexItem>
              <Button variant="secondary" onClick={() => { previousStep(); }}>Back</Button>
            </FlexItem>
          )}

          <FlexItem grow={{ default: "grow" }}>
            <Flex style={{ height: "100%" }} direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
              <FlexItem>
                {stepTemplate()}
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>

      </PageSection>
    </PageContainer >
  );
}
