export function titleize(str: string) {
    let upper = true
    let newStr = ""
    for (let i = 0, l = str.length; i < l; i++) {
        // Note that you can also check for all kinds of spaces  with
        // str[i].match(/\s/)
        if (str[i] == " ") {
            upper = true
            newStr += str[i]
            continue
        }
        newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase()
        upper = false
    }
    return newStr
}


export const romanToInt = (str: string) => {
    const romanNumeralValues = new Map([
        ["I", 1],
        ["V", 5],
    ]);
    let result = 0;
    let current;
    let previous = 0;
    for (const char of str.split("").reverse()) {
        current = romanNumeralValues.get(char);
        if (current === undefined) continue;
        if (current >= previous) {
            result += current;
        } else {
            result -= current;
        }
        previous = current;
    }
    return result;
};