import { useQuery } from "@apollo/client";
import { Button, Flex, FlexItem, PageSection, Title, TitleSizes } from "@patternfly/react-core";
import html2canvas from "html2canvas";
import moment from "moment";
import { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { useTraveler, useUser } from "../contexts";
import { GET_FLIGHTS_QUERY, GET_FLIGHT_MANIFEST_QUERY } from "../graphql/queries";
import { GetFlightManifest, GetFlightManifest_flightManifest, GetFlights, GetFlights_flights } from "../__generated__/api";


interface Props {
  guid: string | undefined;
}

const RollCallManifest = (props: Props) => {
  const {
    guid,
  } = props;

  const [flight, setFlight] = useState<GetFlights_flights | undefined>(undefined);
  const [manifest, setManifest] = useState<GetFlightManifest_flightManifest | undefined>(undefined);

  const { me } = useUser();
  const { meTraveler } = useTraveler();

  useQuery<GetFlights>(
    GET_FLIGHTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      pollInterval: 1000,
      variables: {
        flightGuid: guid,
      },
      onCompleted: async (resp) => {
        setFlight(resp.flights[0]);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );
  useQuery<GetFlightManifest>(
    GET_FLIGHT_MANIFEST_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      pollInterval: 1000,
      variables: {
        flightGuid: guid,
      },
      onCompleted: async (resp) => {
        setManifest(resp.flightManifest ?? undefined);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const downloadCSV = () => {
    if (manifest === undefined) return;

    const csvHeaders = [
      "Name",
      "Rank",
      "DOD ID",
      "SVC",
      "Checked Bags Count",
      "Checked Bags Weight",
      "Carry-on Weight",
      "PAX Weight",
      "EPOC Name",
      "EPOC Phone",
    ].join("|");
    const csvRows = manifest.rows.map((r) => [
      r.lastName + ", " + r.firstName + ", " + r.middleName,
      r.rank,
      r.dodId,
      "TBD",
      "TBD",
      "TBD",
      r.service,
      r.checkedBagsCount,
      r.checkedBagsWeight,
      r.carryOnWeight,
      r.passengerWeight,
      r.epocName,
      r.epocPhone,
    ].join("|")).join("\n");

    const blob = new Blob([csvHeaders, "\n", csvRows], {
      type: 'text/plain'
    });
    var fileURL = window.URL.createObjectURL(blob);
    let tab = window.open();
    if (tab) {
      tab.location.href = fileURL;
    }
  };


  const checkedBagsCount = parseFloat((manifest?.rows ?? []).map((r) => r.checkedBagsCount).reduce((a, b) => a + b, 0).toFixed(2));
  const checkedBagsWeight = parseFloat((manifest?.rows ?? []).map((r) => r.checkedBagsWeight).reduce((a, b) => a + b, 0).toFixed(2));
  const carryOnsWeight = parseFloat((manifest?.rows ?? []).map((r) => r.carryOnWeight).reduce((a, b) => a + b, 0).toFixed(2));
  const passengersWeight = parseFloat((manifest?.rows ?? []).map((r) => r.passengerWeight).reduce((a, b) => a + b, 0).toFixed(2));
  const totalWeight = (checkedBagsWeight + carryOnsWeight + passengersWeight).toFixed(2);


  return (
    <PageContainer>
      <PageSection>


        <Flex direction={{ default: "row" }} style={{ marginBottom: 20, }}>
          {/* Header */}
          <FlexItem alignSelf={{ default: "alignSelfCenter" }}>
            <Title headingLevel="h1" size={TitleSizes["2xl"]}>
              Flight Manifest
            </Title>
          </FlexItem>
          <FlexItem>
            <Button
              variant="primary"
              onClick={() => {
                const ll = document.querySelector("#manifest");
                if (!ll)
                  return;

                const popup = window.open();
                if (!popup)
                  return;

                html2canvas(ll as HTMLElement, {}).then(canvas => {
                  popup.document.body.appendChild(canvas);
                  popup.print();
                });
              }}
            >
              Print
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant="primary"
              onClick={() => {
                downloadCSV();
              }}
            >
              Export Rows as CSV
            </Button>
          </FlexItem>
        </Flex>

        <table style={{ border: "2px solid black", width: "100%", marginBottom: 40, fontSize: 14, }} id="manifest">
          <thead>
            <tr>
              <th colSpan={13} style={{ textAlign: "center", textTransform: "uppercase", }}>
                Passenger Manifest
              </th>
            </tr>
            <tr style={{ borderTop: "2px solid black" }}>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>Mission #</p>
                <p style={{ fontWeight: "initial", }}>{flight?.mission.externalId}</p>
              </th>
              <th colSpan={6} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>Aircraft Type/Vehicle/Vessel</p>
                <p style={{ fontWeight: "initial", }}>{flight?.aircraft.manufacturer.name} {flight?.aircraft.designation} {flight?.aircraft.name}</p>
              </th>
              <th colSpan={2} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>POE</p>
                <p style={{ fontWeight: "initial", }}>{flight?.arrivalAirport.code}</p>
              </th>
              <th colSpan={2} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>POD</p>
                <p style={{ fontWeight: "initial", }}>{flight?.departureAirport.code}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Departure Date
                  {" "}
                  <span style={{ fontWeight: "initial", fontStyle: "italic", }}>(YYYMMDD)</span>
                </p>
                <p style={{ fontWeight: "initial", }}>{moment(flight?.departureSchedule).format("YYYYMMDD")}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Time
                  {" "}
                  <span style={{ fontWeight: "initial", fontStyle: "italic", }}>(ZULU)</span>
                </p>
                <p style={{ fontWeight: "initial", }}>{moment(flight?.departureSchedule).format("HH:mm:SS")}Z</p>
              </th>
            </tr>
            <tr style={{ borderTop: "2px solid black" }}>
              <th colSpan={7} style={{ textAlign: "center", borderBottom: "1px solid black", borderRight: "1px solid black", }}>
                <p style={{ textTransform: "uppercase", }}>
                  Passenger Information
                </p>
              </th>
              <th colSpan={3} style={{ textAlign: "center", borderBottom: "1px solid black", borderRight: "1px solid black", }}>
                <p style={{ textTransform: "uppercase", }}>
                  Baggage
                </p>
              </th>
              <th rowSpan={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black", padding: 10, }}>
                <p style={{ textTransform: "uppercase", }}>
                  PAX Weight
                </p>
              </th>
              <th colSpan={2} style={{ textAlign: "center", borderBottom: "1px solid black", borderRight: "1px solid black", }}>
                <p style={{ textTransform: "uppercase", }}>
                  Emergency Contact Information
                </p>
              </th>
            </tr>
            <tr>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Name
                </p>
                <p style={{ fontWeight: "initial", fontStyle: "italic", }}>
                  (Last, First, Middle)
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Rank
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  DoD ID
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Status
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  ULN
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Line No.
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  SVC
                </p>
              </th>
              <th colSpan={2} style={{ textAlign: "center", paddingLeft: 2, paddingRight: 2, borderRight: "1px solid black", }}>
                <p style={{ textTransform: "uppercase", }}>
                  Checked
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Carry-On Weight
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Name
                </p>
                <p style={{ fontWeight: "initial", fontStyle: "italic", }}>
                  (Last, First, Middle)
                </p>
              </th>
              <th rowSpan={2} style={{ borderRight: "1px solid black", textAlign: "center", paddingLeft: 2, paddingRight: 2, }}>
                <p style={{ textTransform: "uppercase", }}>
                  Telephone
                </p>
                <p style={{ fontWeight: "initial", fontStyle: "italic", }}>
                  (Include Area Code)
                </p>
              </th>
            </tr>
            <tr style={{ borderBottom: "2px solid black", }}>
              <th style={{ borderRight: "1px solid black", textAlign: "center", borderTop: "1px solid black" }}>
                Pieces
              </th>
              <th style={{ borderRight: "1px solid black", textAlign: "center", borderTop: "1px solid black" }}>
                Weight
              </th>
            </tr>
          </thead>
          <tbody>
            {manifest?.rows?.map((mr, idx) => (
              <tr key={mr.dodId} style={{ borderTop: idx === 0 ? "" : "1px solid black", borderBottom: idx === manifest.rows.length - 1 ? "1px solid black" : "", }}>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.lastName}, {mr.firstName}, {mr.middleName}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.rank}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.dodId}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.rank ? "Active Duty" : ""}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  TBD
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  TBD
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.service}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.checkedBagsCount}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.checkedBagsWeight.toFixed(2)}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.carryOnWeight.toFixed(2)}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.passengerWeight.toFixed(2)}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.epocName}
                </td>
                <td style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                  {mr.epocPhone}
                </td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black", }}>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }} colSpan={2}>
                <p style={{ textTransform: "uppercase", }}>Manifest Total</p>
                <p style={{ fontWeight: "initial", }}>{manifest?.rows.length}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Active</p>
                <p style={{ fontWeight: "initial", }}>{manifest?.rows.filter((r) => r.rank).length}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Dependent</p>
                <p style={{ fontWeight: "initial", }}>{manifest?.rows.filter((r) => !r.rank).length}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Retiree</p>
                <p style={{ fontWeight: "initial", }}>{manifest?.rows.filter((r) => r.retired).length}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Civilian</p>
                <p style={{ fontWeight: "initial", }}>{manifest?.rows.filter((r) => !r.rank).length}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Guard/Res</p>
                <p style={{ fontWeight: "initial", }}>{0}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Bag/Pcs</p>
                <p style={{ fontWeight: "initial", }}>{checkedBagsCount}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Checked Bag Wt</p>
                <p style={{ fontWeight: "initial", }}>{checkedBagsWeight}</p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Carry-On Bag Wt</p>
                <p style={{ fontWeight: "initial", }}>
                  {carryOnsWeight}
                </p>
              </th>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>PAX Wt</p>
                <p style={{ fontWeight: "initial", }}>
                  {passengersWeight}
                </p>
              </th>
              <th colSpan={2} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, verticalAlign: "baseline", }}>
                <p style={{ textTransform: "uppercase", }}>Total Weight PAX and all Baggage</p>
                <p style={{ fontWeight: "initial", }}>
                  {totalWeight}
                </p>
              </th>
            </tr>
            <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black", }}>
              <th colSpan={13} style={{ paddingLeft: 2, paddingRight: 2, }}>
                I certify that no unauthorized weapons/ammunition/explosive devices, or other prohibited items are in the possession of these personnel from whom I am the designated manifesting representative or troop commander, and that their authorized weaopns have been cleared.
              </th>
            </tr>
            <tr style={{ border: "0px solid black" }}>
              <th style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <span style={{ textTransform: "uppercase", }}>
                  Date
                </span>
                {" "}
                <span style={{ fontWeight: "initial", fontStyle: "italic", }}>
                  (YYYYMMDD)
                </span>
                <p style={{ fontWeight: "initial", }}>
                  {moment().format("YYYYMMDD")}
                </p>
              </th>
              <th colSpan={6} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <span style={{ textTransform: "uppercase", }}>
                  Printed Name
                </span>
                {" "}
                <span style={{ fontWeight: "initial", fontStyle: "italic", }}>
                  (Last, First, Middle)
                </span>
                <p style={{ fontWeight: "initial", }}>
                  {meTraveler?.lastName}, {meTraveler?.firstName}, {meTraveler?.middleName}
                </p>
              </th>
              <th colSpan={3} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <span style={{ textTransform: "uppercase", }}>
                  Grade
                </span>
                <p style={{ fontWeight: "initial", }}>
                  {me?.rank?.grade}
                </p>
              </th>
              <th colSpan={3} style={{ borderRight: "1px solid black", paddingLeft: 2, paddingRight: 2, }}>
                <span style={{ textTransform: "uppercase", }}>
                  Signature
                </span>
                <p style={{ fontWeight: "initial", fontStyle: "italic", fontFamily: "cursive", }}>
                  {meTraveler?.firstName} {meTraveler?.middleName?.substring(0, 1)} {meTraveler?.lastName}
                </p>
              </th>
            </tr>
          </thead>
        </table>


      </PageSection>
    </PageContainer>
  );
};

export default RollCallManifest;
