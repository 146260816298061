import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem, PageSection
} from "@patternfly/react-core";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { CREATE_AIRPORT_QUERY, GET_AIRPORTS_QUERY } from "../graphql/queries";
import { CreateAirport, GetAirports, GetAirports_airports } from "../__generated__/api";

export default function Airports() {
  const [airports, setAirports] = useState<GetAirports_airports[]>([]);

  const { loading: fetchingAirports, refetch: refetchAirports, } = useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAirports(resp.airports);
      },
    }
  );
  const [createAirport, { loading: creatingAirport }] = useMutation<CreateAirport>(CREATE_AIRPORT_QUERY, { notifyOnNetworkStatusChange: true, });

  const doCreateAirport = async () => {
    try {
      const createResp = await createAirport({
        variables: {
          // airport info
          name: `New Airport ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          code: "ABC",

          // location info
          lat: 0.00,
          lon: 0.00,

          // address info
          addressLine1: "123 Imaginary Lane",
          city: "Somewhere",
          state: "AB",
          postCode: "12345",
        }
      });
      navigate(`/airports/${createResp.data?.createAirport}`);
    } catch (err) {
      console.log("Failed to get create Airport");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <Flex style={{ marginBottom: 20, }}>
          <FlexItem>
            <Button
              disabled={creatingAirport}
              variant="primary"
              onClick={() => { doCreateAirport(); }}
            >
              Create Airport
            </Button>
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Missions Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>Name</Th>
              <Th>Code</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>

              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {airports.map((airport) => (
              <Tr key={airport.guid}>
                <Td>{airport.name}</Td>
                <Td>{airport.code}</Td>
                <Td>{moment(airport.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(airport.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>

                <Td>
                  <Flex>
                    <FlexItem>
                      <Link to={`/airports/${airport.guid}`}>
                        <Button isSmall>Edit</Button>
                      </Link>
                    </FlexItem>
                    <FlexItem>
                      <Link to={`/airports/${airport.guid}/amc-gram`}>
                        <Button isSmall>AMC Gram</Button>
                      </Link>
                    </FlexItem>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>
      </PageSection>
    </PageContainer>
  );
}
