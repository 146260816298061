import { Flex, FlexItem, List, ListItem, MultipleFileUpload, MultipleFileUploadMain } from "@patternfly/react-core";
import { TimesCircleIcon, UploadIcon } from "@patternfly/react-icons";
import { v4 as uuidv4 } from 'uuid';
import Empty from "../Empty";


export interface PendingFile {
  guid: string;
  file: File;
}

interface Props {
  files: PendingFile[];
  onAdd?: (files: PendingFile[]) => void;
  onRemove?: (file: PendingFile) => void;

  acceptedMimeTypes?: string[];
  acceptedMimeTypesForHint?: string[];
}

export default (props: Props) => {
  const {
    files,
    onAdd,
    onRemove,

    acceptedMimeTypes,
    acceptedMimeTypesForHint,
  } = props;
  return (
    <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
      <Flex direction={{ default: "row" }} style={{ gap: 0, overflowY: "scroll", height: "100%", }}>
        <FlexItem style={{ margin: 0, height: "100%", }}>
          <MultipleFileUpload
            onFileDrop={(newFiles) => {
              if (onAdd === undefined) return;
              onAdd(newFiles.map((nf) => ({
                file: nf,
                guid: uuidv4(),
              })));
            }}
            dropzoneProps={{
              accept: 'application/pdf',
            }}
            style={{ height: "100%", }}
          >
            <MultipleFileUploadMain
              titleIcon={<UploadIcon />}
              titleText="Drag and drop files here"
              titleTextSeparator="or"
              infoText={acceptedMimeTypesForHint === undefined ? undefined : `Accepted file types: ${acceptedMimeTypesForHint.join(", ")}`}
            />
          </MultipleFileUpload>
        </FlexItem>
        <FlexItem grow={{ default: "grow" }} alignSelf={{ default: "alignSelfStretch" }} style={{ margin: 0, overflow: "hidden", }}>
          {files.length === 0 ? (
            <Empty
              title={"No Files Selecteed"}
              description={"You have not selected any files for upload."}
            />
          ) : (
            <>
              <List isPlain style={{ padding: 10, overflow: "auto", height: "100%", }}>
                {files.map((f) => (
                  <ListItem
                    key={f.guid}
                    style={{}}
                    icon={
                      <TimesCircleIcon
                        size={"md"}
                        color={"#FF0000"}
                        style={{ cursor: "pointer", }}
                        onClick={() => {
                          if (onRemove === undefined) return;
                          onRemove(f);
                        }}
                      />}>
                    {f.file.name}
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </FlexItem>
      </Flex>
    </div>
  );


}