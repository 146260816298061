import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Flex, FlexItem, KEY_CODES, PageSection, Text, TextContent, TextInput, TextVariants, Tooltip } from "@patternfly/react-core";
import { ImageIcon, MinusCircleIcon, TrashIcon } from "@patternfly/react-icons";
import { navigate } from "@reach/router";
import React, { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { useUser } from "../contexts";
import { ADD_LUGGAGE_TO_PALLET_QUERY, DELETE_LUGGAGE_PALLET_QUERY, GET_LUGGAGE_PALLET_QUERY, GET_LUGGAGE_TAGS_QUERY, REMOVE_LUGGAGE_FROM_PALLET_QUERY } from "../graphql/queries";
import { AddLuggageToPallet, DeleteLuggagePallet, GetLuggagePallets, GetLuggagePallets_luggagePallets, GetLuggagePallets_luggagePallets_luggageTags, GetLuggageTags, GetLuggageTags_luggageTags, RemoveLuggageFromPallet } from "../__generated__/api";


interface Props {
  guid: string | undefined;
}

export default function Pallet(props: Props) {
  const {
    guid,
  } = props;

  const { me } = useUser();

  const [pallet, setPallet] = useState<GetLuggagePallets_luggagePallets | undefined>(undefined);
  const [luggageTags, setLuggageTags] = useState<GetLuggageTags_luggageTags[]>([]);

  const [targetLuggageTagGuid, setTargetLuggageTagGuid] = useState<string>("");
  const [targetLuggageTag, setTargetLuggageTag] = useState<GetLuggageTags_luggageTags | undefined>(undefined);

  const { refetch: refetchLuggagePallets, } = useQuery<GetLuggagePallets>(
    GET_LUGGAGE_PALLET_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      variables: {
        guids: [guid],
      },
      onCompleted: (resp) => {
        setPallet(resp.luggagePallets[0]);
      },
    }
  );
  const { refetch: refetchLuggageTags, } = useQuery<GetLuggageTags>(
    GET_LUGGAGE_TAGS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setLuggageTags(resp.luggageTags);
      },
    }
  );
  const [addLuggageToPallet] = useMutation<AddLuggageToPallet>(ADD_LUGGAGE_TO_PALLET_QUERY, { notifyOnNetworkStatusChange: true, });
  const [removeLuggageFromPallet] = useMutation<RemoveLuggageFromPallet>(REMOVE_LUGGAGE_FROM_PALLET_QUERY, { notifyOnNetworkStatusChange: true, });
  const [deletePallet] = useMutation<DeleteLuggagePallet>(DELETE_LUGGAGE_PALLET_QUERY, { notifyOnNetworkStatusChange: true, });

  const doAddLuggage = async () => {
    try {
      await addLuggageToPallet({
        variables: {
          palletGuid: guid,
          luggageTagGuid: targetLuggageTagGuid,
        }
      });

      await refetchLuggagePallets();

      setTargetLuggageTagGuid("");

      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to add luggage to pallet");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doRemoveLuggage = async (tagGuid: string) => {
    try {
      await removeLuggageFromPallet({
        variables: {
          palletGuid: guid,
          luggageTagGuid: tagGuid,
        }
      });

      await refetchLuggagePallets();

      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to remove luggage from pallet");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doDeletePallet = async () => {
    try {
      await deletePallet({
        variables: {
          guid,
        }
      });

      navigate("/pallets");

      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to delete Luggage Pallet");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const getLuggageTagByGuid = (tagGuid: string) => luggageTags.find((lt) => lt.guid === tagGuid);

  return (
    <PageContainer>
      <PageSection>

        <Flex>
          <FlexItem>
            <Button variant="secondary" onClick={() => { navigate("/pallets"); }}>Back</Button>
          </FlexItem>
        </Flex>

        <Flex direction={{ default: "row" }} justifyContent={{ default: "justifyContentCenter" }} style={{ padding: 40, }}>
          {/* View All */}
          <FlexItem grow={{ default: "grow" }}>
            <TextContent style={{ marginBottom: 20, }}>
              <Text component={TextVariants.h1} style={{ textAlign: "center" }}>All Luggage on Pallet</Text>
            </TextContent>
            <Flex justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
              {pallet?.luggageTags.length === 0 ? (
                <TextContent style={{ marginBottom: 10, }}>
                  <Text component={TextVariants.p} style={{}}>Pallet has no luggage assigned to it.</Text>
                </TextContent>
              ) : (
                <>
                  {pallet?.luggageTags.map((lt) => (
                    <FlexItem key={lt.guid}>
                      <Card style={{ width: 128, height: 128, cursor: "pointer", position: "relative" }}>
                        <Tooltip content={"Remove Luggage"}>
                          <MinusCircleIcon
                            style={{ position: "absolute", top: -8, right: -8, }}
                            size="md"
                            color="#FA0000"
                            onClick={() => {
                              if (confirm("Are you sure you want to remove this luggage?")) {
                                doRemoveLuggage(lt.guid);
                              }
                            }}
                          />
                        </Tooltip>
                        {(lt.checkedLuggage.photoFilePathS3 && lt.checkedLuggage.photoFilePathS3 !== "") ? (
                          <img src={`${process.env.REACT_APP_S3_URL}${lt.checkedLuggage.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", }} />
                        ) : (
                          <Flex style={{ backgroundColor: "#a2a2a2", width: "100%", height: "100%", }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
                            <ImageIcon size={"xl"} color={"#FFFFFF"} />
                          </Flex>
                        )}
                      </Card>
                    </FlexItem>
                  ))}
                </>
              )}
            </Flex>
          </FlexItem>
          {/* Add New */}
          <FlexItem grow={{ default: "grow" }}>
            <Flex direction={{ default: "column" }} style={{ marginBottom: 40, }}>
              <FlexItem>
                <TextContent style={{ marginBottom: 10, }}>
                  <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Add Luggage</Text>
                </TextContent>
              </FlexItem>
              <FlexItem>
                <TextInput
                  aria-label="Luggage Tag ID"
                  autoFocus
                  onKeyDown={(e) => { if (e.key !== "Enter") return; doAddLuggage(); }}
                  validated={getLuggageTagByGuid(targetLuggageTagGuid ?? "") !== undefined ? "success" : "error"}
                  title={getLuggageTagByGuid(targetLuggageTagGuid ?? "") === undefined ? "Invalid ID" : "Valid ID"}
                  placeholder="e.g. c636e49d-2216-4012-92ba-49e7010ffb34"
                  type="text"
                  value={targetLuggageTagGuid}
                  onChange={(val) => {
                    if (val == "") {
                      setTargetLuggageTagGuid("");
                      setTargetLuggageTag(undefined);
                      return;
                    }
                    setTargetLuggageTagGuid(val);
                    setTargetLuggageTag(getLuggageTagByGuid(val));
                  }}
                />
              </FlexItem>
              <FlexItem>
                <Button
                  style={{ width: "100%" }}
                  variant="primary"
                  isDisabled={targetLuggageTag === undefined}
                  onClick={() => {
                    doAddLuggage();
                  }}
                >
                  Register
                </Button>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>


        <Button
          style={{ width: "100%" }}
          variant="danger"
          onClick={() => {
            if (confirm("Are you sure you want to delete this pallet?")) {
              doDeletePallet();
            }
          }}
        >
          Delete Pallet
        </Button>


      </PageSection>
    </PageContainer >
  );
}
