import {
  PageSection
} from "@patternfly/react-core";
import React from "react";
import PageContainer from "../Components/PageContainer";

const Dashboard = () => {


  return (
    <PageContainer>
      <PageSection>
        Dashboard

      </PageSection>
    </PageContainer>
  );
};

export default Dashboard;
