import { gql } from "@apollo/client";

export const GET_TRAVELERS_QUERY = gql`
query GetTravelers($guids: [String], $sponsorGuid: String, $pageNumber: Int, $resultsPerPage: Int) {
  travelers(guids: $guids, sponsorGuid: $sponsorGuid, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid
    createdAt
    lastUpdatedAt

    email
    firstName
    middleName
    lastName
    dodId

    sponsor {
      guid
    }
    sponsorRelationship

    travelerDocuments {
      guid
      createdAt
      lastUpdatedAt

      fileName
      fileMimeType
      filePathS3
    }

    accommodations {
      guid
      createdAt
      lastUpdatedAt

      requiresLargerSeat
      requiresSeatBeltExtension
      requiresWheelchairAccessibleSpace
      notes
    }
  }
}
`;

export const CREATE_TRAVELER_QUERY = gql`
mutation CreateTraveler(
  $sponsorGuid: String!,
  $firstName: String!,
  $middleName: String,
  $lastName: String!,
  $email: String!,
  $dodId: String!,
  $sponsorRelationship: SponsorRelationship!
) {
  createTraveler(
    sponsorGuid: $sponsorGuid,
    firstName: $firstName,
    middleName: $middleName,
    lastName: $lastName,
    email: $email,
    dodId: $dodId,
    sponsorRelationship: $sponsorRelationship
  )
}
`;


export const UPDATE_TRAVELER_QUERY = gql`
mutation UpdateTraveler(
  $guid: String!,
  $firstName: String,
  $middleName: String,
  $lastName: String,
  $email: String,
  $dodId: String,
  $sponsorRelationship: SponsorRelationship
) {
  updateTraveler(
    guid: $guid,
    firstName: $firstName,
    middleName: $middleName,
    lastName: $lastName,
    email: $email,
    dodId: $dodId,
    sponsorRelationship: $sponsorRelationship
  )
}
`;


export const DELETE_TRAVELER_QUERY = gql`
mutation DeleteTraveler($guid: String!) {
  deleteTraveler(guid: $guid)
}
`;


export const UPDATE_TRAVELER_ACCOMMODATIONS = gql`
mutation UpdateTravelerAccommodations(
  $travelerGuid: String!,
  $requiresLargerSeat: Boolean,
  $requiresSeatBeltExtension: Boolean,
  $requiresWheelchairAccessibleSpace: Boolean,
  $notes: String
) {
  updateTravelerAccommodations(
    travelerGuid: $travelerGuid,
    requiresLargerSeat: $requiresLargerSeat,
    requiresSeatBeltExtension: $requiresSeatBeltExtension,
    requiresWheelchairAccessibleSpace: $requiresWheelchairAccessibleSpace,
    notes: $notes
  )
}
`;

export const CREATE_TRAVELER_DOCUMENT_QUERY = gql`
mutation CreateTravelerDocument($travelerGuid: String!, $file: Upload!) {
  createTravelerDocument(travelerGuid: $travelerGuid, file: $file)
}
`;

export const DELETE_TRAVELER_DOCUMENT_QUERY = gql`
mutation DeleteTravelerDocument($guid: String!) {
  deleteTravelerDocument(guid: $guid)
}
`;
