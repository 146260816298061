import {
  BackgroundImage, Bullseye,
  Button, Card,
  CardBody,
  CardHeader, Masthead, MastheadMain
} from "@patternfly/react-core";
import { useKeycloak } from "@react-keycloak/web";
import afLogo from "../assets/af-logo.png";
import c17Background from "../assets/c17.png";
import loginDotGov from "../assets/login.gov.svg";
import React from "react";
import keycloakConfig from "../Auth/keycloak";

const Login: React.FC = (props) => {
  const { keycloak } = useKeycloak();

  return (
    <>
      <BackgroundImage src={c17Background} />
      <Masthead style={styles.masthead}>
        <MastheadMain>
          <img style={styles.afLogo} src={afLogo} />
          <h1>Air Mobility Command</h1>
        </MastheadMain>
      </Masthead>
      <Bullseye>
        <Card style={styles.loginCard}>
          <CardHeader style={styles.loginHeaderContainer}>
            <h1 style={styles.loginHeader}>AMC Gateways</h1>

          </CardHeader>
          <CardBody >
            <h2>FOR OFFICIAL USE ONLY</h2>
            <br />
            <p style={styles.fouoNotice}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean congue mi pellentesque metus pulvinar pretium. Proin ligula augue, interdum a pellentesque a, dapibus ut lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam id est eleifend enim varius malesuada. Maecenas blandit maximus dolor, at interdum metus vestibulum sed. Cras tempor orci nisi, vitae maximus quam mollis vitae. Pellentesque sodales suscipit ligula vitae semper. Nam feugiat arcu id magna maximus, eu cursus nulla venenatis. In eu ullamcorper justo, sed fringilla ipsum. Sed lectus dui, fermentum in dui ac, malesuada suscipit ex. Curabitur ornare dolor nisl, nec dignissim lectus porttitor ac. Sed tempus eleifend urna non elementum. Nulla auctor felis sed volutpat ultricies. Suspendisse vel augue vel sapien blandit placerat eu eu tellus. Morbi maximus vitae odio quis scelerisque. In in mi in erat malesuada tristique.
            </p>
            <br />
            <Button
              variant='danger'
              style={styles.cacLoginbutton}
            >
              CAC Login
            </Button>
            <Button
              variant='tertiary'
              style={styles.loginDotGovButton}
            >
              <img style={styles.loginDotGovButtonImg} src={loginDotGov} />
            </Button>
            <Button
              variant='primary'
              style={styles.dsLogonButton}
              onClick={() => keycloak.login(keycloakConfig)}
            >
              DS LOGON
            </Button>
          </CardBody>
        </Card>
      </Bullseye>
    </>
  );
};

export default Login;

const styles: {
  [key: string]: React.CSSProperties
} = {
  loginCard: {
    minWidth: 40,
    maxWidth: "33%",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  masthead: {
    position: "absolute",
    width: "100%",
    fontFamily: "ethnocentric",
    //color: '#1c2449'
    color: "white",
  },
  loginHeaderContainer: {
    textAlign: "center",
  },
  loginHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
  afLogo: {
    maxHeight: 40,
    marginRight: 20,
    marginLeft: 20,
  },
  fouoNotice: {
    textAlign: "justify",
  },
  dsLogonButton: {
    margin: 10,
    fontWeight: "bold"
  },
  loginDotGovButton: {
    margin: 10,
  },
  loginDotGovButtonImg: {
    maxHeight: 10,
  },
  cacLoginbutton: {
    margin: 10,
    fontWeight: "bold"

  },
};