import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem, Modal, PageSection, Text, TextContent, TextInput, TextVariants, Title, TitleSizes, Tooltip
} from "@patternfly/react-core";
import { PlusCircleIcon } from "@patternfly/react-icons";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import { useUser } from "../contexts";
import PageContainer from "../Components/PageContainer";
import PassengerSelector from "../Components/PassengerSelector";
import { CREATE_FOUND_LUGGAGE_INQUIRY_QUERY, GET_FOUND_LUGGAGE_INQUERIES_QUERY, GET_LOST_LUGGAGE_INQUERIES_QUERY, GET_LUGGAGE_TAGS_QUERY } from "../graphql/queries";
import { CreateFoundLuggageInquiry, FoundLuggageInquiries, FoundLuggageInquiries_foundLuggageInquiries, GetLuggageTags, GetLuggageTags_luggageTags, LostLuggageInquiries, LostLuggageInquiries_lostLuggageInquiries } from "../__generated__/api";

export default function LostAndFound() {

  const { me } = useUser();

  const [lostInquiries, setLostInquiries] = useState<LostLuggageInquiries_lostLuggageInquiries[]>([]);
  const [foundInquiries, setFoundInquiries] = useState<FoundLuggageInquiries_foundLuggageInquiries[]>([]);
  const [luggageTags, setLuggageTags] = useState<GetLuggageTags_luggageTags[]>([]);

  const [createFoundModalVisible, setCreateFoundModalVisible] = useState<boolean>(false);
  const [luggageTagGuid, setLuggageTagGuid] = useState<string>("");
  const [travelerGuid, setTravelerGuid] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");

  const { refetch: refetchLost } = useQuery<LostLuggageInquiries>(
    GET_LOST_LUGGAGE_INQUERIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setLostInquiries(resp.lostLuggageInquiries);
      },
    }
  );
  const { refetch: refetchFound } = useQuery<FoundLuggageInquiries>(
    GET_FOUND_LUGGAGE_INQUERIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setFoundInquiries(resp.foundLuggageInquiries);
      },
    }
  );
  useQuery<GetLuggageTags>(
    GET_LUGGAGE_TAGS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setLuggageTags(resp.luggageTags);
      },
    }
  );
  const [createFoundInquiry, { loading: creatingFoundInquiry, }] = useMutation<CreateFoundLuggageInquiry>(CREATE_FOUND_LUGGAGE_INQUIRY_QUERY, { notifyOnNetworkStatusChange: true, });


  const getLastLostStatus = (ticket: LostLuggageInquiries_lostLuggageInquiries) => ticket.reviews[ticket.reviews.length > 1 ? ticket.reviews.length - 1 : 0];

  const getLuggageTagByGuid = (guid: string) => luggageTags.find((lt) => lt.guid === guid);

  const doCreateFoundInquiry = async () => {
    await createFoundInquiry({
      variables: {
        submittingUserguid: me?.guid,
        remarks,

        ...(luggageTagGuid !== "" && ({
          luggageTagGuid,
        })),
        ...(travelerGuid !== "" && ({
          suspectedOwnerGuid: travelerGuid,
        })),
      },
    });
    await refetchLost();
    await refetchFound();
  };

  return (
    <PageContainer>
      <PageSection>

        <Modal
          width="50%"
          aria-label={"Create Found Luggage Inquiry"}
          title={"Create Found Luggage Inquiry"}
          isOpen={createFoundModalVisible}
          onClose={() => {
            setCreateFoundModalVisible(false);
            setLuggageTagGuid("");
            setTravelerGuid("");
            setRemarks("");
          }}
          header={(
            <>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 20, }}>
                Create Found Luggage Inquiry
              </Title>
            </>
          )}
        >

          <Flex style={{ marginBottom: 20, }} direction={{ default: "row", }}>
            <FlexItem grow={{ default: "grow" }} style={{ margin: 0, }}>
              <TextContent style={{ marginBottom: 10, }}>
                <Text component={TextVariants.h3}>Luggage Tag ID</Text>
              </TextContent>
              <TextInput
                aria-label="Luggage Tag ID"
                validated={luggageTagGuid === "" ? undefined : getLuggageTagByGuid(luggageTagGuid ?? "") !== undefined ? "success" : "error"}
                title={getLuggageTagByGuid(luggageTagGuid ?? "") === undefined ? "Invalid ID" : "Valid ID"}
                placeholder="e.g. c636e49d-2216-4012-92ba-49e7010ffb34"
                type="text"
                value={luggageTagGuid}
                onChange={(val) => {
                  setLuggageTagGuid(val);
                }}
              />
            </FlexItem>
            <FlexItem alignSelf={{ default: "alignSelfCenter" }} style={{ marginLeft: 60, marginRight: 60, }}>
              <TextContent style={{ textAlign: "center" }}>
                <Text component={TextVariants.h3}>OR</Text>
              </TextContent>
            </FlexItem>
            <FlexItem grow={{ default: "grow" }} style={{ margin: 0, }}>
              <TextContent style={{ marginBottom: 10, }}>
                <Text component={TextVariants.h3}>Suspected Owner</Text>
              </TextContent>
              <PassengerSelector
                selectedGuid={travelerGuid}
                onSelect={(target): void => {
                  setTravelerGuid(target?.guid ?? "");
                }}
              />
            </FlexItem>
          </Flex>


          <TextContent style={{ marginBottom: 10, }}>
            <Text component={TextVariants.h3}>Comments</Text>
          </TextContent>
          <TextInput
            style={{ marginBottom: 20, }}
            aria-label="Comments"
            placeholder="e.g. 'found in the 2nd floor unisex bathroom'"
            type="text"
            value={remarks}
            onChange={(val) => {
              setRemarks(val);
            }}
          />

          <Button
            variant="primary"
            style={{ width: "100%" }}
            isDisabled={getLuggageTagByGuid(luggageTagGuid) === undefined && travelerGuid === ""}
            onClick={() => {
              doCreateFoundInquiry();
              setCreateFoundModalVisible(false);
            }}
          >
            Submit
          </Button>
        </Modal>

        <TextContent style={{ marginBottom: 20, }}>
          <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Lost Luggage Inquiries</Text>
        </TextContent>
        <TableComposable
          aria-label="Lost Luggage Table"
          variant={"compact"}
          borders
          style={{ marginBottom: 20, }}
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>Ticket #</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {lostInquiries.map((inq) => (
              <Tr key={inq.guid}>
                <Td>{inq.id}</Td>
                <Td>{inq.isClosed ? "Closed" : getLastLostStatus(inq).status.replace(/_/g, " ")}</Td>
                <Td>{moment(inq.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(inq.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>

                <Td>
                  <Link to={`/lost-and-found/lost/${inq.guid}`}>
                    <Button isSmall>View</Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>


        <Flex style={{ marginBottom: 20, }} alignItems={{ default: "alignItemsCenter" }} justifyContent={{ default: "justifyContentCenter" }}>
          <FlexItem>
            <TextContent>
              <Text component={TextVariants.h1} style={{ textAlign: "center" }}>
                Found Luggage Inquiries
              </Text>
            </TextContent>
          </FlexItem>
          <FlexItem>
            <Tooltip content="Create New Found Inquiry">
              <Button
                variant="plain"
                style={{ padding: 0 }}
                onClick={() => {
                  setCreateFoundModalVisible(true);
                }}
              >
                <PlusCircleIcon size="md" />
              </Button>
            </Tooltip>
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Found Luggage Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>Ticket #</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {foundInquiries.map((inq) => (
              <Tr key={inq.guid}>
                <Td>{inq.id}</Td>
                <Td>{inq.isClosed ? "Closed" : "Open"}</Td>
                <Td>{moment(inq.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(inq.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>

                <Td>
                  <Link to={`/lost-and-found/found/${inq.guid}`}>
                    <Button isSmall>View</Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>




      </PageSection>
    </PageContainer>
  );
}
