import { useMutation, useQuery } from "@apollo/client";
import { DatePicker, Flex, FlexItem, Modal, ModalVariant, Select, SelectOption, SelectVariant, Text, Title, TitleSizes, Wizard } from "@patternfly/react-core";
import moment from "moment";
import { useState } from "react";
import { CREATE_TRAVEL_AUTHORIZATION_DOCUMENT_MUTATION, CREATE_TRAVEL_AUTHORIZATION_MUTATION, GET_TRAVELERS_QUERY } from "../../graphql/queries";
import { titleize } from "../../utils/string-utils";
import { CreateTravelAuthorization, CreateTravelAuthorizationDocument, GetTravelers, GetTravelers_travelers, ReviewStatus, TravelAuthorizationCategory } from "../../__generated__/api";
import FileUploader, { PendingFile } from "../FileUploader";
import TravelerCard from "../TravelerCard";


interface Props {
  sponsorGuid: string | undefined;

  onClose: () => void;
  onCreated: () => void;
}

export default (props: Props) => {

  const {
    sponsorGuid,

    onClose,
    onCreated,
  } = props;


  const [travelers, setTravelers] = useState<GetTravelers_travelers[]>([]);

  const [selectedTravelerGuids, setSelectedTravelerGuids] = useState<string[]>([]);
  const [pendingUploads, setPendingUploads] = useState<PendingFile[]>([]);

  const [categoryDropdownExpanded, setCategoryDropdownExpanded] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<TravelAuthorizationCategory>(TravelAuthorizationCategory.CATEGORY_III);
  const [leaveStatusDate, setLeaveStatusDate] = useState<Date>(moment().toDate());
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(2, "weeks").toDate());


  const { loading: fetchingTraveler, refetch: refetchTraveler, } = useQuery<GetTravelers>(
    GET_TRAVELERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        sponsorGuid,
      },
      onCompleted: async (resp) => {
        setTravelers(resp.travelers);

        const sponsorTraveler = resp.travelers.find((t) => t.sponsorRelationship === "SELF");
        if (sponsorTraveler !== undefined) {
          setSelectedTravelerGuids([sponsorTraveler.guid]);
        }
      },
    }
  );
  const [createTravelAuthorization, { loading: creatingTravelAuthorization }] = useMutation<CreateTravelAuthorization>(CREATE_TRAVEL_AUTHORIZATION_MUTATION, { notifyOnNetworkStatusChange: true, });
  const [createTravelAuthorizationDocument, { loading: creatingTravelAuthorizationDocument }] = useMutation<CreateTravelAuthorizationDocument>(CREATE_TRAVEL_AUTHORIZATION_DOCUMENT_MUTATION);

  const doCreateTravelAuthorization = async () => {
    try {
      console.log("Create travel auth");
      const createdResp = await createTravelAuthorization({
        variables: {
          sponsorGuid,
          travelerGuids: selectedTravelerGuids,
          leaveStatusDate: leaveStatusDate.toISOString(),
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          category: selectedCategory,
          forceStatus: ReviewStatus.SUBMITTED,
        }
      });
      const createdGuid = createdResp.data?.createTravelAuthorization;

      for (const upload of pendingUploads) {
        await createTravelAuthorizationDocument({
          variables: {
            travelAuthorizationGuid: createdGuid,
            file: upload.file,
          },
        });
      }

      onClose();
      onCreated();
    } catch (err) {
      console.error("Failed to get create Travel Authorization");
      console.error(JSON.stringify(err, null, 2));
    }
  };


  const travelersTemplate = () => (
    <Flex>
      <FlexItem>
        <Flex style={{ rowGap: 20, }}>
          {travelers.map((t) => (
            <FlexItem key={t.guid}>
              <TravelerCard
                traveler={t}
                selected={selectedTravelerGuids.includes(t.guid)}
                onClick={() => {
                  if (selectedTravelerGuids.includes(t.guid)) {
                    setSelectedTravelerGuids(selectedTravelerGuids.filter((g) => g !== t.guid));
                  } else {
                    setSelectedTravelerGuids([...selectedTravelerGuids, t.guid]);
                  }
                }}
              />
            </FlexItem>
          ))}
        </Flex>
      </FlexItem>
    </Flex>
  );

  const documentsTemplate = () => (
    <Flex direction={{ default: "column" }}>
      <FlexItem>
        <FileUploader
          files={pendingUploads}
          onAdd={(files) => {
            setPendingUploads([...pendingUploads, ...files]);
          }}
          onRemove={(f) => {
            setPendingUploads(pendingUploads.filter((pu) => pu !== f));
          }}
        />
      </FlexItem>
    </Flex>
  );

  const categories = [
    TravelAuthorizationCategory.CATEGORY_I,
    TravelAuthorizationCategory.CATEGORY_II,
    TravelAuthorizationCategory.CATEGORY_III,
    TravelAuthorizationCategory.CATEGORY_IV,
    TravelAuthorizationCategory.CATEGORY_V,
    TravelAuthorizationCategory.CATEGORY_VI,
  ];
  const categoryTemplate = () => (
    <Flex direction={{ default: "column" }}>
      <FlexItem>
        <Select
          placeholderText="Select Category"
          onToggle={(expanded) => { setCategoryDropdownExpanded(expanded); }}
          onSelect={(event, selection, isPlaceholder) => {
            setSelectedCategory(selection as TravelAuthorizationCategory);
            setCategoryDropdownExpanded(false);
          }}
          isOpen={categoryDropdownExpanded}
          selections={selectedCategory}
        >
          {categories.map((e) => (
            <SelectOption
              key={e.toString()}
              value={e.toString()}
              selected={selectedCategory === e}
            >
              {e.toString().replace("_", " ")}
            </SelectOption>
          ))}
        </Select>
      </FlexItem>
    </Flex>
  );

  const datesTemplate = () => (
    <Flex direction={{ default: "column" }}>
      <FlexItem>
        <Title headingLevel="h6" size={TitleSizes.md}>
          Leave Status Date
        </Title>
        <DatePicker
          value={moment(leaveStatusDate).format("YYYY-MM-DD")}
          onBlur={(str, date) => {
            console.log('onBlur', str, date);
          }}
          onChange={(str, date) => {
            if (date === undefined) return;
            setLeaveStatusDate(date);
          }}
        />
      </FlexItem>
      <FlexItem>
        <Title headingLevel="h6" size={TitleSizes.md}>
          Start Date
        </Title>
        <DatePicker
          value={moment(startDate).format("YYYY-MM-DD")}
          onBlur={(str, date) => {
            console.log('onBlur', str, date);
          }}
          onChange={(str, date) => {
            if (date === undefined) return;
            setStartDate(date);
          }}
        />
      </FlexItem>
      <FlexItem>
        <Title headingLevel="h6" size={TitleSizes.md}>
          End Date
        </Title>
        <DatePicker
          value={moment(endDate).format("YYYY-MM-DD")}
          onBlur={(str, date) => {
            console.log('onBlur', str, date);
          }}
          onChange={(str, date) => {
            if (date === undefined) return;
            setEndDate(date);
          }}
        />
      </FlexItem>
    </Flex>
  );

  const reviewTemplate = () => (
    <Flex direction={{ default: "column" }}>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Travelers:
            </Title>
          </FlexItem>
          <FlexItem>
            <Text>
              {selectedTravelerGuids.map((g) => {
                const traveler = travelers.find((t) => t.guid === g);
                if (traveler === undefined) return undefined;
                return `${traveler.firstName} ${traveler.lastName}`;
              }).join(", ")}
            </Text>
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Documents:
            </Title>
          </FlexItem>
          <FlexItem>
            <Text>
              {pendingUploads.length === 0 ? "None" : pendingUploads.map((pu) => pu.file.name).join(", ")}
            </Text>
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Category:
            </Title>
          </FlexItem>
          <FlexItem>
            {selectedCategory.toString().replace("_", " ")}
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Leave Status Date:
            </Title>
          </FlexItem>
          <FlexItem>
            {moment(leaveStatusDate).format("MM/DD/YYYY")}
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Leave Start Date:
            </Title>
          </FlexItem>
          <FlexItem>
            {moment(startDate).format("MM/DD/YYYY")}
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex direction={{ default: "row" }}>
          <FlexItem>
            <Title headingLevel="h6" size={TitleSizes.lg}>
              Leave End Date:
            </Title>
          </FlexItem>
          <FlexItem>
            {moment(endDate).format("MM/DD/YYYY")}
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );

  const steps = [
    { name: 'Select Travelers', component: travelersTemplate(), },
    { name: 'Select Documents', component: documentsTemplate(), },
    { name: 'Select Category', component: categoryTemplate(), },
    { name: 'Select Dates', component: datesTemplate(), },
    { name: 'Review', component: reviewTemplate(), nextButtonText: 'Create' }
  ];

  return (
    <Modal
      aria-label="Create Travel Authorization Modal"
      isOpen={true}
      variant={ModalVariant.large}
      showClose={false}
      onClose={() => { onClose(); }}
      hasNoBodyWrapper
    >
      <Wizard
        title="Create Travel Authorization"
        description="Create a Travel Authorization for a Sponsoring Traveler."
        steps={steps}
        onClose={onClose}
        onSave={() => { doCreateTravelAuthorization(); }}
      />
    </Modal>
  );
};