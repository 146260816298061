import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Card, CardBody, CardTitle, Flex,
  FlexItem, PageSection, Spinner
} from "@patternfly/react-core";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { DELETE_FOUND_LUGGAGE_INQUIRY_QUERY, GET_FOUND_LUGGAGE_INQUERIES_QUERY, TOGGLE_FOUND_LUGGAGE_INQUIRY_QUERY } from "../graphql/queries";
import { DeleteFoundLuggageInquiry, FoundLuggageInquiries, FoundLuggageInquiries_foundLuggageInquiries, ToggleLostLuggageInquiry } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function FoundLuggageInquiry(props: Props) {
  const {
    guid,
  } = props;

  const [inquiry, setInquiry] = useState<FoundLuggageInquiries_foundLuggageInquiries | undefined>(undefined);

  const [closedModalOpen, setClosedModalOpen] = useState<boolean>(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const { loading: fetchingInquiries, refetch: refetchInquiries, } = useQuery<FoundLuggageInquiries>(
    GET_FOUND_LUGGAGE_INQUERIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 30000,
      variables: {
        guids: guid ? [guid] : [],
      },
      onCompleted: (resp) => {
        setInquiry(resp.foundLuggageInquiries[0]);
      },
    }
  );
  const [toggleInquiry, { loading: togglingInquiry, }] = useMutation<ToggleLostLuggageInquiry>(TOGGLE_FOUND_LUGGAGE_INQUIRY_QUERY, { notifyOnNetworkStatusChange: true, });
  const [deleteInquiry, { loading: deletingInquiry, }] = useMutation<DeleteFoundLuggageInquiry>(DELETE_FOUND_LUGGAGE_INQUIRY_QUERY, { notifyOnNetworkStatusChange: true, });

  const doToggleInquiry = async () => {
    try {
      await toggleInquiry({
        variables: {
          inquiryGuid: guid,

          closed: !inquiry?.isClosed,
        }
      });
      refetchInquiries();
    } catch (err) {
      console.log("Failed to get toggle Inquiry");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doDelete = async () => {
    try {
      await deleteInquiry({
        variables: {
          guid,
        }
      });

      navigate("/lost-and-found/");
    } catch (err) {
      console.log("Failed to delete Inquiry");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const owner = inquiry?.luggageTag ? inquiry.luggageTag.checkedLuggage.boardingPass.owner : inquiry?.suspectedOwner;
  return (
    <PageContainer>
      <PageSection>

        <ConfirmationModal
          title="Change Ticket Status"
          contents={(
            <>
              <p>
                Are you sure you want to {inquiry?.isClosed ? "re-open" : "close"} this ticket?
              </p>
            </>
          )}
          onClose={(): void => {
            setClosedModalOpen(false);
          }}
          onConfirm={(): void => {
            setClosedModalOpen(false);
            doToggleInquiry();
          }}
          visible={closedModalOpen}
        />

        <ConfirmationModal
          title="Delete Review Record"
          contents={(
            <>
              <p>
                Are you sure you want to delete this record?
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteModalVisible(false);
            doDelete();
          }}
          visible={deleteModalVisible}
        />

        {fetchingInquiries ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || inquiry === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Inquiry Not Found"
              >
                <p>Failed to load inquiry. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>

                <Flex style={{ marginBottom: 20, }}>
                  <FlexItem grow={{ default: "grow" }}>
                    <Alert title={inquiry.isClosed ? "Closed" : "Open"} />
                  </FlexItem>
                </Flex>

                <Card style={{ marginBottom: 20, }}>
                  <CardTitle>
                    Ticket #{inquiry.id}
                  </CardTitle>
                  <CardBody>
                    <Flex style={{ gap: 40, alignItems: "center", justifyContent: "space-between" }}>
                      <FlexItem>
                        <p><span style={{ fontWeight: "bold" }}>Opened</span>: {moment(inquiry.createdAt).format("MM/DD/YYYY HH:mm")}</p>
                        <p><span style={{ fontWeight: "bold" }}>Owner</span>: {owner?.firstName} {owner?.lastName}</p>
                        <p><span style={{ fontWeight: "bold" }}>Comments</span>: {inquiry.remarks}</p>
                      </FlexItem>
                      <FlexItem>
                        <Flex>
                          {inquiry.luggageTag?.checkedLuggage.photoFilePathS3 ? (
                            <FlexItem style={{ objectFit: "cover", width: 128, height: 128, }}>
                              <a href={`${process.env.REACT_APP_S3_URL}${inquiry.luggageTag.checkedLuggage.photoFilePathS3}`} target="_blank" rel="noreferrer">
                                <img src={`${process.env.REACT_APP_S3_URL}${inquiry.luggageTag.checkedLuggage.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", }} />
                              </a>
                            </FlexItem>
                          ) : (
                            <FlexItem>
                              <div style={{ width: 128, height: 128, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f2f2f2", padding: 20, }}>
                                <p>No Image</p>
                              </div>
                            </FlexItem>
                          )}
                        </Flex>
                      </FlexItem>
                    </Flex>
                  </CardBody>
                </Card>
              </>
            )}

            <Flex>
              <FlexItem>
                <Button
                  disabled={deletingInquiry}
                  variant={"danger"}
                  onClick={() => { setDeleteModalVisible(true); }}
                >
                  Delete
                </Button>
              </FlexItem>
              <FlexItem>
                <Button
                  disabled={togglingInquiry}
                  variant={inquiry?.isClosed ? "warning" : "tertiary"}
                  onClick={() => { setClosedModalOpen(true); }}
                >
                  {inquiry?.isClosed ? "Re-open" : "Close"}
                </Button>
              </FlexItem>
            </Flex>

          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
