import "@patternfly/react-core/dist/styles/base.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";


const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

// TODO re-add strict mode when this issue is resolved https://github.com/react-keycloak/react-keycloak/issues/182
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
