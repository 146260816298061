import { gql } from "@apollo/client";

export const GET_FOUND_LUGGAGE_INQUERIES_QUERY = gql`
query FoundLuggageInquiries(
  $guids: [String],
  $owningTravelerGuid: String,
  $submittedByGuid: String,
  $pageNumber: Int,
  $resultsPerPage: Int
) {
  foundLuggageInquiries(
    guids: $guids,
    owningTravelerGuid: $owningTravelerGuid,
    submittedByGuid: $submittedByGuid,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    id
    guid
    createdAt
    lastUpdatedAt
    
    submittedBy {
      guid
    }

    isClosed
    photoFilePathS3
    
    luggageTag {
      checkedLuggage {
        guid
        createdAt
        boardingPass {
          owner {
            guid
            firstName
            lastName
          }
        }
        photoFilePathS3
      }
    }
    suspectedOwner {
      guid
      firstName
      lastName
    }
    
    remarks
  }
}
`;

export const CREATE_FOUND_LUGGAGE_INQUIRY_QUERY = gql`
mutation CreateFoundLuggageInquiry(
  $submittingUserguid: String!,
  $luggageTagGuid: String,
  $suspectedOwnerGuid: String,
  $remarks: String,
) {
  createFoundLuggageInquiry(
    submittingUserguid: $submittingUserguid,
    luggageTagGuid: $luggageTagGuid,
    suspectedOwnerGuid: $suspectedOwnerGuid,
    remarks: $remarks,
  )
}
`;

export const TOGGLE_FOUND_LUGGAGE_INQUIRY_QUERY = gql`
mutation ToggleFoundLuggageInquiry(
    $inquiryGuid: String!,

    $closed: Boolean!
) {
  toggleFoundLuggageInquiry(
    inquiryGuid: $inquiryGuid,

    closed: $closed,
  )
}
`;

export const DELETE_FOUND_LUGGAGE_INQUIRY_QUERY = gql`
mutation DeleteFoundLuggageInquiry(
    $guid: String!
) {
  deleteFoundLuggageInquiry(
    guid: $guid
  )
}
`;
