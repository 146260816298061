import { useMutation, useQuery } from "@apollo/client";
import { Button, DatePicker, Flex, FlexItem, Modal, ModalVariant, NumberInput, Select, SelectOption, SelectVariant, Text, Title, TitleSizes, Wizard } from "@patternfly/react-core";
import { CameraIcon, ImageIcon, PhotoVideoIcon } from "@patternfly/react-icons";
import { FileWithPath, fromEvent } from "file-selector";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { CREATE_CHECKED_LUGGAGE_QUERY, CREATE_TRAVEL_AUTHORIZATION_DOCUMENT_MUTATION, CREATE_TRAVEL_AUTHORIZATION_MUTATION, GET_BOARDING_PASSES_QUERY, GET_TRAVELERS_QUERY, SET_CHECKED_LUGGAGE_PHOTO_QUERY } from "../../graphql/queries";
import { titleize } from "../../utils/string-utils";
import { CreateTravelAuthorization, CreateTravelAuthorizationDocument, GetBoardingPasses, GetBoardingPasses_boardingPasses, GetTravelers, GetTravelers_travelers, ReviewStatus, SetCheckedLuggagePhoto, TravelAuthorizationCategory } from "../../__generated__/api";
import FileUploader, { PendingFile } from "../FileUploader";
import TravelerCard from "../TravelerCard";


interface Props {
  boardingPassGuid: string | undefined;

  onClose: () => void;
  onCreated: () => void;
}

export default (props: Props) => {

  const {
    boardingPassGuid,

    onClose,
    onCreated,
  } = props;


  const [boardingPass, setBoardingPass] = useState<GetBoardingPasses_boardingPasses | undefined>(undefined);
  const [traveler, setTraveler] = useState<GetTravelers_travelers | undefined>(undefined);

  const [pendingFile, setPendingFile] = useState<File>();
  const [luggageWeight, setLuggageWeight] = useState<number>(0);

  const { loading: fetchingTraveler, refetch: refetchTraveler, } = useQuery<GetBoardingPasses>(
    GET_BOARDING_PASSES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        guids: [boardingPassGuid],
      },
      onCompleted: async (resp) => {
        const fetchedBoardingPass = resp.boardingPasses[0];
        setBoardingPass(fetchedBoardingPass);
        if (fetchedBoardingPass !== undefined) {

        }
      },
    }
  );
  const [createCheckedLuggage, { loading: creatingLuggage }] = useMutation<CreateTravelAuthorization>(CREATE_CHECKED_LUGGAGE_QUERY, { notifyOnNetworkStatusChange: true, });


  const fileInputRef = React.createRef<HTMLInputElement>();


  const doCreate = async () => {
    try {
      await createCheckedLuggage({
        variables: {
          boardingPassGuid,
          weightInPounds: luggageWeight,
          image: pendingFile,
        },
      });
      onClose();
      onCreated();
    } catch (err) {
      console.error("Failed to get create Checked Luggage");
      console.error(JSON.stringify(err, null, 2));
    }
  };


  return (
    <Modal
      aria-label="Create Checked Luggage"
      title="Create Checked Luggage"
      isOpen={true}
      variant={ModalVariant.small}
      onClose={() => { onClose(); }}
      actions={[
        <Button key="confirm" variant="primary" onClick={() => { doCreate(); }}>
          Confirm
        </Button>,
        <Button key="cancel" variant="link" onClick={() => { onClose(); }}>
          Cancel
        </Button>
      ]}
    >
      <Flex direction={{ default: "row" }} style={{ columnGap: 40, }}>
        <FlexItem grow={{ default: "grow" }}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none", }}
            onChange={async (e) => {
              const file = await fromEvent(e);
              setPendingFile(file[0] as FileWithPath);
            }}
          />
          <Title headingLevel="h1" size={TitleSizes["lg"]} style={{ marginBottom: 10, }}>
            Luggage Photo
          </Title>
          <div
            style={{ width: 150, height: 150, overflow: "hidden", borderRadius: 10, backgroundColor: "#f2f2f2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: 10, cursor: "pointer", }}
            onClick={() => { fileInputRef.current?.click(); }}
          >
            {pendingFile === undefined ? (
              <>
                <CameraIcon size="lg" color="#a2a2a2" />
                <Text style={{ color: "#a2a2a2" }}>Upload photo</Text>
              </>
            ) : (
              <>
                <img src={URL.createObjectURL(pendingFile)} style={{ width: "100%", height: "100%", objectFit: "cover", }} />
              </>
            )}
          </div>
        </FlexItem>
        <FlexItem grow={{ default: "grow" }}>
          <Title headingLevel="h1" size={TitleSizes["lg"]} style={{ marginBottom: 10, }}>
            Luggage Weight (lbs)
          </Title>
          <NumberInput
            value={luggageWeight}
            onMinus={() => { setLuggageWeight(Math.max(0, luggageWeight - 1.0)); }}
            onPlus={() => { setLuggageWeight(luggageWeight + 1.0); }}
            onChange={(e) => {
              setLuggageWeight(parseFloat(e.currentTarget.value));
            }}
          />
        </FlexItem>
      </Flex>
    </Modal>
  );
};