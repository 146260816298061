import { gql } from "@apollo/client";

export const GET_LOST_LUGGAGE_INQUERIES_QUERY = gql`
query LostLuggageInquiries(
  $guids: [String],
  $ownerGuid: String
) {
  lostLuggageInquiries(
    guids: $guids,
    ownerGuid: $ownerGuid
  ) {
    id
    guid
    createdAt
    lastUpdatedAt
    
    isClosed
    submittedBy {
      guid
    }
    
    luggageTag {
      checkedLuggage {
        boardingPass {
          owner {
            guid
            firstName
            lastName
          }
        }
        photoFilePathS3
      }
    }

    remarks

    reviews {
      guid
      createdAt
      lastUpdatedAt
      updatedBy {
        guid
      }
      state
      status
      message
    }
  }
}
`;

export const UPDATE_LOST_LUGGAGE_INQUIRY_QUERY = gql`
mutation UpdateLostLuggageInquiry(
    $inquiryGuid: String!,

    $state: LostLuggageState,
    $status: LostLuggageInquiryStatus,
    $message: String
) {
  updateLostLuggageInquiry(
    inquiryGuid: $inquiryGuid,

    state: $state,
    status: $status,
    message: $message
  )
}
`;

export const TOGGLE_LOST_LUGGAGE_INQUIRY_QUERY = gql`
mutation ToggleLostLuggageInquiry(
    $inquiryGuid: String!,

    $closed: Boolean!
) {
  toggleLostLuggageInquiry(
    inquiryGuid: $inquiryGuid,

    closed: $closed,
  )
}
`;

export const DELETE_LOST_LUGGAGE_INQUIRY_REVIEW_QUERY = gql`
mutation DeleteLostLuggageInquiryReview(
    $reviewGuid: String!
) {
  deleteLostLuggageInquiryReview(
    reviewGuid: $reviewGuid
  )
}
`;
