import { gql } from "@apollo/client";

export const GET_BOARDING_PASSES_QUERY = gql`
query GetBoardingPasses (
  $guids: [String],
  $ownerGuid: String,
  $spaceRequestGuid: String,
  $pageNumber: Int, 
  $resultsPerPage: Int
) {
  boardingPasses(
    guids: $guids,
    ownerGuid: $ownerGuid,
    spaceRequestGuid: $spaceRequestGuid,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt
    boarded

    owner {
      guid
      firstName
      middleName
      lastName
      email
      dodId
    }

    spaceRequest {
      guid
      createdAt
      lastUpdatedAt
      flightStatus {
        guid
        createdAt
        status
        flight {
          guid
          spaceAvailable
          mission {
            externalId
          }
          departureSchedule
          departureActual
          departureAirport {
            guid
            name
            code
            address {
              guid
              addressLine1
              addressLine2
              addressLine3
              city
              state
              postCode
            }
          }
          arrivalSchedule
          arrivalActual
          arrivalAirport {
            guid
            name
            code
            address {
              guid
              addressLine1
              addressLine2
              addressLine3
              city
              state
              postCode
            }
          }
        }
      }
      travelAuthorization {
        guid
        sponsor {
          guid
        }
      }
    }

    checkedLuggage {
      guid
      createdAt
      photoFilePathS3
      verified
      luggageTag {
        guid
        createdAt
      }
      luggageGroupTag {
        guid
        createdAt
      }
    }
  }
}
`;

export const TOGGLE_BOARDED_MUTATION = gql`
mutation ToggleBoarded(
  $boardingPassGuid: String!,
  $state: Boolean!
) {
  toggleBoarded(
    boardingPassGuid: $boardingPassGuid,
    state: $state
  )
}
`;
