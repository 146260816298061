import { useMutation, useQuery } from "@apollo/client";
import { Button, Flex, FlexItem, FormGroup, Modal, PageSection, Select, SelectOption, SelectVariant, Text, TextContent, TextInput, TextVariants } from "@patternfly/react-core";
import { MapMarkedAltIcon, MapPinIcon } from "@patternfly/react-icons";
import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AirportSelector from "../Components/AirportSelector";
import PageContainer from "../Components/PageContainer";
import { useUser } from "../contexts";
import { GET_LUGGAGE_PALLET_QUERY, UPDATE_LUGGAGE_PALLET_QUERY } from "../graphql/queries";
import { GetAirports_airports, GetLuggagePallets, GetLuggagePallets_luggagePallets, LuggageCheckState, UpdateLuggagePallet } from "../__generated__/api";


enum WizardStep {
  Scan,
  SelectAction,
  ViewHistory,
  Update,
}

export default function TrackPallet() {

  const { me } = useUser();

  const [pallets, setPallets] = useState<GetLuggagePallets_luggagePallets[]>([]);
  const [targetPalletGuid, setTargetPalletGuid] = useState<string>("");
  const [targetPallet, setTargetPallet] = useState<GetLuggagePallets_luggagePallets | undefined>(undefined);

  const [targetAirport, setTargetAirport] = useState<GetAirports_airports | undefined>(undefined);

  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);
  const [targetStatus, setTargetStatus] = useState<LuggageCheckState | undefined>(undefined);

  const [step, setStep] = useState<WizardStep>(WizardStep.Scan);

  const { refetch: refetchLuggagePallets, } = useQuery<GetLuggagePallets>(
    GET_LUGGAGE_PALLET_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        setPallets(resp.luggagePallets);
      },
    }
  );
  const [updatePallet] = useMutation<UpdateLuggagePallet>(UPDATE_LUGGAGE_PALLET_QUERY, { notifyOnNetworkStatusChange: true, });

  const doUpdatePallet = async () => {
    if (targetPallet === undefined) return;

    try {
      await updatePallet({
        variables: {
          palletGuid: targetPallet.guid,
          updatingUserGuid: me?.guid,
          currentAirportGuid: targetAirport?.guid,
          newState: targetStatus,
        }
      });

      setStep(WizardStep.ViewHistory);
      setTargetAirport(undefined);
      setTargetStatus(undefined);

      refetchLuggagePallets();


      // alerts.success(`Updated luggage tag status from ${lastHistory.state} to ${newState}`, 3000);
    } catch (err) {
      console.log("Failed to get update LuggageTag");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const scanTagInputRef = React.createRef<HTMLInputElement>();

  const getPalletByGuid = (guid: string) => pallets.find((lt) => lt.guid === guid);

  useEffect(() => {
    if (targetPallet !== undefined) {
      setTargetPallet(getPalletByGuid(targetPallet.guid));
    }
  }, [pallets]);

  const scanTemplate = () => (
    <Flex alignItems={{ default: "alignItemsCenter" }} direction={{ default: "row" }}>
      <FlexItem>
        {/* Header */}
        <TextContent style={{ marginBottom: 10, }}>
          <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Scan Printed Tag</Text>
        </TextContent>
        {/* Input Pallet Guid */}
        <FormGroup fieldId="tag" style={{ marginBottom: 10, }}>
          <TextInput
            aria-label="Pallet ID"
            validated={getPalletByGuid(targetPalletGuid ?? "") !== undefined ? "success" : "error"}
            title={getPalletByGuid(targetPalletGuid ?? "") === undefined ? "Invalid ID" : "Valid ID"}
            placeholder="e.g. c636e49d-2216-4012-92ba-49e7010ffb34"
            autoFocus
            type="text"
            value={targetPalletGuid}
            onChange={(val) => {
              if (val == "") {
                setTargetPalletGuid("");
                setTargetPallet(undefined);
                return;
              }
              setTargetPalletGuid(val);
              setTargetPallet(getPalletByGuid(val));
            }}
            ref={scanTagInputRef}
          />
        </FormGroup>
        <Button
          style={{ width: "100%" }}
          variant="secondary"
          isDisabled={targetPallet === undefined}
          onClick={() => {
            setStep(WizardStep.SelectAction);
          }}
        >
          Continue
        </Button>
      </FlexItem>
    </Flex>
  );

  const actionsTemplate = () => (
    <>
      <TextContent style={{ marginBottom: 40, }}>
        <Text component={TextVariants.h1} style={{ textAlign: "center" }}>Select Action</Text>
      </TextContent>
      <Flex direction={{ default: "row" }} style={{ marginBottom: 20, }}>
        <FlexItem>
          <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <MapMarkedAltIcon size="xl" />
            </FlexItem>
            <FlexItem>
              <TextContent>
                <Text component={TextVariants.h2} style={{ textAlign: "center" }}>View History</Text>
              </TextContent>
            </FlexItem>
            <FlexItem style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                onClick={() => {
                  setStep(WizardStep.ViewHistory);
                }}
              >
                Continue
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem style={{ paddingLeft: 40, paddingRight: 40, }}>
          <TextContent style={{ marginBottom: 40, }}>
            <Text component={TextVariants.h2} style={{ textAlign: "center" }}>OR</Text>
          </TextContent>
        </FlexItem>
        <FlexItem>
          <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <MapPinIcon size="xl" />
            </FlexItem>
            <FlexItem>
              <TextContent>
                <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Update Location</Text>
              </TextContent>
            </FlexItem>
            <FlexItem style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                onClick={() => {
                  setStep(WizardStep.Update);
                }}
              >
                Continue
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </>
  );

  const historyTemplate = () => (
    <>
      <Table aria-label="Pallet Details">
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>Location</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {targetPallet?.locationHistory.map((lh) => (
            <Tr key={lh.guid}>
              <Td>{moment(lh.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
              <Td>{lh.airport.name}</Td>
              <Td>{lh.state}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );

  const updateTemplate = () => {
    return (
      <>
        <Flex direction={{ default: "column" }}>
          <FlexItem>
            <TextContent style={{}}>
              <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Select New Location</Text>
            </TextContent>
          </FlexItem>
          <FlexItem style={{ marginBottom: 20, }}>
            <AirportSelector
              onSelect={setTargetAirport}
              selectedGuid={targetAirport?.guid}
            />
          </FlexItem>
          <FlexItem>
            <TextContent style={{}}>
              <Text component={TextVariants.h2} style={{ textAlign: "center" }}>Select Status</Text>
            </TextContent>
          </FlexItem>
          <FlexItem style={{ marginBottom: 20, }}>
            <FormGroup fieldId="airport">
              <Select
                placeholderText="Select Status"
                variant={SelectVariant.single}
                typeAheadAriaLabel="Select status"
                onToggle={(expanded) => { setStatusDropdownOpen(expanded); }}
                onSelect={(event, selection, isPlaceholder) => {
                  if (isPlaceholder) {
                    setTargetStatus(undefined);
                    return;
                  }
                  setTargetStatus(selection.toString() as LuggageCheckState);
                  setStatusDropdownOpen(false);
                }}
                onClear={() => {
                  setTargetStatus(undefined);
                }}
                selections={targetStatus}
                isOpen={statusDropdownOpen}
                shouldResetOnSelect
              >
                <SelectOption value={LuggageCheckState.BOARDED}>Boarded</SelectOption>
                <SelectOption value={LuggageCheckState.UNBOARDED}>Unboarded</SelectOption>
              </Select>
            </FormGroup>
          </FlexItem>
          <FlexItem>
            <Button
              style={{ width: "100%" }}
              variant="primary"
              isDisabled={targetAirport === undefined || targetStatus === undefined}
              onClick={() => {
                doUpdatePallet();
              }}
            >
              Save
            </Button>
          </FlexItem>
        </Flex>
      </>
    );
  };

  const stepTemplate = () => {
    switch (step) {
      case WizardStep.Scan:
        return scanTemplate();
      case WizardStep.SelectAction:
        return actionsTemplate();
      case WizardStep.Update:
        return updateTemplate();
      case WizardStep.ViewHistory:
        return historyTemplate();
    }
  };

  const previousStep = () => {
    if (step === WizardStep.SelectAction) {
      setStep(WizardStep.Scan);
    }
    else if (step === WizardStep.Update || step === WizardStep.ViewHistory) {
      setStep(WizardStep.SelectAction);
    }
  };

  return (
    <PageContainer>
      <PageSection>

        <Flex style={{ width: "100%", height: "100%" }} direction={{ default: "column" }}>

          {step !== WizardStep.Scan && (
            <FlexItem>
              <Button variant="secondary" onClick={() => { previousStep(); }}>Back</Button>
            </FlexItem>
          )}

          <FlexItem grow={{ default: "grow" }}>
            <Flex style={{ height: "100%" }} direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
              <FlexItem>
                {stepTemplate()}
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>

      </PageSection>
    </PageContainer >
  );
}
