import { ReactKeycloakProvider } from "@react-keycloak/web";
import React, { StrictMode } from "react";
import "./App.scss";
import keycloak from "./Auth/keycloak";
import { AppRouter } from "./Routes/AppRouter";

const eventLogger = (event: unknown, error: unknown) => {
  // console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: unknown) => {
  // console.log("onKeycloakTokens", tokens);
};

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}

    >
      <AppRouter />
    </ReactKeycloakProvider>
  );
}

export default App;
