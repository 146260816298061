import { gql } from "@apollo/client";


export const GET_LUGGAGE_TAGS_QUERY = gql`
query GetLuggageTags (
  $guids: [String],
  $pageNumber: Int, 
  $resultsPerPage: Int
) {
  luggageTags(
    guids: $guids,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt

    createdBy {
      guid
    }

    checkedLuggage {
      guid
      photoFilePathS3
      boardingPass {
        owner {
          guid
          firstName
          lastName
        }
      }
    }

    locationHistory {
      guid
      createdAt
      updatedBy {
        guid
      }
      airport {
        guid
        name
        code
      }
      state
    }
  }
}
`;

export const CREATE_LUGGAGE_TAG_QUERY = gql`
mutation CreateLuggageTag (
  $creatingUserGuid: String!,
  $luggageGuid: String!,
  $currentAirportGuid: String!
) {
  createLuggageTag(
    creatingUserGuid: $creatingUserGuid,
    luggageGuid: $luggageGuid,
    currentAirportGuid: $currentAirportGuid
  )
}
`;

export const UPDATE_LUGGAGE_TAG_QUERY = gql`
mutation UpdateLuggageTag (
  $tagGuid: String!,
  $updatingUserGuid: String!,
  $currentAirportGuid: String!,
  $newState: LuggageCheckState!
) {
  updateLuggageTag(
    tagGuid: $tagGuid,
    updatingUserGuid: $updatingUserGuid,
    currentAirportGuid: $currentAirportGuid,
    newState: $newState,
  )
}
`;


export const GET_LUGGAGE_GROUP_TAGS_QUERY = gql`
query GetLuggageGroupTags (
  $guids: [String],
  $pageNumber: Int, 
  $resultsPerPage: Int
) {
  luggageGroupTags(
    guids: $guids,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt

    createdBy {
      guid
    }

    checkedLuggage {
      guid
      photoFilePathS3
      boardingPass {
        owner {
          guid
          firstName
          lastName
        }
      }
    }

    locationHistory {
      guid
      createdAt
      updatedBy {
        guid
      }
      airport {
        guid
        name
        code
      }
      state
    }
  }
}
`;

export const CREATE_LUGGAGE_GROUP_TAG_QUERY = gql`
mutation CreateLuggageGroupTag (
  $creatingUserGuid: String!,
  $luggageGuids: [String]!,
  $currentAirportGuid: String!
) {
  createLuggageGroupTag(
    creatingUserGuid: $creatingUserGuid,
    luggageGuids: $luggageGuids,
    currentAirportGuid: $currentAirportGuid
  )
}
`;

export const UPDATE_LUGGAGE_GROUP_TAG_QUERY = gql`
mutation UpdateLuggageGroupTag (
  $tagGuid: String!,
  $updatingUserGuid: String!,
  $currentAirportGuid: String!,
  $newState: LuggageCheckState!
) {
  updateLuggageGroupTag(
    tagGuid: $tagGuid,
    updatingUserGuid: $updatingUserGuid,
    currentAirportGuid: $currentAirportGuid,
    newState: $newState,
  )
}
`;



export const DELETE_LUGGAGE_GROUP_TAG_QUERY = gql`
mutation DeleteLuggageGroupTag (
  $guid: String!
) {
  deleteLuggageGroupTag(
    guid: $guid
  )
}
`;

export const GET_LUGGAGE_PALLET_QUERY = gql`
query GetLuggagePallets (
  $guids: [String],
  $pageNumber: Int, 
  $resultsPerPage: Int
) {
  luggagePallets(
    guids: $guids,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt
    lastUpdatedAt

    createdBy {
      guid
    }

    luggageTags {
      guid
      checkedLuggage {
        guid
        photoFilePathS3
        boardingPass {
          owner {
            guid
            firstName
            lastName
          }
        }
      }
    }

    locationHistory {
      guid
      createdAt
      updatedBy {
        guid
      }
      airport {
        guid
        name
        code
      }
      state
    }
  }
}
`;

export const CREATE_LUGGAGE_PALLET_QUERY = gql`
mutation CreateLuggagePallet (
  $creatingUserGuid: String!,
  $currentAirportGuid: String!
) {
  createLuggagePallet(
    creatingUserGuid: $creatingUserGuid,
    currentAirportGuid: $currentAirportGuid
  )
}
`;


export const DELETE_LUGGAGE_PALLET_QUERY = gql`
mutation DeleteLuggagePallet (
  $guid: String!
) {
  deleteLuggagePallet(
    guid: $guid
  )
}
`;

export const UPDATE_LUGGAGE_PALLET_QUERY = gql`
mutation UpdateLuggagePallet (
  $palletGuid: String!,
  $updatingUserGuid: String!,
  $currentAirportGuid: String!,
  $newState: LuggageCheckState!
) {
  updateLuggagePallet(
    palletGuid: $palletGuid,
    updatingUserGuid: $updatingUserGuid,
    currentAirportGuid: $currentAirportGuid,
    newState: $newState,
  )
}
`;

export const ADD_LUGGAGE_TO_PALLET_QUERY = gql`
mutation AddLuggageToPallet (
  $palletGuid: String!,
  $luggageTagGuid: String!
) {
  addLuggageToPallet(
    palletGuid: $palletGuid,
    luggageTagGuid: $luggageTagGuid,
  )
}
`;

export const REMOVE_LUGGAGE_FROM_PALLET_QUERY = gql`
mutation RemoveLuggageFromPallet (
  $palletGuid: String!,
  $luggageTagGuid: String!
) {
  removeLuggageFromPallet(
    palletGuid: $palletGuid,
    luggageTagGuid: $luggageTagGuid,
  )
}
`;

