import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  List,
  ListItem, NumberInput, PageSection, Spinner, TextInput
} from "@patternfly/react-core";
import { Link, navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { DELETE_AIRPORT_QUERY, GET_AIRPORTS_QUERY, UPDATE_AIRPORT_QUERY } from "../graphql/queries";
import { DeleteAirport, GetAirports, GetAirports_airports, UpdateAirport } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function Airport(props: Props) {
  const {
    guid,
  } = props;

  const [airport, setAirport] = useState<GetAirports_airports | undefined>(undefined);

  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [newCode, setNewCode] = useState<string | undefined>(undefined);
  const [newLat, setNewLat] = useState<number | undefined>(undefined);
  const [newLon, setNewLon] = useState<number | undefined>(undefined);
  const [newAddress1, setNewAddress1] = useState<string | undefined>(undefined);
  const [newAddress2, setNewAddress2] = useState<string | undefined>(undefined);
  const [newAddress3, setNewAddress3] = useState<string | undefined>(undefined);
  const [newCity, setNewCity] = useState<string | undefined>(undefined);
  const [newState, setNewState] = useState<string | undefined>(undefined);
  const [newPostCode, setNewPostCode] = useState<string | undefined>(undefined);


  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);


  const { loading: fetchingAirport, refetch: refetchAirport, } = useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        guids: [guid],
      },
      onCompleted: (resp) => {
        const fetched = resp.airports[0];
        setAirport(fetched);
      },
    }
  );
  const [deleteAirport, { loading: deletingAirport, }] = useMutation<DeleteAirport>(DELETE_AIRPORT_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateAirport, { loading: updatingAirport, }] = useMutation<UpdateAirport>(UPDATE_AIRPORT_QUERY, { notifyOnNetworkStatusChange: true, });


  const resetForm = () => {
    if (airport === undefined) return;
    setNewName(airport.name);
    setNewCode(airport.code);
    setNewLat(airport?.location?.lat);
    setNewLon(airport?.location?.lon);
    setNewAddress1(airport.address.addressLine1);
    setNewAddress2(airport.address.addressLine2 ?? undefined);
    setNewAddress3(airport.address.addressLine3 ?? undefined);
    setNewCity(airport.address.city);
    setNewState(airport.address.state);
    setNewPostCode(airport.address.postCode);
  };

  useEffect(() => {
    if (airport !== undefined) {
      resetForm();
    }
  }, [airport]);

  const doDeleteAirport = async () => {
    try {
      await deleteAirport({
        variables: {
          guid,
        }
      });
      navigate("/airports/");
    } catch (err) {
      console.log("Failed to get delete Airport");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateAirport = async () => {
    try {
      await updateAirport({
        variables: {
          guid,

          // airport info
          name: newName,
          code: newCode,

          // location info
          lat: newLat,
          lon: newLon,

          // address info
          addressLine1: newAddress1,
          addressLine2: newAddress2,
          addressLine3: newAddress3,
          city: newCity,
          state: newState,
          postCode: newPostCode,
        }
      });
      refetchAirport();
    } catch (err) {
      console.log("Failed to get update Airport");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <ConfirmationModal
          title="Delete Traveler"
          contents={(
            <>
              <p>
                Are you sure you want to delete this airport and all associated data?
              </p>
              <p>
                Warning: this action can not be undone.
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteModalVisible(false);
            doDeleteAirport();
          }}
          visible={deleteModalVisible}
        />
        <ConfirmationModal
          title="Confirm Airport Changes"
          contents={(
            <>
              <p>
                Are you sure you want to save your changes to this airport?
              </p>
              <List>
                {airport?.name !== newName && (<ListItem>{airport?.name} {"->"} {newName}</ListItem>)}
                {airport?.code !== newCode && (<ListItem>{airport?.code} {"->"} {newCode}</ListItem>)}
                {airport?.location?.lat !== newLat && (<ListItem>{airport?.location?.lat} {"->"} {newLat}</ListItem>)}
                {airport?.location?.lon !== newLon && (<ListItem>{airport?.location?.lon} {"->"} {newLon}</ListItem>)}
                {airport?.address?.addressLine1 !== newAddress1 && (<ListItem>{airport?.address?.addressLine1} {"->"} {newAddress1}</ListItem>)}
                {airport?.address?.addressLine2 !== newAddress2 && (<ListItem>{airport?.address?.addressLine2} {"->"} {newAddress2}</ListItem>)}
                {airport?.address?.addressLine3 !== newAddress3 && (<ListItem>{airport?.address?.addressLine3} {"->"} {newAddress3}</ListItem>)}
                {airport?.address?.city !== newCity && (<ListItem>{airport?.address?.city} {"->"} {newCity}</ListItem>)}
                {airport?.address?.state !== newState && (<ListItem>{airport?.address?.state} {"->"} {newState}</ListItem>)}
                {airport?.address?.postCode !== newPostCode && (<ListItem>{airport?.address?.postCode} {"->"} {newPostCode}</ListItem>)}
              </List>
            </>
          )}
          onClose={(): void => {
            setUpdateModalVisible(false);
          }}
          onConfirm={(): void => {
            setUpdateModalVisible(false);
            doUpdateAirport();
          }}
          visible={updateModalVisible}
        />

        {fetchingAirport ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || airport === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Airport Not Found"
              >
                <p>Failed to load airport. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>
                <Grid style={{ marginBottom: 40, }}>
                  <GridItem span={4}>
                    {/* Name */}
                    <Form>
                      <FormGroup
                        label="Name"
                        type="string"
                        fieldId="name"
                      >
                        <TextInput
                          value={newName}
                          onChange={(val) => { setNewName(val); }}
                          aria-label="Airport Name Text Input"
                        />
                      </FormGroup>
                      {/* Code */}
                      <FormGroup
                        label="Code"
                        type="string"
                        fieldId="code"
                      >
                        <TextInput
                          value={newCode ?? ""}
                          onChange={(val) => { setNewCode(val); }}
                          aria-label="Airport Code Text Input"
                        />
                      </FormGroup>
                      {/* Lat */}
                      <FormGroup
                        label="Latitude"
                        type="float"
                        fieldId="lat"
                      >
                        <NumberInput
                          min={-99999}
                          value={newLat ?? 0.00}
                          onChange={(e) => { setNewLat(parseFloat(e.currentTarget.value)); }}
                          aria-label="Airport Latitude Text Input"
                        />
                      </FormGroup>
                      {/* Lat */}
                      <FormGroup
                        label="Longitude"
                        type="float"
                        fieldId="lon"
                      >
                        <NumberInput
                          min={-99999}
                          value={newLon ?? 0.00}
                          onChange={(e) => { setNewLon(parseFloat(e.currentTarget.value)); }}
                          aria-label="Airport Longitude Text Input"
                        />
                      </FormGroup>
                      {/* Address Line 1 */}
                      <FormGroup
                        label="Address Line 1"
                        type="string"
                        fieldId="addressLine1"
                      >
                        <TextInput
                          value={newAddress1 ?? ""}
                          onChange={(val) => { setNewAddress1(val); }}
                          aria-label="Airport Address Line 1 Text Input"
                        />
                      </FormGroup>
                      {/* Address Line 2 */}
                      <FormGroup
                        label="Address Line 2"
                        type="string"
                        fieldId="addressLine2"
                      >
                        <TextInput
                          value={newAddress2 ?? ""}
                          onChange={(val) => { setNewAddress2(val); }}
                          aria-label="Airport Address Line 2 Text Input"
                        />
                      </FormGroup>
                      {/* Address Line 3 */}
                      <FormGroup
                        label="Address Line 3"
                        type="string"
                        fieldId="addressLine3"
                      >
                        <TextInput
                          value={newAddress3 ?? ""}
                          onChange={(val) => { setNewAddress3(val); }}
                          aria-label="Airport Address Line 3 Text Input"
                        />
                      </FormGroup>
                      {/* Address City */}
                      <FormGroup
                        label="City"
                        type="string"
                        fieldId="city"
                      >
                        <TextInput
                          value={newCity ?? ""}
                          onChange={(val) => { setNewCity(val); }}
                          aria-label="Airport City Text Input"
                        />
                      </FormGroup>
                      {/* Address State */}
                      <FormGroup
                        label="State"
                        type="string"
                        fieldId="state"
                      >
                        <TextInput
                          value={newState ?? ""}
                          onChange={(val) => { setNewState(val); }}
                          aria-label="Airport State Text Input"
                        />
                      </FormGroup>
                      {/* Address Post Code */}
                      <FormGroup
                        label="Post Code"
                        type="string"
                        fieldId="state"
                      >
                        <TextInput
                          value={newPostCode ?? ""}
                          onChange={(val) => { setNewPostCode(val); }}
                          aria-label="Airport Postal Code Text Input"
                        />
                      </FormGroup>
                      <Button
                        disabled={updatingAirport}
                        onClick={() => { setUpdateModalVisible(true); }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </GridItem>
                </Grid>

                <Flex style={{ marginTop: 20, }}>
                  <FlexItem>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDeleteModalVisible(true);
                      }}
                      disabled={deletingAirport}
                    >
                      Delete Airport
                    </Button>
                  </FlexItem>
                </Flex>
              </>
            )}
          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
