import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, Button, Card, Flex, FlexItem, List, ListItem, Modal, OverflowMenu, OverflowMenuContent, OverflowMenuGroup, OverflowMenuItem, PageSection, Tab, Tabs, TabTitleText, Title, TitleSizes, Tooltip } from "@patternfly/react-core";
import { PlaneDepartureIcon, UserIcon } from "@patternfly/react-icons";
import { ExpandableRowContent, TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import avatarImg from "../assets/avatar.svg";
import Empty from "../Components/Empty";
import HorizontalTable from "../Components/HorizontalTable";
import PageContainer from "../Components/PageContainer";
import { GENERATE_ROLL_CALL_QUERY, GET_FLIGHTS_QUERY, GET_FLIGHT_CHECK_INS_QUERY, GET_SPACE_REQUESTS_QUERY, SET_REGISTERED_FLIGHT_STATUS_MUTATION, TOGGLE_FLIGHT_ROLL_CALL_QUERY } from "../graphql/queries";
import { romanToInt } from "../utils/string-utils";
import { FlightStatusType, GenerateRollCall, GenerateRollCall_generateRollCall, GetFlightCheckIns, GetFlightCheckIns_flightCheckIns, GetFlights, GetFlights_flights, GetSpaceRequests, GetSpaceRequests_spaceRequests, SetRegisteredFlightStatus, ToggleFlightRollCall } from "../__generated__/api";


interface Props {
  guid: string | undefined;
}

const RollCall = (props: Props) => {
  const {
    guid,
  } = props;

  const [flight, setFlight] = useState<GetFlights_flights | undefined>(undefined);
  const [spaceRequests, setSpaceRequests] = useState<GetSpaceRequests_spaceRequests[]>([]);
  const [checkIns, setCheckIns] = useState<GetFlightCheckIns_flightCheckIns[]>([]);

  const [passengerListTabIndex, setPassengerListTabIndex] = useState<number>(0);
  const [generatedModalVisible, setGeneratedModalVisible] = useState<boolean>(false);
  const [generatedRollCall, setGeneratedRollCall] = useState<GenerateRollCall_generateRollCall[]>([]);
  const [expandedRow, setExpandedRow] = useState<{ status: FlightStatusType, guid: string } | undefined>(undefined);


  const { loading: fetchingFlights, refetch: refetchFlights, } = useQuery<GetFlights>(
    GET_FLIGHTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      pollInterval: 1000,
      variables: {
        guids: [guid],
      },
      onCompleted: async (resp) => {
        const fetchedFlight = resp.flights[0];
        setFlight(fetchedFlight);
      },
    }
  );
  const { loading: fetchingSpaceRequests, refetch: refetchSpaceRequests, } = useQuery<GetSpaceRequests>(
    GET_SPACE_REQUESTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      pollInterval: 1000,
      variables: {
        flightGuid: guid,
      },
      onCompleted: async (resp) => {
        setSpaceRequests(resp.spaceRequests);
      },
    }
  );
  const { loading: fetchingCheckIns, refetch: refetchCheckIns, } = useQuery<GetFlightCheckIns>(
    GET_FLIGHT_CHECK_INS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      pollInterval: 1000,
      variables: {
        flightGuid: guid,
      },
      onCompleted: async (resp) => {
        setCheckIns(resp.flightCheckIns);
      },
    }
  );
  const [setRegisteredFlightStatus, { loading: isSettingRegisteredFlightStatus }] = useMutation<SetRegisteredFlightStatus>(SET_REGISTERED_FLIGHT_STATUS_MUTATION, { notifyOnNetworkStatusChange: true });
  const [toggleFlightRollCall, { loading: isTogglingFlightRollCall }] = useMutation<ToggleFlightRollCall>(TOGGLE_FLIGHT_ROLL_CALL_QUERY, { notifyOnNetworkStatusChange: true });

  const apolloClient = useApolloClient();

  const doToggleRollCall = async (state: boolean) => {
    if (flight === undefined) {
      return;
    }
    try {
      await toggleFlightRollCall({
        variables: {
          guid: flight.guid,
          state,
        },
      });

    } catch (err) {
      console.log("Failed to get generate roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doGenerateRollCall = async () => {
    if (flight === undefined) {
      return;
    }
    try {
      await doToggleRollCall(true);

      const result = await apolloClient.query<GenerateRollCall>({
        query: GENERATE_ROLL_CALL_QUERY,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: {
          flightGuid: flight.guid,
        },
      });

      await refetchFlights();
      await refetchSpaceRequests();

      setGeneratedRollCall(result.data.generateRollCall);
      setGeneratedModalVisible(true);
    } catch (err) {
      console.log("Failed to get generate roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doChangeSpaceRequestList = async (travelAuthGuid: string, status: FlightStatusType) => {
    if (flight === undefined) {
      return;
    }
    try {
      await setRegisteredFlightStatus({
        variables: {
          travelAuthGuid: travelAuthGuid,
          flightGuid: flight.guid,
          flightStatus: status,
        },
      });

      await refetchFlights();
      await refetchSpaceRequests();
    } catch (err) {
      console.log("Failed to change flight list for Space Request");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doAcceptGeneratedRollCall = async () => {
    if (flight === undefined) {
      return;
    }
    if (generatedRollCall === undefined) {
      return;
    }
    try {
      let promises: any[] = [];
      generatedRollCall.forEach((grc) => {
        const promise = doChangeSpaceRequestList(grc.travelAuthorization.guid, FlightStatusType.ROLL_CALL);
        promises.push(promise);
      });
      await Promise.all(promises);

      await refetchFlights();
      await refetchSpaceRequests();
    } catch (err) {
      console.log("Failed to accept generated roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doResetSeats = async () => {
    if (flight === undefined) {
      return;
    }
    try {
      await doToggleRollCall(false);
      let promises: any[] = [];
      spaceRequests.forEach((sr) => {
        const promise = doChangeSpaceRequestList(sr.travelAuthorization.guid, FlightStatusType.BACKLOG);
        promises.push(promise);
      });
      await Promise.all(promises);

      await refetchFlights();
      await refetchSpaceRequests();
    } catch (err) {
      console.log("Failed to get generate roll call");
      console.log(JSON.stringify(err, null, 2));
    }
  };


  const getSpaceRequestsForStatus = (status: FlightStatusType) => _.sortBy(
    spaceRequests.filter((sp) => sp.flightStatus?.status === status),
    (sr) => {
      const rank = romanToInt(sr.travelAuthorization.category.replace("CATEGORY_", ""));
      return rank;
    },
    ["asc"]
  );

  const getCheckedIn = () => checkIns;
  const getBoarded = () => checkIns.filter((ci) => ci.boardingPass?.boarded);

  const flightTemplate = () => {
    if (guid === undefined || flight === undefined) {
      return (
        <Alert
          isInline
          variant="danger"
          title="Flight Not Found"
        >
          <p>Failed to load flight. No such ID exists: {guid}.</p>
        </Alert>
      );
    }

    const timeAlert = () => {
      if (flight.rollCallTime) {
        // roll call time passed
        if (moment(flight.rollCallTime).isBefore(moment())) {
          if (!flight.hasRollCallStarted) {
            return (
              <Alert
                isInline
                variant="danger"
                title="Roll Call Overdue"
              >
                <p>
                  This flight is overdue for roll call.
                </p>
              </Alert>
            );
          }
        }
        // flight rollcall is upcoming
        else if (moment(flight.rollCallTime).isSameOrAfter(moment())) {
          if (!flight.hasRollCallStarted) {
            if (moment(flight.rollCallTime).isBetween(moment(), moment().add(1, "hour"))) {
              return (
                <Alert
                  isInline
                  variant="info"
                  title="Roll Call Upcoming"
                >
                  <p>
                    This flight has a roll call scheduled within the next hour.
                  </p>
                </Alert>
              );
            }
          }
        }
      } else {
        if (flight.hasRollCallStarted) {
          return (
            <Alert
              isInline
              variant="success"
              title="Roll Call Complete"
            >
              <p>
                This flight roll call has already occurred.
              </p>
            </Alert>
          );
        } else {
          return (
            <Alert
              isInline
              variant="warning"
              title="No Published Roll Call Time"
            >
              <p>
                This flight has no published time to begin Roll Call.
              </p>

              <Link to={`/missions/${flight.mission.guid}/flights/${flight.guid}`}>
                <Button variant="primary" style={{ marginTop: 10, }}>
                  Update Roll Call Time
                </Button>
              </Link>
            </Alert>
          );
        }
      }
    };

    const isRowExpanded = (status: FlightStatusType, guid: string) => {
      return expandedRow !== undefined && expandedRow.status === status && expandedRow.guid === guid;
    }

    const expandRow = (status: FlightStatusType, guid: string) => {
      setExpandedRow(isRowExpanded(status, guid) ? undefined : { status, guid });
    };

    const flightListTemplate = (status: FlightStatusType, actions: { title: string; newList: FlightStatusType; }[]) => {
      return (
        <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF" }} direction={{ default: "column" }}>
          <FlexItem>
            <TableComposable aria-label="Simple table" variant="compact" borders>
              <Thead>
                <Tr>
                  <Th />
                  <Th>Submitted</Th>
                  <Th>Category</Th>
                  <Th># Travelers</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getSpaceRequestsForStatus(status).map((sr, idx) => (
                  <>
                    <Tr key={sr.guid}>
                      <Td
                        expand={{
                          rowIndex: idx,
                          isExpanded: isRowExpanded(status, sr.guid),
                          onToggle: () => { expandRow(status, sr.guid) },
                        }}
                      />
                      <Td dataLabel={"Submitted"}>{moment(sr.createdAt).format("MM/DD/YYY HH:mm A")}</Td>
                      <Td dataLabel={"Category"}>{sr.travelAuthorization.category.replace("_", " ")}</Td>
                      <Td dataLabel={"# Travelers"}>{sr.travelAuthorization.travelers.length}</Td>
                      <Td isActionCell>
                        <OverflowMenu breakpoint="lg">
                          <OverflowMenuContent>
                            <OverflowMenuGroup groupType="button">
                              {actions.map((a) => (
                                <OverflowMenuItem key={`${sr.guid}-${a.title}`}>
                                  <Button
                                    onClick={() => {
                                      doChangeSpaceRequestList(sr.travelAuthorization.guid, a.newList);
                                    }}
                                    isSmall
                                  >
                                    {a.title}
                                  </Button>
                                </OverflowMenuItem>
                              ))}
                            </OverflowMenuGroup>
                          </OverflowMenuContent>
                        </OverflowMenu>
                      </Td>
                    </Tr>
                    <Tr isExpanded={isRowExpanded(status, sr.guid)}>
                      <Td noPadding colSpan={6}>
                        <ExpandableRowContent>
                          <Flex style={{ padding: 20, }} direction={{ default: "column" }}>
                            <FlexItem>
                              <Title headingLevel="h6" size={TitleSizes["lg"]}>
                                Travelers ({sr.travelAuthorization.travelers.length})
                              </Title>
                            </FlexItem>
                            <FlexItem>
                              <List isPlain>
                                {sr.travelAuthorization.travelers.map((t) => (
                                  <ListItem icon={<UserIcon />} key={t.guid}>
                                    <Link to={`/passengers/${t.guid}`}>
                                      <p>
                                        {t.firstName}
                                        {" "}
                                        <Tooltip
                                          content={
                                            <span>{t.middleName}</span>
                                          }
                                        >
                                          <span>
                                            {t.middleName?.substring(0, 1)}
                                          </span>
                                        </Tooltip>
                                        {" "}
                                        {t.lastName}
                                      </p>
                                    </Link>
                                  </ListItem>
                                ))}
                              </List>
                            </FlexItem>
                          </Flex>
                        </ExpandableRowContent>
                      </Td>
                    </Tr>
                  </>
                ))}
              </Tbody>
            </TableComposable>
          </FlexItem>
        </Flex>
      );
    }

    const seatsFilled = flight.flightStatuses.filter((flst) => flst.status === FlightStatusType.ROSTER).map((flst) => flst.spaceRequest.travelAuthorization.travelers.length).reduce((a, b) => a + b, 0);
    const seatsRemaining = flight.spaceAvailable - seatsFilled;

    return (
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          {timeAlert()}
        </FlexItem>

        {/* Header */}
        <FlexItem alignSelf={{ default: "alignSelfCenter" }}>
          <Flex direction={{ default: "row" }} alignItems={{ default: "alignItemsCenter" }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]}>
                {flight.departureAirport.code}
              </Title>
            </FlexItem>
            <FlexItem>
              <PlaneDepartureIcon size="xl" color="#D3D6DA" />
            </FlexItem>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]}>
                {flight.arrivalAirport.code}
              </Title>
            </FlexItem>
          </Flex>
        </FlexItem>

        {/* Flight Details */}
        <FlexItem>
          <Flex direction={{ default: "column" }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Flight Details
              </Title>
            </FlexItem>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "Departure Airport", value: flight.departureAirport.name, valueType: "string", },
                  // { title: "Departure Airport Code", value: flight.departureAirport.code, valueType: "string", },
                  { title: "Departure Time", value: moment(flight.departureSchedule).format("MM/DD/YYYY HH:mm A"), valueType: "string", },
                  { title: "Arrival Airport", value: flight.arrivalAirport.name, valueType: "string", },
                  // { title: "Arrival Airport Code", value: flight.arrivalAirport.code, valueType: "string", },
                  { title: "Arrival Time", value: moment(flight.arrivalSchedule).format("MM/DD/YYYY HH:mm A"), valueType: "string", },

                  { title: "Aircraft", value: `${flight.aircraft.manufacturer.name} ${flight.aircraft.designation} ${flight.aircraft.name}`, valueType: "string", },
                  { title: "Total Seats Available", value: flight.spaceAvailable, valueType: "string", },
                ]}
              />
            </FlexItem>
          </Flex>
        </FlexItem>

        {/* Flight Details */}
        <FlexItem>
          <Flex direction={{ default: "column" }}>
            <FlexItem>
              <Title headingLevel="h1" size={TitleSizes["2xl"]} style={{ marginBottom: 10, }}>
                Seat Details
              </Title>
            </FlexItem>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "Total Seats Available", value: flight.spaceAvailable, valueType: "number", },
                  { title: "Seats Filled", value: seatsFilled, valueType: "number", },
                  { title: "Seats Remaining", value: seatsRemaining, valueType: "number", },
                ]}
              />
            </FlexItem>
          </Flex>
        </FlexItem>

        {/* Actions */}
        <FlexItem>
          <Flex direction={{ default: "row" }}>
            <FlexItem>
              <Button
                variant="primary"
                onClick={() => {
                  doGenerateRollCall();
                }}
                isDisabled={flight.hasRollCallStarted}
              >
                Begin Roll Call
              </Button></FlexItem>
            <FlexItem>
              <Button
                variant="danger"
                onClick={async () => {
                  if (confirm("Are you sure you want to reset the seats on this flight?")) {
                    doResetSeats();
                  }
                }}
                isDisabled={!flight.hasRollCallStarted}
              >
                Reset Seats
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>

        {/* Passengers Lists */}
        <Tabs
          activeKey={passengerListTabIndex}
          onSelect={(e, index) => { setPassengerListTabIndex(index as number); }}
          isBox={true}
          aria-label="Passenger List tabs"
          style={{ marginBottom: 0, }}
        >
          {/* Backlog */}
          <Tab eventKey={0} title={<TabTitleText>Backlog ({getSpaceRequestsForStatus(FlightStatusType.BACKLOG).length})</TabTitleText>}>
            {getSpaceRequestsForStatus(FlightStatusType.BACKLOG).length === 0 ? (
              <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                <Empty
                  title={"Backlog is Empty"}
                  description={"There are no space requests in the backlog for this flight."}
                />
              </div>
            ) : (
              <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF" }} direction={{ default: "column" }}>
                <FlexItem>
                  {flightListTemplate(
                    FlightStatusType.BACKLOG,
                    [
                      { title: "Promote to Roll Call", newList: FlightStatusType.ROLL_CALL },
                    ],
                  )}
                </FlexItem>
              </Flex>
            )}
          </Tab>
          {/* Roll Call */}
          <Tab eventKey={1} title={<TabTitleText>Roll Call ({getSpaceRequestsForStatus(FlightStatusType.ROLL_CALL).length})</TabTitleText>}>
            {getSpaceRequestsForStatus(FlightStatusType.ROLL_CALL).length === 0 ? (
              <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                <Empty
                  title={"Roll Call is Empty"}
                  description={"There are no space requests on the roll call for this flight."}
                />
              </div>
            ) : (
              <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF" }} direction={{ default: "column" }}>
                <FlexItem>
                  {flightListTemplate(
                    FlightStatusType.ROLL_CALL,
                    [
                      { title: "Demote to Backlog", newList: FlightStatusType.BACKLOG },
                      { title: "Promote to Roster", newList: FlightStatusType.ROSTER },
                    ],
                  )}
                </FlexItem>
              </Flex>
            )}
          </Tab>
          {/* Roster */}
          <Tab eventKey={2} title={<TabTitleText>Roster ({getSpaceRequestsForStatus(FlightStatusType.ROSTER).length})</TabTitleText>}>
            {getSpaceRequestsForStatus(FlightStatusType.ROSTER).length === 0 ? (
              <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                <Empty
                  title={"Roster is Empty"}
                  description={"There are no space requests on the roster for this flight."}
                />
              </div>
            ) : (
              <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF" }} direction={{ default: "column" }}>
                <FlexItem>
                  {flightListTemplate(
                    FlightStatusType.ROSTER,
                    [
                      { title: "Demote to Roll Call", newList: FlightStatusType.ROLL_CALL },
                    ],
                  )}
                </FlexItem>
              </Flex>
            )}
          </Tab>
          {/* Checked-In */}
          <Tab eventKey={3} title={<TabTitleText>Checked-In ({getCheckedIn().length})</TabTitleText>}>
            {getCheckedIn().length === 0 ? (
              <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                <Empty
                  title={"Checked-In List is Empty"}
                  description={"There are no checked-in passengers."}
                />
              </div>
            ) : (
              <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF", padding: 20, }} direction={{ default: "column" }} >
                <FlexItem>
                  <Title headingLevel="h4" size={TitleSizes["xl"]}>
                    Passengers ({getCheckedIn().length})
                  </Title>
                </FlexItem>
                <FlexItem>
                  <Flex>
                    {getCheckedIn().map((ci) => (
                      <FlexItem key={ci.guid}>
                        <Card style={{ width: "180px", padding: 20, position: "relative" }}>
                          <Flex direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
                            <FlexItem>
                              <Avatar src={avatarImg} alt="avatar" size="lg" style={{}} />
                            </FlexItem>
                            <FlexItem>
                              <Link to={`/passengers/${ci.boardingPass?.owner.guid}`}>
                                <Title headingLevel="h4" size={TitleSizes["md"]} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                  {ci.boardingPass?.owner.firstName} {ci.boardingPass?.owner.middleName?.substring(0, -1)} {ci.boardingPass?.owner.lastName}
                                </Title>
                              </Link>
                            </FlexItem>
                          </Flex>
                        </Card>
                      </FlexItem>
                    ))}
                  </Flex>
                </FlexItem>
              </Flex>
            )}
          </Tab>
          {/* Boarded */}
          <Tab eventKey={4} title={<TabTitleText>Boarded ({getBoarded().length})</TabTitleText>}>
            {getBoarded().length === 0 ? (
              <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                <Empty
                  title={"Boarded List is Empty"}
                  description={"There are no boarded passengers."}
                />
              </div>
            ) : (
              <Flex style={{ border: "1px solid #d2d2d2", borderTopWidth: 0, backgroundColor: "#FFFFFF", padding: 20, }} direction={{ default: "column" }} >
                <FlexItem>
                  <a href={`/roll-call/${guid}/manifest`} target="_blank">
                    <Button
                      variant="primary"
                    >
                      View Manifest
                    </Button>
                  </a>
                </FlexItem>
                <FlexItem>
                  <Title headingLevel="h4" size={TitleSizes["xl"]}>
                    Passengers ({getBoarded().length})
                  </Title>
                </FlexItem>
                <FlexItem>
                  <Flex>
                    {getBoarded().map((ci) => (
                      <FlexItem key={ci.guid}>
                        <Card style={{ width: "180px", padding: 20, position: "relative" }}>
                          <Flex direction={{ default: "column" }} justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }}>
                            <FlexItem>
                              <Avatar src={avatarImg} alt="avatar" size="lg" style={{}} />
                            </FlexItem>
                            <FlexItem>
                              <Link to={`/passengers/${ci.boardingPass?.owner.guid}`}>
                                <Title headingLevel="h4" size={TitleSizes.md} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                  {ci.boardingPass?.owner.firstName} {ci.boardingPass?.owner.middleName?.substring(0, -1)} {ci.boardingPass?.owner.lastName}
                                </Title>
                              </Link>
                            </FlexItem>
                          </Flex>
                        </Card>
                      </FlexItem>
                    ))}
                  </Flex>
                </FlexItem>
              </Flex>
            )}
          </Tab>
        </Tabs>

      </Flex>
    );
  }

  const generateModalTemplate = () => {
    const closeModal = () => {
      setGeneratedModalVisible(false);
    }
    return (
      <Modal
        width="50%"
        aria-label={"Roll Call Modal"}
        title={"Roll Call Generation Results"}
        isOpen={true}
        onClose={() => {
          closeModal();
        }}
        actions={generatedRollCall.length === 0 ? [
          <Button key="cancel" variant="link" onClick={() => {
            closeModal();
          }}>
            Close
          </Button>,
        ] : [
          <Button
            key="confirm"
            variant="primary"
            onClick={() => {
              doAcceptGeneratedRollCall();
              closeModal();
            }}
          >
            Accept
          </Button>,
          <Button
            key="cancel"
            variant="link"
            onClick={() => {
              closeModal();
            }}
          >
            Reject
          </Button>,
        ]}
      >
        {generatedRollCall.length === 0 ? (
          <>
            <p style={{ fontWeight: "bold", marginBottom: 10, }}>
              The system was unable to generate a Roll Call list from the backlog.
            </p>
            <p style={{ marginBottom: 10, }}>
              This occurs when there are either no space requests for the flight, or the space requests that are available would exceed the conditions of the flight (e.g. too many people for the number of seats available).
            </p>
            <p>
              If you wish to re-generate the Roll Call, you must press the <strong>Reset</strong> button.
            </p>
          </>
        ) : (
          <>
            <Flex style={{ marginBottom: 20, }}>
              <FlexItem>
                <p>The system generated a Roll Call filling {generatedRollCall.map((spaceReq) => spaceReq.travelAuthorization.travelers.length).reduce((a, b) => a + b, 0)}/{flight?.spaceAvailable} total seats.</p>
              </FlexItem>
            </Flex>

            <p style={{ fontWeight: "bold" }}>Requests Accepted from Backlog:</p>
            <List>
              {generatedRollCall.map((sr) => (
                <ListItem key={sr.guid}>
                  {sr.travelAuthorization.category.replace("_", " ")} submitted on {moment(sr.createdAt).format("MM/DD/YYYY HH:mm")} ({sr.travelAuthorization.travelers.length} travelers)
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Modal>
    );
  };

  return (
    <PageContainer>
      <PageSection>

        {generatedModalVisible && generateModalTemplate()}

        {flightTemplate()}

      </PageSection>
    </PageContainer>
  );
};

export default RollCall;
