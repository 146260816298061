import { useQuery } from "@apollo/client";
import React from "react";
import { ME_QUERY } from "../../graphql/queries";
import { GetMe } from "../../__generated__/api";
import { UserContext } from "./UserContext";

export interface IUserConfiguration {
    children?: React.ReactElement;
}

export const UserProvider = ({
    children,

}: IUserConfiguration) => {
    const {
        loading,
        error,
        data,
    } = useQuery<GetMe>(ME_QUERY);

    return (
        <UserContext.Provider
            value={{
                me: data?.me
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
