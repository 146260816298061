import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Card, CardBody, CardTitle, Flex,
  FlexItem, FormGroup, Modal, PageSection, Select, SelectOption, Spinner, TextArea, Title, TitleSizes
} from "@patternfly/react-core";
import { MinusCircleIcon } from "@patternfly/react-icons";
import { Link } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import { getEnumValues } from "../utils/upgrade-utils";
import PageContainer from "../Components/PageContainer";
import { DELETE_LOST_LUGGAGE_INQUIRY_REVIEW_QUERY, GET_LOST_LUGGAGE_INQUERIES_QUERY, TOGGLE_LOST_LUGGAGE_INQUIRY_QUERY, UPDATE_LOST_LUGGAGE_INQUIRY_QUERY } from "../graphql/queries";
import { DeleteLostLuggageInquiryReview, LostLuggageInquiries, LostLuggageInquiries_lostLuggageInquiries, LostLuggageInquiries_lostLuggageInquiries_reviews, LostLuggageInquiryStatus, LostLuggageState, ToggleLostLuggageInquiry, UpdateLostLuggageInquiry } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function LostLuggageInquiry(props: Props) {
  const {
    guid,
  } = props;

  const [inquiry, setInquiry] = useState<LostLuggageInquiries_lostLuggageInquiries | undefined>(undefined);

  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string | undefined>(undefined);
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<LostLuggageInquiryStatus | undefined>(undefined);
  const [stateModalOpen, setStateModalOpen] = useState<boolean>(false);
  const [stateDropdownOpen, setStateDropdownOpen] = useState<boolean>(false);
  const [newState, setNewState] = useState<LostLuggageState | undefined>(undefined);
  const [closedModalOpen, setClosedModalOpen] = useState<boolean>(false);

  const [deleteReviewModalVisible, setDeleteReviewModalVisible] = useState<boolean>(false);
  const [targetReview, setTargetReview] = useState<LostLuggageInquiries_lostLuggageInquiries_reviews | undefined>(undefined);

  const { loading: fetchingInquiries, refetch: refetchInquiries, } = useQuery<LostLuggageInquiries>(
    GET_LOST_LUGGAGE_INQUERIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 30000,
      variables: {
        guids: guid ? [guid] : [],
      },
      onCompleted: (resp) => {
        setInquiry(resp.lostLuggageInquiries[0]);
      },
    }
  );
  const [updateInquiry, { loading: updatingInquiry, }] = useMutation<UpdateLostLuggageInquiry>(UPDATE_LOST_LUGGAGE_INQUIRY_QUERY, { notifyOnNetworkStatusChange: true, });
  const [toggleInquiry, { loading: togglingInquiry, }] = useMutation<ToggleLostLuggageInquiry>(TOGGLE_LOST_LUGGAGE_INQUIRY_QUERY, { notifyOnNetworkStatusChange: true, });
  const [deleteInquiryReview, { loading: deletingInquiryReview, }] = useMutation<DeleteLostLuggageInquiryReview>(DELETE_LOST_LUGGAGE_INQUIRY_REVIEW_QUERY, { notifyOnNetworkStatusChange: true, });


  const getLastStatus = (ticket: LostLuggageInquiries_lostLuggageInquiries) => ticket.reviews[ticket.reviews.length > 1 ? ticket.reviews.length - 1 : 0];

  const doUpdateInquiry = async () => {
    try {
      await updateInquiry({
        variables: {
          inquiryGuid: guid,

          state: newState,
          status: newStatus,
          message: newComment,
        }
      });
      refetchInquiries();
    } catch (err) {
      console.log("Failed to get update Inquiry");
      console.log(JSON.stringify(err, null, 2));
    }

    setNewState(undefined);
    setNewStatus(undefined);
    setNewComment(undefined);
  };

  const doToggleInquiry = async () => {
    try {
      await toggleInquiry({
        variables: {
          inquiryGuid: guid,

          closed: !inquiry?.isClosed,
        }
      });
      refetchInquiries();
    } catch (err) {
      console.log("Failed to get toggle Inquiry");
      console.log(JSON.stringify(err, null, 2));
    }

    setNewState(undefined);
    setNewStatus(undefined);
    setNewComment(undefined);
  };

  const doDeleteReview = async () => {
    try {
      await deleteInquiryReview({
        variables: {
          reviewGuid: targetReview?.guid,
        }
      });
      refetchInquiries();
    } catch (err) {
      console.log("Failed to delete Inquiry Review");
      console.log(JSON.stringify(err, null, 2));
    }

    setTargetReview(undefined);
  };

  const modalTemplate = (isOpen: boolean, onClose: () => void, title: string, children: React.ReactNode) => (
    <Modal
      width="50%"
      aria-label={"Preview Modal"}
      title={title}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      header={(
        <>
          <Title id="custom-header-label" headingLevel="h1" size={TitleSizes["2xl"]}>
            {title}
          </Title>
        </>
      )}
    >
      {children}
    </Modal>
  );

  return (
    <PageContainer>
      <PageSection>

        {modalTemplate(
          commentModalOpen,
          () => {
            setCommentModalOpen(false);
            setNewComment(undefined);
          },
          "Add Comment",
          <>
            <FormGroup
              label="Comment"
              type="string"
              fieldId="comment"
              style={{ marginBottom: 10, }}
            >
              <TextArea
                value={newComment}
                onChange={(val) => { setNewComment(val); }}
                aria-label="Comment"
              />
            </FormGroup>
            <Flex direction={{ default: "row", }} justifyContent={{ default: "justifyContentFlexEnd" }}>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setCommentModalOpen(false);
                    doUpdateInquiry();
                  }}
                >
                  Submit
                </Button>
              </FlexItem>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setCommentModalOpen(false);
                    setNewComment(undefined);
                  }}
                >
                  Cancel
                </Button>
              </FlexItem>
            </Flex>
          </>
        )}

        {modalTemplate(
          statusModalOpen,
          () => {
            setStatusModalOpen(false);
            setNewStatus(undefined);
          },
          "Update Inquiry Status",
          <>
            <FormGroup
              label="Status"
              fieldId="status"
              style={{ marginBottom: 10, }}
            >
              <Select
                isOpen={statusDropdownOpen}
                onToggle={(expanded) => { setStatusDropdownOpen(expanded); }}
                selections={newStatus}
                style={{ position: "relative" }}
              >
                {getEnumValues(LostLuggageInquiryStatus).map((st) => (
                  <SelectOption
                    key={st}
                    value={st}
                    onClick={() => { setNewStatus(st as LostLuggageInquiryStatus); }}
                  >
                    {st.replace(/_/g, " ")}
                  </SelectOption>
                ))}
              </Select>
            </FormGroup>
            <Flex direction={{ default: "row", }} justifyContent={{ default: "justifyContentFlexEnd" }}>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setStatusModalOpen(false);
                    doUpdateInquiry();
                  }}
                >
                  Submit
                </Button>
              </FlexItem>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setStatusModalOpen(false);
                    setNewStatus(undefined);
                  }}
                >
                  Cancel
                </Button>
              </FlexItem>
            </Flex>
          </>
        )}

        {modalTemplate(
          stateModalOpen,
          () => {
            setStateModalOpen(false);
            setNewState(undefined);
          },
          "Update Luggage State",
          <>
            <FormGroup
              label="State"
              fieldId="state"
              style={{ marginBottom: 10, }}
            >
              <Select
                isOpen={stateDropdownOpen}
                onToggle={(expanded) => { setStateDropdownOpen(expanded); }}
                selections={newState}
                style={{ position: "relative" }}
              >
                {getEnumValues(LostLuggageState).map((st) => (
                  <SelectOption
                    key={st}
                    value={st}
                    onClick={() => { setNewState(st as LostLuggageState); }}
                  >
                    {st.replace(/_/g, " ")}
                  </SelectOption>
                ))}
              </Select>
            </FormGroup>
            <Flex direction={{ default: "row", }} justifyContent={{ default: "justifyContentFlexEnd" }}>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setStateModalOpen(false);
                    doUpdateInquiry();
                  }}
                >
                  Submit
                </Button>
              </FlexItem>
              <FlexItem alignSelf={{ default: "alignSelfFlexEnd" }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setStateModalOpen(false);
                    setNewState(undefined);
                  }}
                >
                  Cancel
                </Button>
              </FlexItem>
            </Flex>
          </>
        )}

        <ConfirmationModal
          title="Change Ticket Status"
          contents={(
            <>
              <p>
                Are you sure you want to {inquiry?.isClosed ? "re-open" : "close"} this ticket?
              </p>
            </>
          )}
          onClose={(): void => {
            setClosedModalOpen(false);
          }}
          onConfirm={(): void => {
            setClosedModalOpen(false);
            doToggleInquiry();
          }}
          visible={closedModalOpen}
        />

        <ConfirmationModal
          title="Delete Review Record"
          contents={(
            <>
              <p>
                Are you sure you want to delete this record?
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteReviewModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteReviewModalVisible(false);
            doDeleteReview();
          }}
          visible={deleteReviewModalVisible}
        />

        {fetchingInquiries ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || inquiry === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Inquiry Not Found"
              >
                <p>Failed to load inquiry. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>

                <Flex style={{ marginBottom: 20, }}>
                  <FlexItem grow={{ default: "grow" }}>
                    <Alert title={inquiry.isClosed ? "Closed" : getLastStatus(inquiry).status.replace(/_/g, " ")} />
                  </FlexItem>
                </Flex>

                <Card style={{ marginBottom: 20, }}>
                  <CardTitle>
                    Ticket #{inquiry.id}
                  </CardTitle>
                  <CardBody>
                    <Flex style={{ gap: 40, alignItems: "center", justifyContent: "space-between" }}>
                      <FlexItem>
                        <p><span style={{ fontWeight: "bold" }}>Opened</span>: {moment(inquiry.createdAt).format("MM/DD/YYYY HH:mm")}</p>
                        {/* <p><span style={{ fontWeight: "bold" }}>Submitted By</span>: {inquiry.owner.firstName} {inquiry.owner.lastName}</p> */}
                        <p><span style={{ fontWeight: "bold" }}>Comments</span>: {inquiry.remarks}</p>
                      </FlexItem>
                      <FlexItem>
                        <Flex>
                          {inquiry.luggageTag.checkedLuggage.photoFilePathS3 ? (
                            <FlexItem style={{ objectFit: "cover", width: 128, height: 128, }}>
                              <a href={`${process.env.REACT_APP_S3_URL}/${inquiry.luggageTag.checkedLuggage.photoFilePathS3}`} target="_blank" rel="noreferrer">
                                <img src={`${process.env.REACT_APP_S3_URL}/${inquiry.luggageTag.checkedLuggage.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", }} />
                              </a>
                            </FlexItem>
                          ) : (
                            <FlexItem>
                              <div style={{ width: 128, height: 128, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f2f2f2", padding: 20, }}>
                                <p>No Image</p>
                              </div>
                            </FlexItem>
                          )}
                        </Flex>
                      </FlexItem>
                    </Flex>
                  </CardBody>
                </Card>

                {inquiry.reviews.map((r, idx) => {
                  if (idx === 0) return null;
                  const previousReview = inquiry.reviews[idx - 1];
                  return (
                    <Flex key={r.guid} direction={{ default: "column" }} style={{ marginBottom: 20, }}>
                      {r.message !== "" && (
                        <FlexItem>
                          <Card>
                            <Flex style={{ padding: 40, gap: 80 }} alignItems={{ default: "alignItemsCenter" }}>
                              <FlexItem>
                                <Flex direction={{ default: "column" }}>
                                  <FlexItem>
                                    <i className={inquiry.submittedBy.guid === r.updatedBy.guid ? "fas fa-user" : "fa-solid fa-headset"} style={{ fontSize: 40, }}></i>
                                  </FlexItem>
                                  <FlexItem>
                                    <span style={{ fontWeight: "bold" }}>{inquiry.submittedBy.guid === r.updatedBy.guid ? "User" : "Agent"}</span>
                                  </FlexItem>
                                </Flex>
                              </FlexItem>
                              <FlexItem style={{ flexGrow: 1, }}>
                                <Flex direction={{ default: "column" }}>
                                  <FlexItem>
                                    <Flex>
                                      <FlexItem grow={{ default: "grow" }}>
                                        {moment(r.createdAt).format("MM/DD/YYYY HH:mm")}
                                      </FlexItem>
                                      <FlexItem>
                                        <Button
                                          variant="plain"
                                          aria-label="Delete"
                                          onClick={() => {
                                            setDeleteReviewModalVisible(true);
                                            setTargetReview(r);
                                          }}
                                        >
                                          <MinusCircleIcon color="red" />
                                        </Button>
                                      </FlexItem>
                                    </Flex>
                                  </FlexItem>
                                  <FlexItem style={{ flexGrow: 1, }}>
                                    {r.message}
                                  </FlexItem>
                                </Flex>
                              </FlexItem>
                            </Flex>
                          </Card>
                        </FlexItem>
                      )}
                      {previousReview !== undefined && previousReview.status !== r.status && (
                        <FlexItem style={{ marginBottom: 20, }}>
                          <Flex justifyContent={{ default: "justifyContentCenter" }}>
                            <FlexItem>
                              <p>
                                Ticket Status changed from
                                {" "}
                                {previousReview.status.replace(/_/g, " ")}
                                {" "}
                                to
                                {" "}
                                {r.status.replace(/_/g, " ")}
                              </p>
                            </FlexItem>
                            <FlexItem>
                              <Button
                                variant="plain"
                                aria-label="Delete"
                                onClick={() => {
                                  setDeleteReviewModalVisible(true);
                                  setTargetReview(r);
                                }}
                              >
                                <MinusCircleIcon color="red" />
                              </Button>
                            </FlexItem>
                          </Flex>
                        </FlexItem>
                      )}
                      {previousReview !== undefined && previousReview.state !== r.state && (
                        <FlexItem style={{ marginBottom: 20, }}>
                          <Flex justifyContent={{ default: "justifyContentCenter" }}>
                            <FlexItem>
                              <p>
                                Luggage State changed from
                                {" "}
                                {previousReview.state.replace(/_/g, " ")}
                                {" "}
                                to
                                {" "}
                                {r.state.replace(/_/g, " ")}
                              </p>
                            </FlexItem>
                            <FlexItem>
                              <Button
                                variant="plain"
                                aria-label="Delete"
                                onClick={() => {
                                  setDeleteReviewModalVisible(true);
                                  setTargetReview(r);
                                }}
                              >
                                <MinusCircleIcon color="red" />
                              </Button>
                            </FlexItem>
                          </Flex>
                        </FlexItem>
                      )}
                      {/* TODO perhaps closed should be tied to the reviews and not the ticket itself? */}
                    </Flex>
                  );
                })}
              </>
            )}

            <Flex>
              <FlexItem>
                <Button
                  disabled={updatingInquiry}
                  variant="primary"
                  onClick={() => { setCommentModalOpen(true); }}
                >
                  Add Comment
                </Button>
              </FlexItem>
              <FlexItem>
                <Button
                  disabled={updatingInquiry}
                  variant="primary"
                  onClick={() => { setStatusModalOpen(true); }}
                >
                  Update Inquiry Status
                </Button>
              </FlexItem>
              <FlexItem>
                <Button
                  disabled={updatingInquiry}
                  variant="primary"
                  onClick={() => { setStateModalOpen(true); }}
                >
                  Update Luggage State
                </Button>
              </FlexItem>
              <FlexItem>
                <Button
                  disabled={updatingInquiry}
                  variant={inquiry?.isClosed ? "warning" : "tertiary"}
                  onClick={() => { setClosedModalOpen(true); }}
                >
                  {inquiry?.isClosed ? "Re-open" : "Close"}
                </Button>
              </FlexItem>
            </Flex>

          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
