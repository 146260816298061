import { gql } from "@apollo/client";

export const GET_USERS_QUERY = gql`
query GetUsers(
    $guids: [String], 
    $pageNumber: Int, 
    $resultsPerPage: Int
) {
    users(
        guids: $guids, 
        pageNumber: $pageNumber, 
        resultsPerPage: $resultsPerPage
    ) {
        guid
        retired
        rank {
            name
            service
            grade
        }
        emergencyContact {
            guid
            phone
            firstName
            lastName
        }
    }
}
`;

export const UPDATE_EMERGENCY_CONTACT_MUTATION = gql`
mutation UpdateEmergencyContact($userGuid: String!, $phone: String, $firstName: String, $lastName: String) {
    updateEmergencyContact(userGuid: $userGuid, phone: $phone, firstName: $firstName, lastName: $lastName)
}
`;
