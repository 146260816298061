import { useQuery } from "@apollo/client";
import React from "react";
import { ME_TRAVELER_QUERY } from "../../graphql/queries";
import { GetMeTraveler } from "../../__generated__/api";
import { TravelerContext } from "./TravelerContext";

export interface ITravelerConfiguration {
    children?: React.ReactElement;
}

export const TravelerProvider = ({
    children,

}: ITravelerConfiguration) => {
    const {
        loading,
        error,
        data,
    } = useQuery<GetMeTraveler>(ME_TRAVELER_QUERY);

    return (
        <TravelerContext.Provider
            value={{
                meTraveler: data?.meTraveler,
            }}
        >
            {children}
        </TravelerContext.Provider>
    );
};
