import { useQuery } from "@apollo/client";
import { Select, SelectOption, SelectVariant } from "@patternfly/react-core";
import _ from "lodash";
import React, { useState } from "react";
import { GET_AIRPORTS_QUERY } from "../../graphql/queries";
import { GetAirports, GetAirports_airports } from "../../__generated__/api";

interface Props {
    onSelect: (target?: GetAirports_airports) => void;
    selectedGuid?: string;
}

export default function AirportSelector(props: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [airports, setAirports] = useState<GetAirports_airports[]>([]);

    useQuery<GetAirports>(
        GET_AIRPORTS_QUERY,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            pollInterval: 15000,
            onCompleted: (resp) => {
                setAirports(resp.airports);
            },
        }
    );

    const getAirportByGuid = (guid: string) => airports.find((lt) => lt.guid === guid);

    const airportDropdownValues = () => _.sortBy(
        [
            ...airports.map((airport) => ({
                id: airport.guid,
                value: airport.guid,
                title: `${airport.name} (${airport.code})`,
            })),
        ],
        ["title"]
    );
    return (
        <Select
            placeholderText="Select or type airport's name"
            variant={SelectVariant.typeahead}
            typeAheadAriaLabel="Select an airport"
            onToggle={(expanded) => { setOpen(expanded); }}
            onSelect={(event, selection, isPlaceholder) => {
                if (isPlaceholder) {
                    props.onSelect(undefined);
                    return;
                }
                props.onSelect(getAirportByGuid(selection.toString()));
                setOpen(false);
            }}
            onClear={() => {
                props.onSelect(undefined);
            }}
            selections={props.selectedGuid}
            isOpen={open}
            shouldResetOnSelect
        >
            {airportDropdownValues().map((obj) => (
                <SelectOption
                    key={obj.id}
                    value={obj.value}
                >
                    {obj.title}
                </SelectOption>
            ))}
        </Select>
    );
}
