import { createContext } from "react";
import { GetMeTraveler_meTraveler } from "../../__generated__/api";


export interface ITravelerContext {
    meTraveler?: GetMeTraveler_meTraveler | null
}


export const TravelerContext = createContext<ITravelerContext>({
    meTraveler: null,
});
