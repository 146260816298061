import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  DatePicker,
  Flex,
  FlexItem, Form, FormGroup, Grid,
  GridItem,
  List, ListItem, PageSection,
  Select,
  SelectOption,
  Spinner,
  TextInput,
  TimePicker
} from "@patternfly/react-core";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { DELETE_FLIGHT_QUERY, GET_AIRCRAFTS_QUERY, GET_AIRPORTS_QUERY, GET_FLIGHTS_QUERY, UPDATE_FLIGHT_QUERY } from "../graphql/queries";
import { DeleteFlight, GetAircrafts, GetAircrafts_aircrafts, GetAirports, GetAirports_airports, GetFlights, GetFlights_flights, UpdateFlight } from "../__generated__/api";

interface Props {
  missionGuid: string | undefined;
  flightGuid: string | undefined;
}

export default function Flight(props: Props) {
  const {
    missionGuid,
    flightGuid,
  } = props;

  const [flight, setFlight] = useState<GetFlights_flights | undefined>(undefined);
  const [aircrafts, setAircrafts] = useState<GetAircrafts_aircrafts[]>([]);
  const [airports, setAirports] = useState<GetAirports_airports[]>([]);

  const [aircraftDropdownOpen, setAircraftDropdownOpen] = useState<boolean>(false);
  const [departureAirportDropdownOpen, setDepartureAirportDropdownOpen] = useState<boolean>(false);
  const [arrivalAirportDropdownOpen, setArrivalAirportDropdownOpen] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);

  const [newSpaceAvailable, setNewSpaceAvailable] = useState<number | undefined>(undefined);
  const [newAircraftGuid, setNewAircraftGuid] = useState<string | undefined>(undefined);
  const [newDepartureSchedule, setNewDepartureSchedule] = useState<Date | undefined>(undefined);
  const [newDepartureActual, setNewDepartureActual] = useState<Date | undefined>(undefined);
  const [newDepartureAirportGuid, setNewDepartureAirportGuid] = useState<string | undefined>(undefined);
  const [newArrivalSchedule, setNewArrivalSchedule] = useState<Date | undefined>(undefined);
  const [newArrivalActual, setNewArrivalActual] = useState<Date | undefined>(undefined);
  const [newArrivalAirportGuid, setNewArrivalAirportGuid] = useState<string | undefined>(undefined);
  const [newPublishTime, setNewPublishTime] = useState<Date | undefined>(undefined);
  const [newRollCallTime, setNewRollCallTime] = useState<Date | undefined>(undefined);


  const { loading: fetchingFlight, refetch: refetchFlight, } = useQuery<GetFlights>(
    GET_FLIGHTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        guids: [flightGuid],
      },
      onCompleted: (resp) => {
        setFlight(resp.flights[0]);
      },
    }
  );
  const [deleteFlight, { loading: deletingFlight, }] = useMutation<DeleteFlight>(DELETE_FLIGHT_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateFlight, { loading: updatingFlight, }] = useMutation<UpdateFlight>(UPDATE_FLIGHT_QUERY, { notifyOnNetworkStatusChange: true, });
  const { loading: fetchingAircrafts, refetch: refetchAircrafts, } = useQuery<GetAircrafts>(
    GET_AIRCRAFTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (resp) => {
        setAircrafts(resp.aircrafts);
      },
    }
  );
  const { loading: fetchingAirports, refetch: refetchAirports, } = useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (resp) => {
        setAirports(resp.airports);
      },
    }
  );

  const resetForm = () => {
    if (flight === undefined) return;
    setNewSpaceAvailable(flight.spaceAvailable);
    setNewAircraftGuid(flight.aircraft.guid);
    setNewDepartureSchedule(moment(flight.departureSchedule).toDate());
    setNewDepartureActual(flight.departureActual === null ? undefined : moment(flight.departureActual).toDate());
    setNewDepartureAirportGuid(flight.departureAirport.guid);
    setNewArrivalSchedule(moment(flight.arrivalSchedule).toDate());
    setNewArrivalActual(flight.arrivalActual === null ? undefined : moment(flight.arrivalActual).toDate());
    setNewArrivalAirportGuid(flight.arrivalAirport.guid);
    setNewPublishTime(flight.publishTime === null ? undefined : moment(flight.publishTime).toDate());
    setNewRollCallTime(flight.rollCallTime === null ? undefined : moment(flight.rollCallTime).toDate());
  };

  useEffect(() => {
    if (flight !== undefined) {
      resetForm();
    }
  }, [flight]);

  const doDeleteFlight = async () => {
    try {
      await deleteFlight({
        variables: {
          guid: flightGuid,
        }
      });
      navigate(`/missions/${missionGuid}`);
    } catch (err) {
      console.log("Failed to get delete Flight");
      console.log(JSON.stringify(err, null, 2));
    }
  };
  const doUpdateFlight = async () => {
    try {
      await updateFlight({
        variables: {
          guid: flightGuid,

          aircraftGuid: newAircraftGuid ?? undefined,

          spaceAvailable: newSpaceAvailable ?? undefined,

          departureSchedule: newDepartureSchedule?.toISOString() ?? undefined,
          departureActual: newDepartureActual?.toISOString() ?? undefined,
          departureAirportGuid: newDepartureAirportGuid ?? undefined,

          arrivalSchedule: newArrivalSchedule?.toISOString() ?? undefined,
          arrivalActual: newArrivalActual?.toISOString() ?? undefined,
          arrivalAirportGuid: newArrivalAirportGuid ?? undefined,

          publishTime: newPublishTime?.toISOString() ?? undefined,
          rollCallTime: newRollCallTime?.toISOString() ?? undefined,
        }
      });
      await refetchFlight();
    } catch (err) {
      console.log("Failed to get update Flight");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const aircraftAsString = (aircraft: GetAircrafts_aircrafts) => `${aircraft?.manufacturer.name} ${aircraft?.designation} ${aircraft?.name}`;

  return (
    <PageContainer>
      <PageSection>
        <ConfirmationModal
          title="Delete Flight"
          contents={(
            <>
              <p>
                Are you sure you want to delete this flight and all associated data?
              </p>
              <p>
                Warning: this action can not be undone.
              </p>
            </>
          )}
          onClose={(): void => {
            setDeleteModalVisible(false);
          }}
          onConfirm={(): void => {
            setDeleteModalVisible(false);
            doDeleteFlight();
          }}
          visible={deleteModalVisible}
        />
        <ConfirmationModal
          title="Confirm Flight Changes"
          contents={(
            <>
              <p style={{ marginBottom: 10, }}>
                Are you sure you want to save your changes to this flight?
              </p>
              <List>
                {flight?.spaceAvailable !== newSpaceAvailable && (<ListItem><strong>Space Available</strong>: {flight?.spaceAvailable} {"->"} {newSpaceAvailable}</ListItem>)}
                {flight?.aircraft.guid !== newAircraftGuid && (<ListItem><strong>Aircraft</strong>: {aircraftAsString(flight?.aircraft as GetAircrafts_aircrafts)} {"->"} {aircraftAsString(aircrafts.find((a) => a.guid === newAircraftGuid)!)}</ListItem>)}

                {flight?.departureAirport.guid !== newDepartureAirportGuid && (<ListItem><strong>Departure Airport</strong>: {flight?.departureAirport.name} {"->"} {airports.find((a) => a.guid === newDepartureAirportGuid)?.name}</ListItem>)}
                {!moment(flight?.departureSchedule).isSame(moment(newDepartureSchedule), "minute") && (<ListItem><strong>Departure Schedule</strong>: {moment(flight?.departureSchedule).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newDepartureSchedule).format("MM/DD/YYYY HH:mm")}</ListItem>)}
                {flight?.departureActual != newDepartureActual && !moment(flight?.departureActual).isSame(moment(newDepartureActual), "minute") && (<ListItem><strong>Departure Actual</strong>: {!flight?.departureActual ? "None" : moment(flight?.departureActual).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newDepartureSchedule).format("MM/DD/YYYY HH:mm")}</ListItem>)}

                {flight?.arrivalAirport.guid !== newArrivalAirportGuid && (<ListItem><strong>Arrival Airport</strong>: {flight?.arrivalAirport.name} {"->"} {airports.find((a) => a.guid === newArrivalAirportGuid)?.name}</ListItem>)}
                {!moment(flight?.arrivalSchedule).isSame(moment(newArrivalSchedule), "minute") && (<ListItem><strong>Arrival Schedule</strong>: {moment(flight?.arrivalSchedule).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newArrivalSchedule).format("MM/DD/YYYY HH:mm")}</ListItem>)}
                {flight?.arrivalActual != newArrivalActual && !moment(flight?.arrivalActual).isSame(moment(newArrivalActual), "minute") && (<ListItem><strong>Arrival Actual</strong>: {!flight?.arrivalActual ? "None" : moment(flight?.arrivalActual).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newArrivalSchedule).format("MM/DD/YYYY HH:mm")}</ListItem>)}

                {flight?.publishTime != newPublishTime && !moment(flight?.publishTime).isSame(moment(newPublishTime), "minute") && (<ListItem><strong>Publish Time</strong>: {!flight?.publishTime ? "None" : moment(flight?.publishTime).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newPublishTime).format("MM/DD/YYYY HH:mm")}</ListItem>)}
                {flight?.rollCallTime != newRollCallTime && !moment(flight?.rollCallTime).isSame(moment(newRollCallTime), "minute") && (<ListItem><strong>Roll Call Time</strong>: {!flight?.rollCallTime ? "None" : moment(flight?.rollCallTime).format("MM/DD/YYYY HH:mm")} {"->"} {moment(newRollCallTime).format("MM/DD/YYYY HH:mm")}</ListItem>)}
              </List>
            </>
          )}
          onClose={(): void => {
            setUpdateModalVisible(false);
          }}
          onConfirm={(): void => {
            setUpdateModalVisible(false);
            doUpdateFlight();
          }}
          visible={updateModalVisible}
        />

        {fetchingFlight ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {flight === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Flight Not Found"
              >
                <p>Failed to load flight. No such ID exists: {flightGuid}.</p>
              </Alert>
            ) : (
              <>
                <Grid style={{ marginBottom: 40, }}>
                  <GridItem span={12}>
                    <Form>
                      <FormGroup
                        label="Space Available"
                        type="int"
                        fieldId="spaceAvailable"
                      >
                        <TextInput
                          value={isNaN(newSpaceAvailable ?? NaN) ? "" : newSpaceAvailable}
                          onChange={(val) => {
                            const parsed = parseInt(val, 10);
                            setNewSpaceAvailable(parsed);
                          }}
                          aria-label="Space Available Number Input"
                        />
                      </FormGroup>
                      {/* Aircraft */}
                      <FormGroup
                        label="Aircraft"
                        fieldId="aircraft"
                      >
                        <Select
                          isOpen={aircraftDropdownOpen}
                          onToggle={(expanded) => { setAircraftDropdownOpen(expanded); }}
                          selections={newAircraftGuid}
                        >
                          {aircrafts.map((aircraft) => (
                            <SelectOption
                              key={aircraft.guid}
                              value={aircraft.guid}
                              onClick={() => { setNewAircraftGuid(aircraft.guid); }}
                            >
                              {aircraftAsString(aircraft)}
                            </SelectOption>
                          ))}
                        </Select>
                      </FormGroup>
                      {/* Departure Airport */}
                      <FormGroup
                        label="Departure Airport"
                        fieldId="departureAirport"
                      >
                        <Select
                          isOpen={departureAirportDropdownOpen}
                          onToggle={(expanded) => { setDepartureAirportDropdownOpen(expanded); }}
                          selections={newDepartureAirportGuid}
                        >
                          {airports.map((airport) => (
                            <SelectOption
                              key={airport.guid}
                              value={airport.guid}
                              onClick={() => { setNewDepartureAirportGuid(airport.guid); }}
                            >
                              {airport?.code} {airport?.name}
                            </SelectOption>
                          ))}
                        </Select>
                      </FormGroup>
                      {/* Departure Scheduled Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Scheduled Departure Date"
                            fieldId="departureScheduleDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={moment(newDepartureSchedule).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewDepartureSchedule(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Scheduled Departure Time"
                            fieldId="departureScheduleTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={moment(newDepartureSchedule).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newDepartureSchedule);
                                date.set({ hour, minute, });
                                setNewDepartureSchedule(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                      </Flex>
                      {/* Departure Actual Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Actual Departure Date"
                            fieldId="departureActualDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={newDepartureActual === undefined ? undefined : moment(newDepartureActual).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewDepartureActual(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Actual Departure Time"
                            fieldId="departureActualTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={moment(newDepartureActual).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newDepartureActual);
                                date.set({ hour, minute, });
                                setNewDepartureActual(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup fieldId={"departureActualClear"} label="." style={{ color: "transparent" }}>
                            <Button variant="secondary" onClick={() => { setNewDepartureActual(undefined); }}>Clear</Button>
                          </FormGroup>
                        </FlexItem>
                      </Flex>


                      {/* Arrival Airport */}
                      <FormGroup
                        label="Arrival Airport"
                        fieldId="arrivalAirport"
                      >
                        <Select
                          isOpen={arrivalAirportDropdownOpen}
                          onToggle={(expanded) => { setArrivalAirportDropdownOpen(expanded); }}
                          selections={newArrivalAirportGuid}
                        >
                          {airports.map((airport) => (
                            <SelectOption
                              key={airport.guid}
                              value={airport.guid}
                              onClick={() => { setNewArrivalAirportGuid(airport.guid); }}
                            >
                              {airport?.code} {airport?.name}
                            </SelectOption>
                          ))}
                        </Select>
                      </FormGroup>
                      {/* Arrival Scheduled Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Scheduled Arrival Date"
                            fieldId="arrivalScheduleDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={moment(newArrivalSchedule).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewArrivalSchedule(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Scheduled Arrival Time"
                            fieldId="arrivalScheduleTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={moment(newArrivalSchedule).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newArrivalSchedule);
                                date.set({ hour, minute, });
                                setNewArrivalSchedule(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                      </Flex>
                      {/* Arrival Actual Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Actual Arrival Date"
                            fieldId="arrivalActualDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={newArrivalActual === undefined ? undefined : moment(newArrivalActual).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewArrivalActual(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Actual Arrival Time"
                            fieldId="arrivalActualTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={newArrivalActual === undefined ? undefined : moment(newArrivalActual).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newArrivalActual);
                                date.set({ hour, minute, });
                                setNewArrivalActual(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup fieldId={"arrivalActualClear"} label="." style={{ color: "transparent" }}>
                            <Button variant="secondary" onClick={() => { setNewArrivalActual(undefined); }}>Clear</Button>
                          </FormGroup>
                        </FlexItem>
                      </Flex>


                      {/* Publish Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Publish Date"
                            fieldId="publishDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={newPublishTime === undefined ? undefined : moment(newPublishTime).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewPublishTime(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Publish Time"
                            fieldId="publishTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={newPublishTime === undefined ? undefined : moment(newPublishTime).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newPublishTime);
                                date.set({ hour, minute, });
                                setNewPublishTime(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup fieldId={"publishTimeClear"} label="." style={{ color: "transparent" }}>
                            <Button variant="secondary" onClick={() => { setNewPublishTime(undefined); }}>Clear</Button>
                          </FormGroup>
                        </FlexItem>
                      </Flex>


                      {/* Roll Call Date/Time */}
                      <Flex>
                        <FlexItem>
                          <FormGroup
                            label="Roll Call Date"
                            fieldId="rollCallDate"
                          >
                            <DatePicker
                              placeholder="MM/DD/YYYY"
                              dateFormat={(date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`}
                              value={newRollCallTime === undefined ? undefined : moment(newRollCallTime).format("MM/DD/YYYY")}
                              onChange={(str, date) => {
                                setNewRollCallTime(date);
                              }}
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup
                            label="Roll Call Time"
                            fieldId="publishTime"
                          >
                            <TimePicker
                              placeholder="HH:mm"
                              time={newRollCallTime === undefined ? undefined : moment(newRollCallTime).format("HH:mm")}
                              stepMinutes={5}
                              onChange={(_: string, hour?: number, minute?: number): void => {
                                const date = moment(newRollCallTime);
                                date.set({ hour, minute, });
                                setNewRollCallTime(date.toDate());
                              }}
                              is24Hour
                            />
                          </FormGroup>
                        </FlexItem>
                        <FlexItem>
                          <FormGroup fieldId={"publishTimeClear"} label="." style={{ color: "transparent" }}>
                            <Button variant="secondary" onClick={() => { setNewRollCallTime(undefined); }}>Clear</Button>
                          </FormGroup>
                        </FlexItem>
                      </Flex>

                      <Button
                        variant="secondary"
                        onClick={() => { resetForm(); }}
                      >
                        Reset Changes
                      </Button>
                      <Button
                        disabled={updatingFlight}
                        onClick={() => { setUpdateModalVisible(true); }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </GridItem>
                </Grid>

                <Flex style={{ marginTop: 20, }}>
                  <FlexItem>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDeleteModalVisible(true);
                      }}
                      disabled={deletingFlight}
                    >
                      Delete Flight
                    </Button>
                  </FlexItem>
                </Flex>
              </>
            )}
          </>
        )}
      </PageSection>
    </PageContainer>
  );
}
