/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAircraftManufacturers
// ====================================================

export interface GetAircraftManufacturers_aircraftManufacturers {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  name: string;
}

export interface GetAircraftManufacturers {
  aircraftManufacturers: GetAircraftManufacturers_aircraftManufacturers[];
}

export interface GetAircraftManufacturersVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAircrafts
// ====================================================

export interface GetAircrafts_aircrafts_manufacturer {
  guid: string;
  name: string;
}

export interface GetAircrafts_aircrafts {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  name: string | null;
  designation: string;
  manufacturer: GetAircrafts_aircrafts_manufacturer;
}

export interface GetAircrafts {
  aircrafts: GetAircrafts_aircrafts[];
}

export interface GetAircraftsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAircraft
// ====================================================

export interface CreateAircraft {
  createAircraft: string;
}

export interface CreateAircraftVariables {
  name?: string | null;
  designation: string;
  manufacturerGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAircraft
// ====================================================

export interface UpdateAircraft {
  updateAircraft: string;
}

export interface UpdateAircraftVariables {
  guid: string;
  name?: string | null;
  designation?: string | null;
  manufacturerGuid?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAircraft
// ====================================================

export interface DeleteAircraft {
  deleteAircraft: boolean;
}

export interface DeleteAircraftVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAirports
// ====================================================

export interface GetAirports_airports_location {
  lat: number;
  lon: number;
}

export interface GetAirports_airports_address {
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  state: string;
  postCode: string;
}

export interface GetAirports_airports {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  name: string;
  code: string;
  location: GetAirports_airports_location | null;
  address: GetAirports_airports_address;
}

export interface GetAirports {
  airports: GetAirports_airports[];
}

export interface GetAirportsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAirport
// ====================================================

export interface CreateAirport {
  createAirport: string;
}

export interface CreateAirportVariables {
  name: string;
  code: string;
  lat: number;
  lon: number;
  addressLine1: string;
  addressLine2?: string | null;
  addressLine3?: string | null;
  city: string;
  state: string;
  postCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAirport
// ====================================================

export interface UpdateAirport {
  updateAirport: string;
}

export interface UpdateAirportVariables {
  guid: string;
  name?: string | null;
  code?: string | null;
  lat?: number | null;
  lon?: number | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAirport
// ====================================================

export interface DeleteAirport {
  deleteAirport: boolean;
}

export interface DeleteAirportVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerminalData
// ====================================================

export interface GetTerminalData_terminalData {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  hoursOfOperation: string | null;
  amenities: string | null;
  localHotels: string | null;
  carRentals: string | null;
  rail: string | null;
  parkingInfo: string | null;
  moneyWithdrawal: string | null;
}

export interface GetTerminalData {
  terminalData: GetTerminalData_terminalData;
}

export interface GetTerminalDataVariables {
  airportGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTerminalData
// ====================================================

export interface UpdateTerminalData {
  updateTerminalData: boolean;
}

export interface UpdateTerminalDataVariables {
  terminalDataGuid: string;
  hoursOfOperation?: string | null;
  amenities?: string | null;
  parkingInfo?: string | null;
  moneyWithdrawal?: string | null;
  hotels?: string | null;
  carRentals?: string | null;
  railways?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBoardingPasses
// ====================================================

export interface GetBoardingPasses_boardingPasses_owner {
  guid: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  dodId: string;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_mission {
  externalId: string;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_departureAirport_address {
  guid: string;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  state: string;
  postCode: string;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_departureAirport {
  guid: string;
  name: string;
  code: string;
  address: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_departureAirport_address;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_arrivalAirport_address {
  guid: string;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  state: string;
  postCode: string;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_arrivalAirport {
  guid: string;
  name: string;
  code: string;
  address: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_arrivalAirport_address;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight {
  guid: string;
  spaceAvailable: number;
  mission: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_mission;
  departureSchedule: any;
  departureActual: any | null;
  departureAirport: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_departureAirport;
  arrivalSchedule: any;
  arrivalActual: any | null;
  arrivalAirport: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight_arrivalAirport;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_flightStatus {
  guid: string;
  createdAt: any;
  status: FlightStatusType;
  flight: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus_flight;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_travelAuthorization_sponsor {
  guid: string;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest_travelAuthorization {
  guid: string;
  sponsor: GetBoardingPasses_boardingPasses_spaceRequest_travelAuthorization_sponsor;
}

export interface GetBoardingPasses_boardingPasses_spaceRequest {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  flightStatus: GetBoardingPasses_boardingPasses_spaceRequest_flightStatus | null;
  travelAuthorization: GetBoardingPasses_boardingPasses_spaceRequest_travelAuthorization;
}

export interface GetBoardingPasses_boardingPasses_checkedLuggage_luggageTag {
  guid: string;
  createdAt: any;
}

export interface GetBoardingPasses_boardingPasses_checkedLuggage_luggageGroupTag {
  guid: string;
  createdAt: any;
}

export interface GetBoardingPasses_boardingPasses_checkedLuggage {
  guid: string;
  createdAt: any;
  photoFilePathS3: string | null;
  verified: boolean;
  luggageTag: GetBoardingPasses_boardingPasses_checkedLuggage_luggageTag | null;
  luggageGroupTag: GetBoardingPasses_boardingPasses_checkedLuggage_luggageGroupTag | null;
}

export interface GetBoardingPasses_boardingPasses {
  guid: string;
  createdAt: any;
  boarded: boolean;
  owner: GetBoardingPasses_boardingPasses_owner;
  spaceRequest: GetBoardingPasses_boardingPasses_spaceRequest;
  checkedLuggage: GetBoardingPasses_boardingPasses_checkedLuggage[];
}

export interface GetBoardingPasses {
  boardingPasses: GetBoardingPasses_boardingPasses[];
}

export interface GetBoardingPassesVariables {
  guids?: (string | null)[] | null;
  ownerGuid?: string | null;
  spaceRequestGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleBoarded
// ====================================================

export interface ToggleBoarded {
  toggleBoarded: string;
}

export interface ToggleBoardedVariables {
  boardingPassGuid: string;
  state: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLuggageTags
// ====================================================

export interface GetLuggageTags_luggageTags_createdBy {
  guid: string;
}

export interface GetLuggageTags_luggageTags_checkedLuggage_boardingPass_owner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface GetLuggageTags_luggageTags_checkedLuggage_boardingPass {
  owner: GetLuggageTags_luggageTags_checkedLuggage_boardingPass_owner;
}

export interface GetLuggageTags_luggageTags_checkedLuggage {
  guid: string;
  photoFilePathS3: string | null;
  boardingPass: GetLuggageTags_luggageTags_checkedLuggage_boardingPass;
}

export interface GetLuggageTags_luggageTags_locationHistory_updatedBy {
  guid: string;
}

export interface GetLuggageTags_luggageTags_locationHistory_airport {
  guid: string;
  name: string;
  code: string;
}

export interface GetLuggageTags_luggageTags_locationHistory {
  guid: string;
  createdAt: any;
  updatedBy: GetLuggageTags_luggageTags_locationHistory_updatedBy;
  airport: GetLuggageTags_luggageTags_locationHistory_airport;
  state: LuggageCheckState;
}

export interface GetLuggageTags_luggageTags {
  guid: string;
  createdAt: any;
  createdBy: GetLuggageTags_luggageTags_createdBy;
  checkedLuggage: GetLuggageTags_luggageTags_checkedLuggage;
  locationHistory: GetLuggageTags_luggageTags_locationHistory[];
}

export interface GetLuggageTags {
  luggageTags: GetLuggageTags_luggageTags[];
}

export interface GetLuggageTagsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLuggageTag
// ====================================================

export interface CreateLuggageTag {
  createLuggageTag: string;
}

export interface CreateLuggageTagVariables {
  creatingUserGuid: string;
  luggageGuid: string;
  currentAirportGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLuggageTag
// ====================================================

export interface UpdateLuggageTag {
  updateLuggageTag: boolean;
}

export interface UpdateLuggageTagVariables {
  tagGuid: string;
  updatingUserGuid: string;
  currentAirportGuid: string;
  newState: LuggageCheckState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLuggageGroupTags
// ====================================================

export interface GetLuggageGroupTags_luggageGroupTags_createdBy {
  guid: string;
}

export interface GetLuggageGroupTags_luggageGroupTags_checkedLuggage_boardingPass_owner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface GetLuggageGroupTags_luggageGroupTags_checkedLuggage_boardingPass {
  owner: GetLuggageGroupTags_luggageGroupTags_checkedLuggage_boardingPass_owner;
}

export interface GetLuggageGroupTags_luggageGroupTags_checkedLuggage {
  guid: string;
  photoFilePathS3: string | null;
  boardingPass: GetLuggageGroupTags_luggageGroupTags_checkedLuggage_boardingPass;
}

export interface GetLuggageGroupTags_luggageGroupTags_locationHistory_updatedBy {
  guid: string;
}

export interface GetLuggageGroupTags_luggageGroupTags_locationHistory_airport {
  guid: string;
  name: string;
  code: string;
}

export interface GetLuggageGroupTags_luggageGroupTags_locationHistory {
  guid: string;
  createdAt: any;
  updatedBy: GetLuggageGroupTags_luggageGroupTags_locationHistory_updatedBy;
  airport: GetLuggageGroupTags_luggageGroupTags_locationHistory_airport;
  state: LuggageCheckState;
}

export interface GetLuggageGroupTags_luggageGroupTags {
  guid: string;
  createdAt: any;
  createdBy: GetLuggageGroupTags_luggageGroupTags_createdBy;
  checkedLuggage: GetLuggageGroupTags_luggageGroupTags_checkedLuggage[];
  locationHistory: GetLuggageGroupTags_luggageGroupTags_locationHistory[];
}

export interface GetLuggageGroupTags {
  luggageGroupTags: GetLuggageGroupTags_luggageGroupTags[];
}

export interface GetLuggageGroupTagsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLuggageGroupTag
// ====================================================

export interface CreateLuggageGroupTag {
  createLuggageGroupTag: string;
}

export interface CreateLuggageGroupTagVariables {
  creatingUserGuid: string;
  luggageGuids: (string | null)[];
  currentAirportGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLuggageGroupTag
// ====================================================

export interface UpdateLuggageGroupTag {
  updateLuggageGroupTag: boolean;
}

export interface UpdateLuggageGroupTagVariables {
  tagGuid: string;
  updatingUserGuid: string;
  currentAirportGuid: string;
  newState: LuggageCheckState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLuggageGroupTag
// ====================================================

export interface DeleteLuggageGroupTag {
  deleteLuggageGroupTag: boolean;
}

export interface DeleteLuggageGroupTagVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLuggagePallets
// ====================================================

export interface GetLuggagePallets_luggagePallets_createdBy {
  guid: string;
}

export interface GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage_boardingPass_owner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage_boardingPass {
  owner: GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage_boardingPass_owner;
}

export interface GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage {
  guid: string;
  photoFilePathS3: string | null;
  boardingPass: GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage_boardingPass;
}

export interface GetLuggagePallets_luggagePallets_luggageTags {
  guid: string;
  checkedLuggage: GetLuggagePallets_luggagePallets_luggageTags_checkedLuggage;
}

export interface GetLuggagePallets_luggagePallets_locationHistory_updatedBy {
  guid: string;
}

export interface GetLuggagePallets_luggagePallets_locationHistory_airport {
  guid: string;
  name: string;
  code: string;
}

export interface GetLuggagePallets_luggagePallets_locationHistory {
  guid: string;
  createdAt: any;
  updatedBy: GetLuggagePallets_luggagePallets_locationHistory_updatedBy;
  airport: GetLuggagePallets_luggagePallets_locationHistory_airport;
  state: LuggageCheckState;
}

export interface GetLuggagePallets_luggagePallets {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  createdBy: GetLuggagePallets_luggagePallets_createdBy;
  luggageTags: GetLuggagePallets_luggagePallets_luggageTags[];
  locationHistory: GetLuggagePallets_luggagePallets_locationHistory[];
}

export interface GetLuggagePallets {
  luggagePallets: GetLuggagePallets_luggagePallets[];
}

export interface GetLuggagePalletsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLuggagePallet
// ====================================================

export interface CreateLuggagePallet {
  createLuggagePallet: string;
}

export interface CreateLuggagePalletVariables {
  creatingUserGuid: string;
  currentAirportGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLuggagePallet
// ====================================================

export interface DeleteLuggagePallet {
  deleteLuggagePallet: boolean;
}

export interface DeleteLuggagePalletVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLuggagePallet
// ====================================================

export interface UpdateLuggagePallet {
  updateLuggagePallet: boolean;
}

export interface UpdateLuggagePalletVariables {
  palletGuid: string;
  updatingUserGuid: string;
  currentAirportGuid: string;
  newState: LuggageCheckState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddLuggageToPallet
// ====================================================

export interface AddLuggageToPallet {
  addLuggageToPallet: boolean;
}

export interface AddLuggageToPalletVariables {
  palletGuid: string;
  luggageTagGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveLuggageFromPallet
// ====================================================

export interface RemoveLuggageFromPallet {
  removeLuggageFromPallet: boolean;
}

export interface RemoveLuggageFromPalletVariables {
  palletGuid: string;
  luggageTagGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLuggage
// ====================================================

export interface GetLuggage_luggage_boardingPass_owner_sponsor {
  guid: string;
}

export interface GetLuggage_luggage_boardingPass_owner {
  guid: string;
  sponsor: GetLuggage_luggage_boardingPass_owner_sponsor | null;
}

export interface GetLuggage_luggage_boardingPass {
  guid: string;
  owner: GetLuggage_luggage_boardingPass_owner;
}

export interface GetLuggage_luggage_luggageTag_locationHistory_airport {
  guid: string;
  code: string;
  name: string;
}

export interface GetLuggage_luggage_luggageTag_locationHistory {
  guid: string;
  createdAt: any;
  airport: GetLuggage_luggage_luggageTag_locationHistory_airport;
  state: LuggageCheckState;
}

export interface GetLuggage_luggage_luggageTag {
  guid: string;
  createdAt: any;
  locationHistory: GetLuggage_luggage_luggageTag_locationHistory[];
}

export interface GetLuggage_luggage_luggageGroupTag_locationHistory_airport {
  guid: string;
  code: string;
  name: string;
}

export interface GetLuggage_luggage_luggageGroupTag_locationHistory {
  guid: string;
  createdAt: any;
  airport: GetLuggage_luggage_luggageGroupTag_locationHistory_airport;
  state: LuggageCheckState;
}

export interface GetLuggage_luggage_luggageGroupTag {
  guid: string;
  createdAt: any;
  locationHistory: GetLuggage_luggage_luggageGroupTag_locationHistory[];
}

export interface GetLuggage_luggage {
  guid: string;
  createdAt: any;
  weightInPounds: number;
  photoFilePathS3: string | null;
  verified: boolean;
  verifiedAt: any | null;
  boardingPass: GetLuggage_luggage_boardingPass;
  luggageTag: GetLuggage_luggage_luggageTag | null;
  luggageGroupTag: GetLuggage_luggage_luggageGroupTag | null;
}

export interface GetLuggage {
  luggage: GetLuggage_luggage[];
}

export interface GetLuggageVariables {
  guids?: (string | null)[] | null;
  owningTravelerGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleCheckedLuggageVerified
// ====================================================

export interface ToggleCheckedLuggageVerified {
  toggleCheckedLuggageVerified: boolean;
}

export interface ToggleCheckedLuggageVerifiedVariables {
  guid: string;
  state: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCheckedLuggage
// ====================================================

export interface CreateCheckedLuggage {
  createCheckedLuggage: string;
}

export interface CreateCheckedLuggageVariables {
  boardingPassGuid: string;
  weightInPounds?: number | null;
  image?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCheckedLuggage
// ====================================================

export interface DeleteCheckedLuggage {
  deleteCheckedLuggage: boolean;
}

export interface DeleteCheckedLuggageVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCheckedLuggagePhoto
// ====================================================

export interface SetCheckedLuggagePhoto {
  setCheckedLuggagePhoto: boolean;
}

export interface SetCheckedLuggagePhotoVariables {
  guid: string;
  file?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetDatabase
// ====================================================

export interface ResetDatabase {
  resetDatabase: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFlightCheckIns
// ====================================================

export interface GetFlightCheckIns_flightCheckIns_boardingPass_owner_sponsor_rank {
  service: Service;
  name: string;
  grade: string;
}

export interface GetFlightCheckIns_flightCheckIns_boardingPass_owner_sponsor {
  guid: string;
  rank: GetFlightCheckIns_flightCheckIns_boardingPass_owner_sponsor_rank | null;
  retired: boolean | null;
}

export interface GetFlightCheckIns_flightCheckIns_boardingPass_owner {
  guid: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  dodId: string;
  sponsor: GetFlightCheckIns_flightCheckIns_boardingPass_owner_sponsor | null;
}

export interface GetFlightCheckIns_flightCheckIns_boardingPass {
  guid: string;
  boarded: boolean;
  owner: GetFlightCheckIns_flightCheckIns_boardingPass_owner;
}

export interface GetFlightCheckIns_flightCheckIns {
  guid: string;
  createdAt: any;
  boardingPass: GetFlightCheckIns_flightCheckIns_boardingPass | null;
  travelerWeight: number;
  carryOnWeight: number;
}

export interface GetFlightCheckIns {
  flightCheckIns: GetFlightCheckIns_flightCheckIns[];
}

export interface GetFlightCheckInsVariables {
  guids?: (string | null)[] | null;
  boardingPassGuid?: string | null;
  spaceRequestGuid?: string | null;
  flightGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFlightCheckIn
// ====================================================

export interface CreateFlightCheckIn {
  createFlightCheckIn: string;
}

export interface CreateFlightCheckInVariables {
  boardingPassGuid: string;
  travelerWeight: number;
  carryOnWeight: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFlightCheckIn
// ====================================================

export interface DeleteFlightCheckIn {
  deleteFlightCheckIn: boolean;
}

export interface DeleteFlightCheckInVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFlights
// ====================================================

export interface GetFlights_flights_mission {
  guid: string;
  externalId: string;
  description: string | null;
}

export interface GetFlights_flights_aircraft_manufacturer {
  name: string;
}

export interface GetFlights_flights_aircraft {
  guid: string;
  name: string | null;
  designation: string;
  manufacturer: GetFlights_flights_aircraft_manufacturer;
}

export interface GetFlights_flights_departureAirport {
  guid: string;
  name: string;
  code: string;
}

export interface GetFlights_flights_arrivalAirport {
  guid: string;
  name: string;
  code: string;
}

export interface GetFlights_flights_flightStatuses_spaceRequest_travelAuthorization_travelers {
  guid: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  sponsorRelationship: SponsorRelationship;
}

export interface GetFlights_flights_flightStatuses_spaceRequest_travelAuthorization {
  guid: string;
  category: TravelAuthorizationCategory;
  travelers: GetFlights_flights_flightStatuses_spaceRequest_travelAuthorization_travelers[];
}

export interface GetFlights_flights_flightStatuses_spaceRequest {
  guid: string;
  createdAt: any;
  travelAuthorization: GetFlights_flights_flightStatuses_spaceRequest_travelAuthorization;
}

export interface GetFlights_flights_flightStatuses {
  guid: string;
  createdAt: any;
  status: FlightStatusType;
  spaceRequest: GetFlights_flights_flightStatuses_spaceRequest;
}

export interface GetFlights_flights {
  guid: string;
  spaceAvailable: number;
  mission: GetFlights_flights_mission;
  aircraft: GetFlights_flights_aircraft;
  departureSchedule: any;
  departureActual: any | null;
  departureAirport: GetFlights_flights_departureAirport;
  arrivalSchedule: any;
  arrivalActual: any | null;
  arrivalAirport: GetFlights_flights_arrivalAirport;
  publishTime: any | null;
  rollCallTime: any | null;
  hasRollCallStarted: boolean;
  flightStatuses: GetFlights_flights_flightStatuses[];
}

export interface GetFlights {
  flights: GetFlights_flights[];
}

export interface GetFlightsVariables {
  guids?: (string | null)[] | null;
  departureAirportGuid?: string | null;
  arrivalAirportGuid?: string | null;
  departureDate?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFlightBacklogs
// ====================================================

export interface GetFlightBacklogs_flightBacklogs {
  /**
   * GUID of the Flight
   */
  guid: string;
  /**
   * Total # of seats available initially
   */
  totalAvailableSeats: number;
  /**
   * Total # of travelers in queue
   */
  queueSize: number;
  /**
   * Current position in queue (start of range of travelers)
   */
  positionInQueueStart: number | null;
  /**
   * Current position in queue (end of range of travelers)
   */
  positionInQueueEnd: number | null;
}

export interface GetFlightBacklogs {
  flightBacklogs: GetFlightBacklogs_flightBacklogs[];
}

export interface GetFlightBacklogsVariables {
  guids: (string | null)[];
  sponsorGuid?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFlight
// ====================================================

export interface CreateFlight {
  createFlight: string;
}

export interface CreateFlightVariables {
  missionGuid: string;
  aircraftGuid: string;
  spaceAvailable: number;
  departureSchedule: any;
  departureAirportGuid: string;
  arrivalSchedule: any;
  arrivalAirportGuid: string;
  publishTime?: any | null;
  rollCallTime?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFlight
// ====================================================

export interface UpdateFlight {
  updateFlight: string;
}

export interface UpdateFlightVariables {
  guid: string;
  aircraftGuid?: string | null;
  spaceAvailable?: number | null;
  departureSchedule?: any | null;
  departureActual?: any | null;
  departureAirportGuid?: string | null;
  arrivalSchedule?: any | null;
  arrivalActual?: any | null;
  arrivalAirportGuid?: string | null;
  publishTime?: any | null;
  rollCallTime?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFlight
// ====================================================

export interface DeleteFlight {
  deleteFlight: boolean;
}

export interface DeleteFlightVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFlightRollCall
// ====================================================

export interface ToggleFlightRollCall {
  toggleFlightRollCall: boolean;
}

export interface ToggleFlightRollCallVariables {
  guid: string;
  state: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFlightManifest
// ====================================================

export interface GetFlightManifest_flightManifest_rows {
  /**
   * GUID of the passenger
   */
  travelerGuid: string;
  /**
   * First Name of the passenger
   */
  firstName: string;
  /**
   * Middle Name of the passenger
   */
  middleName: string;
  /**
   * Last Name of the passenger
   */
  lastName: string;
  /**
   * Rank of the passenger, if applicable
   */
  rank: string | null;
  /**
   * Service ID of the passenger, if applicable
   */
  dodId: string;
  /**
   * The service branch of the passenger, if applicable
   */
  service: string | null;
  /**
   * Whether the passenger is retired, if applicable
   */
  retired: string | null;
  /**
   * The passenger's total number of checked bags
   */
  checkedBagsCount: number;
  /**
   * The total weight of the passenger's checked bags
   */
  checkedBagsWeight: number;
  /**
   * The weight of the passenger
   */
  passengerWeight: number;
  /**
   * The weight of the passenger's carry-on
   */
  carryOnWeight: number;
  /**
   * The name of the passenger's Emergency Point of Contact
   */
  epocName: string | null;
  /**
   * The phone number of the passenger's Emergency Point of Contact
   */
  epocPhone: string | null;
}

export interface GetFlightManifest_flightManifest {
  /**
   * Test
   */
  rows: GetFlightManifest_flightManifest_rows[];
}

export interface GetFlightManifest {
  flightManifest: GetFlightManifest_flightManifest | null;
}

export interface GetFlightManifestVariables {
  flightGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FoundLuggageInquiries
// ====================================================

export interface FoundLuggageInquiries_foundLuggageInquiries_submittedBy {
  guid: string;
}

export interface FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage_boardingPass_owner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage_boardingPass {
  owner: FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage_boardingPass_owner;
}

export interface FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage {
  guid: string;
  createdAt: any;
  boardingPass: FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage_boardingPass;
  photoFilePathS3: string | null;
}

export interface FoundLuggageInquiries_foundLuggageInquiries_luggageTag {
  checkedLuggage: FoundLuggageInquiries_foundLuggageInquiries_luggageTag_checkedLuggage;
}

export interface FoundLuggageInquiries_foundLuggageInquiries_suspectedOwner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface FoundLuggageInquiries_foundLuggageInquiries {
  id: number;
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  submittedBy: FoundLuggageInquiries_foundLuggageInquiries_submittedBy;
  isClosed: boolean;
  photoFilePathS3: string | null;
  luggageTag: FoundLuggageInquiries_foundLuggageInquiries_luggageTag | null;
  suspectedOwner: FoundLuggageInquiries_foundLuggageInquiries_suspectedOwner | null;
  remarks: string;
}

export interface FoundLuggageInquiries {
  foundLuggageInquiries: FoundLuggageInquiries_foundLuggageInquiries[];
}

export interface FoundLuggageInquiriesVariables {
  guids?: (string | null)[] | null;
  owningTravelerGuid?: string | null;
  submittedByGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFoundLuggageInquiry
// ====================================================

export interface CreateFoundLuggageInquiry {
  createFoundLuggageInquiry: string;
}

export interface CreateFoundLuggageInquiryVariables {
  submittingUserguid: string;
  luggageTagGuid?: string | null;
  suspectedOwnerGuid?: string | null;
  remarks?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFoundLuggageInquiry
// ====================================================

export interface ToggleFoundLuggageInquiry {
  toggleFoundLuggageInquiry: boolean;
}

export interface ToggleFoundLuggageInquiryVariables {
  inquiryGuid: string;
  closed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFoundLuggageInquiry
// ====================================================

export interface DeleteFoundLuggageInquiry {
  deleteFoundLuggageInquiry: string;
}

export interface DeleteFoundLuggageInquiryVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LostLuggageInquiries
// ====================================================

export interface LostLuggageInquiries_lostLuggageInquiries_submittedBy {
  guid: string;
}

export interface LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage_boardingPass_owner {
  guid: string;
  firstName: string;
  lastName: string;
}

export interface LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage_boardingPass {
  owner: LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage_boardingPass_owner;
}

export interface LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage {
  boardingPass: LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage_boardingPass;
  photoFilePathS3: string | null;
}

export interface LostLuggageInquiries_lostLuggageInquiries_luggageTag {
  checkedLuggage: LostLuggageInquiries_lostLuggageInquiries_luggageTag_checkedLuggage;
}

export interface LostLuggageInquiries_lostLuggageInquiries_reviews_updatedBy {
  guid: string;
}

export interface LostLuggageInquiries_lostLuggageInquiries_reviews {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  updatedBy: LostLuggageInquiries_lostLuggageInquiries_reviews_updatedBy;
  state: LostLuggageState;
  status: LostLuggageInquiryStatus;
  message: string;
}

export interface LostLuggageInquiries_lostLuggageInquiries {
  id: number;
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  isClosed: boolean;
  submittedBy: LostLuggageInquiries_lostLuggageInquiries_submittedBy;
  luggageTag: LostLuggageInquiries_lostLuggageInquiries_luggageTag;
  remarks: string;
  reviews: LostLuggageInquiries_lostLuggageInquiries_reviews[];
}

export interface LostLuggageInquiries {
  lostLuggageInquiries: LostLuggageInquiries_lostLuggageInquiries[];
}

export interface LostLuggageInquiriesVariables {
  guids?: (string | null)[] | null;
  ownerGuid?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLostLuggageInquiry
// ====================================================

export interface UpdateLostLuggageInquiry {
  updateLostLuggageInquiry: boolean;
}

export interface UpdateLostLuggageInquiryVariables {
  inquiryGuid: string;
  state?: LostLuggageState | null;
  status?: LostLuggageInquiryStatus | null;
  message?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleLostLuggageInquiry
// ====================================================

export interface ToggleLostLuggageInquiry {
  toggleLostLuggageInquiry: boolean;
}

export interface ToggleLostLuggageInquiryVariables {
  inquiryGuid: string;
  closed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLostLuggageInquiryReview
// ====================================================

export interface DeleteLostLuggageInquiryReview {
  deleteLostLuggageInquiryReview: boolean;
}

export interface DeleteLostLuggageInquiryReviewVariables {
  reviewGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMe
// ====================================================

export interface GetMe_me_rank {
  name: string;
  service: Service;
  grade: string;
}

export interface GetMe_me {
  guid: string;
  retired: boolean | null;
  rank: GetMe_me_rank | null;
}

export interface GetMe {
  me: GetMe_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMeTraveler
// ====================================================

export interface GetMeTraveler_meTraveler {
  guid: string;
  email: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  dodId: string;
}

export interface GetMeTraveler {
  meTraveler: GetMeTraveler_meTraveler | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMissions
// ====================================================

export interface GetMissions_missions_flights_aircraft_manufacturer {
  name: string;
}

export interface GetMissions_missions_flights_aircraft {
  name: string | null;
  designation: string;
  manufacturer: GetMissions_missions_flights_aircraft_manufacturer;
}

export interface GetMissions_missions_flights_departureAirport {
  guid: string;
  name: string;
  code: string;
}

export interface GetMissions_missions_flights_arrivalAirport {
  guid: string;
  name: string;
  code: string;
}

export interface GetMissions_missions_flights {
  guid: string;
  spaceAvailable: number;
  aircraft: GetMissions_missions_flights_aircraft;
  departureSchedule: any;
  departureActual: any | null;
  departureAirport: GetMissions_missions_flights_departureAirport;
  arrivalSchedule: any;
  arrivalActual: any | null;
  arrivalAirport: GetMissions_missions_flights_arrivalAirport;
  rollCallTime: any | null;
}

export interface GetMissions_missions {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  description: string | null;
  externalId: string;
  flights: GetMissions_missions_flights[];
}

export interface GetMissions {
  missions: GetMissions_missions[];
}

export interface GetMissionsVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMission
// ====================================================

export interface CreateMission {
  createMission: string;
}

export interface CreateMissionVariables {
  externalId: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMission
// ====================================================

export interface UpdateMission {
  updateMission: string;
}

export interface UpdateMissionVariables {
  guid: string;
  externalId?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMission
// ====================================================

export interface DeleteMission {
  deleteMission: boolean;
}

export interface DeleteMissionVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegisterForFlight
// ====================================================

export interface RegisterForFlight {
  registerForFlight: string;
}

export interface RegisterForFlightVariables {
  travelAuthGuid: string;
  flightGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnregisterFromFlight
// ====================================================

export interface UnregisterFromFlight {
  unregisterFromFlight: boolean;
}

export interface UnregisterFromFlightVariables {
  travelAuthGuid: string;
  flightGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetRegisteredFlightStatus
// ====================================================

export interface SetRegisteredFlightStatus {
  setRegisteredFlightStatus: boolean;
}

export interface SetRegisteredFlightStatusVariables {
  travelAuthGuid: string;
  flightGuid: string;
  flightStatus: FlightStatusType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpaceRequests
// ====================================================

export interface GetSpaceRequests_spaceRequests_travelAuthorization_travelers {
  guid: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  dodId: string;
  sponsorRelationship: SponsorRelationship;
}

export interface GetSpaceRequests_spaceRequests_travelAuthorization {
  guid: string;
  startDate: any | null;
  endDate: any | null;
  category: TravelAuthorizationCategory;
  travelers: GetSpaceRequests_spaceRequests_travelAuthorization_travelers[];
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight_mission {
  externalId: string;
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight_departureAirport_address {
  guid: string;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  state: string;
  postCode: string;
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight_departureAirport {
  guid: string;
  name: string;
  code: string;
  address: GetSpaceRequests_spaceRequests_flightStatus_flight_departureAirport_address;
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight_arrivalAirport_address {
  guid: string;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  state: string;
  postCode: string;
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight_arrivalAirport {
  guid: string;
  name: string;
  code: string;
  address: GetSpaceRequests_spaceRequests_flightStatus_flight_arrivalAirport_address;
}

export interface GetSpaceRequests_spaceRequests_flightStatus_flight {
  guid: string;
  spaceAvailable: number;
  mission: GetSpaceRequests_spaceRequests_flightStatus_flight_mission;
  departureSchedule: any;
  departureActual: any | null;
  departureAirport: GetSpaceRequests_spaceRequests_flightStatus_flight_departureAirport;
  arrivalSchedule: any;
  arrivalActual: any | null;
  arrivalAirport: GetSpaceRequests_spaceRequests_flightStatus_flight_arrivalAirport;
}

export interface GetSpaceRequests_spaceRequests_flightStatus {
  guid: string;
  createdAt: any;
  status: FlightStatusType;
  flight: GetSpaceRequests_spaceRequests_flightStatus_flight;
}

export interface GetSpaceRequests_spaceRequests {
  guid: string;
  createdAt: any;
  travelAuthorization: GetSpaceRequests_spaceRequests_travelAuthorization;
  flightStatus: GetSpaceRequests_spaceRequests_flightStatus | null;
}

export interface GetSpaceRequests {
  spaceRequests: GetSpaceRequests_spaceRequests[];
}

export interface GetSpaceRequestsVariables {
  guids?: (string | null)[] | null;
  ownerGuid?: string | null;
  flightGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateRollCall
// ====================================================

export interface GenerateRollCall_generateRollCall_travelAuthorization_travelers {
  guid: string;
  firstName: string;
  lastName: string;
  sponsorRelationship: SponsorRelationship;
}

export interface GenerateRollCall_generateRollCall_travelAuthorization {
  guid: string;
  category: TravelAuthorizationCategory;
  travelers: GenerateRollCall_generateRollCall_travelAuthorization_travelers[];
}

export interface GenerateRollCall_generateRollCall {
  guid: string;
  createdAt: any;
  travelAuthorization: GenerateRollCall_generateRollCall_travelAuthorization;
}

export interface GenerateRollCall {
  generateRollCall: GenerateRollCall_generateRollCall[];
}

export interface GenerateRollCallVariables {
  flightGuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTravelAuthorizations
// ====================================================

export interface GetTravelAuthorizations_travelAuthorizations_reviewHistory {
  guid: string;
  createdAt: any;
  status: ReviewStatus;
  message: string | null;
}

export interface GetTravelAuthorizations_travelAuthorizations_travelAuthorizationDocuments_reviewHistory {
  guid: string;
  status: ReviewStatus;
  message: string | null;
}

export interface GetTravelAuthorizations_travelAuthorizations_travelAuthorizationDocuments {
  guid: string;
  fileName: string;
  fileMimeType: string;
  filePathS3: string | null;
  documentId: string | null;
  createdAt: any;
  dateExpires: any | null;
  reviewHistory: GetTravelAuthorizations_travelAuthorizations_travelAuthorizationDocuments_reviewHistory[];
}

export interface GetTravelAuthorizations_travelAuthorizations_sponsor_travelers {
  guid: string;
  email: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  dodId: string;
  sponsorRelationship: SponsorRelationship;
}

export interface GetTravelAuthorizations_travelAuthorizations_sponsor {
  guid: string;
  travelers: GetTravelAuthorizations_travelAuthorizations_sponsor_travelers[];
}

export interface GetTravelAuthorizations_travelAuthorizations_travelers {
  guid: string;
  email: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  dodId: string;
  sponsorRelationship: SponsorRelationship;
}

export interface GetTravelAuthorizations_travelAuthorizations {
  guid: string;
  category: TravelAuthorizationCategory;
  leaveStatusDate: any | null;
  startDate: any | null;
  endDate: any | null;
  reviewHistory: GetTravelAuthorizations_travelAuthorizations_reviewHistory[];
  travelAuthorizationDocuments: GetTravelAuthorizations_travelAuthorizations_travelAuthorizationDocuments[];
  sponsor: GetTravelAuthorizations_travelAuthorizations_sponsor;
  travelers: GetTravelAuthorizations_travelAuthorizations_travelers[];
}

export interface GetTravelAuthorizations {
  travelAuthorizations: GetTravelAuthorizations_travelAuthorizations[];
}

export interface GetTravelAuthorizationsVariables {
  guids?: (string | null)[] | null;
  ownerGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTravelAuthorization
// ====================================================

export interface CreateTravelAuthorization {
  createTravelAuthorization: string;
}

export interface CreateTravelAuthorizationVariables {
  sponsorGuid: string;
  travelerGuids: (string | null)[];
  leaveStatusDate: any;
  startDate: any;
  endDate: any;
  category: TravelAuthorizationCategory;
  forceStatus?: ReviewStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTravelAuthorizationDocument
// ====================================================

export interface CreateTravelAuthorizationDocument {
  createTravelAuthorizationDocument: boolean;
}

export interface CreateTravelAuthorizationDocumentVariables {
  travelAuthorizationGuid: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTravelAuthorizationStatus
// ====================================================

export interface UpdateTravelAuthorizationStatus {
  updateTravelAuthorizationStatus: boolean;
}

export interface UpdateTravelAuthorizationStatusVariables {
  guid: string;
  status: ReviewStatus;
  reviewerGuid?: string | null;
  statusMessage?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTravelers
// ====================================================

export interface GetTravelers_travelers_sponsor {
  guid: string;
}

export interface GetTravelers_travelers_travelerDocuments {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  fileName: string;
  fileMimeType: string;
  filePathS3: string | null;
}

export interface GetTravelers_travelers_accommodations {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  requiresLargerSeat: boolean;
  requiresSeatBeltExtension: boolean;
  requiresWheelchairAccessibleSpace: boolean;
  notes: string;
}

export interface GetTravelers_travelers {
  guid: string;
  createdAt: any;
  lastUpdatedAt: any;
  email: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  dodId: string;
  sponsor: GetTravelers_travelers_sponsor | null;
  sponsorRelationship: SponsorRelationship;
  travelerDocuments: GetTravelers_travelers_travelerDocuments[];
  accommodations: GetTravelers_travelers_accommodations | null;
}

export interface GetTravelers {
  travelers: GetTravelers_travelers[];
}

export interface GetTravelersVariables {
  guids?: (string | null)[] | null;
  sponsorGuid?: string | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTraveler
// ====================================================

export interface CreateTraveler {
  createTraveler: string;
}

export interface CreateTravelerVariables {
  sponsorGuid: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  email: string;
  dodId: string;
  sponsorRelationship: SponsorRelationship;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTraveler
// ====================================================

export interface UpdateTraveler {
  updateTraveler: string;
}

export interface UpdateTravelerVariables {
  guid: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  dodId?: string | null;
  sponsorRelationship?: SponsorRelationship | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTraveler
// ====================================================

export interface DeleteTraveler {
  deleteTraveler: boolean;
}

export interface DeleteTravelerVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTravelerAccommodations
// ====================================================

export interface UpdateTravelerAccommodations {
  updateTravelerAccommodations: boolean;
}

export interface UpdateTravelerAccommodationsVariables {
  travelerGuid: string;
  requiresLargerSeat?: boolean | null;
  requiresSeatBeltExtension?: boolean | null;
  requiresWheelchairAccessibleSpace?: boolean | null;
  notes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTravelerDocument
// ====================================================

export interface CreateTravelerDocument {
  createTravelerDocument: boolean;
}

export interface CreateTravelerDocumentVariables {
  travelerGuid: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTravelerDocument
// ====================================================

export interface DeleteTravelerDocument {
  deleteTravelerDocument: boolean;
}

export interface DeleteTravelerDocumentVariables {
  guid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_users_rank {
  name: string;
  service: Service;
  grade: string;
}

export interface GetUsers_users_emergencyContact {
  guid: string;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetUsers_users {
  guid: string;
  retired: boolean | null;
  rank: GetUsers_users_rank | null;
  emergencyContact: GetUsers_users_emergencyContact | null;
}

export interface GetUsers {
  users: GetUsers_users[];
}

export interface GetUsersVariables {
  guids?: (string | null)[] | null;
  pageNumber?: number | null;
  resultsPerPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmergencyContact
// ====================================================

export interface UpdateEmergencyContact {
  updateEmergencyContact: boolean;
}

export interface UpdateEmergencyContactVariables {
  userGuid: string;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FlightStatusType {
  BACKLOG = "BACKLOG",
  ON_BOARD = "ON_BOARD",
  ROLL_CALL = "ROLL_CALL",
  ROSTER = "ROSTER",
}

export enum LostLuggageInquiryStatus {
  AWAITING_AGENT_RESPONSE = "AWAITING_AGENT_RESPONSE",
  AWAITING_PASSENGER_RESPONSE = "AWAITING_PASSENGER_RESPONSE",
}

export enum LostLuggageState {
  COURTESY = "COURTESY",
  DAMAGED = "DAMAGED",
  FOUND = "FOUND",
  LOST = "LOST",
  PILFERED = "PILFERED",
}

export enum LuggageCheckState {
  BOARDED = "BOARDED",
  CHECKED_IN = "CHECKED_IN",
  UNBOARDED = "UNBOARDED",
}

export enum ReviewStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  SUBMITTED = "SUBMITTED",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export enum Service {
  AIR_FORCE = "AIR_FORCE",
  ARMY = "ARMY",
  COAST_GUARD = "COAST_GUARD",
  NAVY = "NAVY",
  OTHER = "OTHER",
  SPACE_FORCE = "SPACE_FORCE",
}

export enum SponsorRelationship {
  CHILD = "CHILD",
  PARENT = "PARENT",
  SELF = "SELF",
  SIBLING = "SIBLING",
  SPOUSE = "SPOUSE",
}

export enum TravelAuthorizationCategory {
  CATEGORY_I = "CATEGORY_I",
  CATEGORY_II = "CATEGORY_II",
  CATEGORY_III = "CATEGORY_III",
  CATEGORY_IV = "CATEGORY_IV",
  CATEGORY_V = "CATEGORY_V",
  CATEGORY_VI = "CATEGORY_VI",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
