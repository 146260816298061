import { Card, Flex, FlexItem, Avatar, Title, TitleSizes, Text, Tooltip } from "@patternfly/react-core";
import { CheckCircleIcon } from "@patternfly/react-icons";
import { titleize } from "../../utils/string-utils";
import { GetTravelers_travelers } from "../../__generated__/api";
import avatarImg from "../../assets/avatar.svg";

interface Props {
  traveler: GetTravelers_travelers;

  onClick?: () => void;

  selected?: boolean;
}

export default (props: Props) => {
  const {
    traveler,
    onClick,
    selected,
  } = props;
  return (
    <Card
      style={{ position: "relative", width: "180px", padding: 20, userSelect: "none", cursor: "pointer", }}
      onClick={() => {
        if (onClick === undefined) return;
        onClick();
      }}
    >
      {!!selected && (
        <CheckCircleIcon style={{ position: "absolute", top: -4, right: -4, }} size="md" color="#00AF00" />
      )}
      <Flex direction={{ default: "column" }}>
        <FlexItem>
          <Avatar src={avatarImg} alt="avatar" size="xl" style={{}} />
        </FlexItem>
        <FlexItem>
          <Tooltip content={`${traveler.firstName} ${traveler.middleName} ${traveler.lastName}`}>
            <Title headingLevel="h4" size={TitleSizes["xl"]} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center", }}>
              {traveler.firstName} {traveler.middleName?.substring(0, -1)} {traveler.lastName}
            </Title>
          </Tooltip>
        </FlexItem>
        <FlexItem>
          <Text style={{ textAlign: "center" }}>{titleize(traveler.sponsorRelationship)}</Text>
        </FlexItem>
      </Flex>
    </Card>
  );
}