import { Button, Modal } from "@patternfly/react-core";

interface ConfirmationModalProps {
    title: string;
    contents: string | JSX.Element | JSX.Element[];

    visible?: boolean;
    fullWidth?: boolean;

    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
    return (
        <Modal
            width="50%"
            aria-label={props.title}
            title={props.title}
            isOpen={props.visible}
            onClose={props.onClose}
            actions={[
                <Button key="confirm" variant="primary" onClick={props.onConfirm}>
                    Confirm
                </Button>,
                <Button key="cancel" variant="link" onClick={props.onClose}>
                    Cancel
                </Button>,
            ]}
        >
            {props.contents}
        </Modal>
    );
};