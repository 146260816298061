import { gql } from "@apollo/client";

export const ME_QUERY = gql`
query GetMe {
  me {
    guid
    retired
    rank {
      name
      service
      grade
    }
  }
}
`;

export const ME_TRAVELER_QUERY = gql`
query GetMeTraveler {
  meTraveler {
    guid
    email
    firstName
    middleName
    lastName
    dodId
  }
}
`;
