import { useQuery } from "@apollo/client";
import { Select, SelectOption, SelectVariant } from "@patternfly/react-core";
import _ from "lodash";
import React, { useState } from "react";
import { GET_TRAVELERS_QUERY } from "../../graphql/queries";
import { GetTravelers, GetTravelers_travelers } from "../../__generated__/api";

interface Props {
    onSelect: (target?: GetTravelers_travelers) => void;
    selectedGuid?: string;
}

export default function TravelerSelector(props: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [travelers, setTravelers] = useState<GetTravelers_travelers[]>([]);

    useQuery<GetTravelers>(
        GET_TRAVELERS_QUERY,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            pollInterval: 15000,
            onCompleted: (resp) => {
                setTravelers(resp.travelers);
            },
        }
    );

    const getTravelerByGuid = (guid: string) => travelers.find((lt) => lt.guid === guid);

    const travelerDropdownValues = () => _.sortBy(
        [
            ...travelers.map((traveler) => ({
                id: traveler.guid,
                value: traveler.guid,
                title: `${traveler.firstName} ${traveler.middleName} ${traveler.lastName} (ID: ${traveler.dodId})`,
            })),
        ],
        ["title"]
    );
    return (
        <Select
            placeholderText="Select or type traveler's name"
            variant={SelectVariant.typeahead}
            typeAheadAriaLabel="Select an traveler"
            onToggle={(expanded) => { setOpen(expanded); }}
            onSelect={(event, selection, isPlaceholder) => {
                if (isPlaceholder) {
                    props.onSelect(undefined);
                    return;
                }
                props.onSelect(getTravelerByGuid(selection.toString()));
                setOpen(false);
            }}
            onClear={() => {
                props.onSelect(undefined);
            }}
            selections={props.selectedGuid}
            isOpen={open}
            shouldResetOnSelect
        >
            {travelerDropdownValues().map((obj) => (
                <SelectOption
                    key={obj.id}
                    value={obj.value}
                >
                    {obj.title}
                </SelectOption>
            ))}
        </Select>
    );
}
