import React, { useState } from "react";
import {
  Page,
  Masthead,
  MastheadToggle,
  MastheadMain,
  MastheadBrand,
  MastheadContent,
  PageSidebar,
  PageSection,
  PageSectionVariants,
  PageToggleButton,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  ToolbarGroup,
  DropdownToggle,
  Avatar,
  Dropdown,
} from "@patternfly/react-core";
import { HeaderToolbar } from "./HeaderToolbar";
import afLogo from "../../assets/af-logo.png";

export interface HeaderProps {
  isNavOpen: boolean,
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const Header: React.FC<HeaderProps> = ({
  setIsNavOpen,
  isNavOpen,
}) => (
  <Masthead style={styles.masthead}>
    {/*<MastheadToggle>
      
      <PageToggleButton
        variant='plain'
        aria-label='Global Navigation'
        isNavOpen
        >
      </PageToggleButton>
    </MastheadToggle>*/}
    <MastheadMain style={styles.mastheadMain}>
        <img style={styles.afLogo} src={afLogo}   />
        <h1>AMC - McGuire AFB</h1>
    </MastheadMain>
    <MastheadContent><HeaderToolbar /></MastheadContent>
  </Masthead>
);

export default Header;

const styles: {
  [key: string]: React.CSSProperties
} = {
  masthead: {
    //color: '#1c2449'
    color: "white",
  },
  mastheadMain: {
    fontFamily: "ethnocentric",
  },
  loginHeaderContainer: {
    textAlign: "center",
  },
  loginHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
  afLogo: {
    maxHeight: 40,
    marginRight: 20,
    marginLeft: 20,
  },
};