import { gql } from "@apollo/client";


export const GET_AIRCRAFT_MANUFACTURERS_QUERY = gql`
query GetAircraftManufacturers($guids: [String], $pageNumber: Int, $resultsPerPage: Int) {
  aircraftManufacturers(guids: $guids, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid
    createdAt
    lastUpdatedAt
    name
  }
}
`;