import { useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Flex, FlexItem, Modal, ModalVariant, Text, Tooltip } from "@patternfly/react-core";
import { CameraIcon } from "@patternfly/react-icons";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../../assets/af-logo-blackonwhite.png";
import { useUser } from "../../contexts";
import { CREATE_LUGGAGE_TAG_QUERY, DELETE_CHECKED_LUGGAGE_QUERY, GET_AIRPORTS_QUERY, GET_LUGGAGE_QUERY, TOGGLE_CHECKED_LUGGAGE_VERIFIED_QUERY } from "../../graphql/queries";
import { CreateLuggageTag, DeleteCheckedLuggage, GetAirports, GetLuggage, GetLuggage_luggage, GetTravelers_travelers, ToggleCheckedLuggageVerified } from "../../__generated__/api";
import HorizontalTable from "../HorizontalTable";


interface Props {
  luggageGuid: string | undefined;

  onClose: () => void;
  onCreated: () => void;
  readonly?: boolean;
}

export default (props: Props) => {

  const {
    luggageGuid,

    onClose,
    onCreated,
    readonly,
  } = props;

  const { me } = useUser();


  const [luggage, setLuggage] = useState<GetLuggage_luggage | undefined>(undefined);
  const [traveler, setTraveler] = useState<GetTravelers_travelers | undefined>(undefined);
  const [airportGuid, setAirportGuid] = useState<string>("");

  const [luggageWeight, setLuggageWeight] = useState<number>(0);

  const luggageQrRef = React.createRef<QRCode>();

  const { loading: fetchingLuggage, refetch: refetchLuggage, } = useQuery<GetLuggage>(
    GET_LUGGAGE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        guids: [luggageGuid],
      },
      onCompleted: async (resp) => {
        const fetchedLuggage = resp.luggage[0];
        setLuggage(fetchedLuggage);
        if (fetchedLuggage !== undefined) {
          setLuggageWeight(fetchedLuggage.weightInPounds);
        }
      },
    }
  );
  useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 15000,
      onCompleted: (resp) => {
        // TODO this is hard-coded now as the first airport,
        // but should be the airport of the owning instance
        // e.g. all terminals at WRI are automatically set to WRI
        setAirportGuid(resp.airports[0].guid);
        console.log(`SET DEFAULT AIRPORT TO ${resp.airports[0].name}`);
      },
    }
  );
  const [deleteCheckedLuggage, { loading: deletingLuggage }] = useMutation<DeleteCheckedLuggage>(DELETE_CHECKED_LUGGAGE_QUERY, { notifyOnNetworkStatusChange: true, });
  const [verifyCheckedLuggage, { loading: verifyingLuggage }] = useMutation<ToggleCheckedLuggageVerified>(TOGGLE_CHECKED_LUGGAGE_VERIFIED_QUERY, { notifyOnNetworkStatusChange: true, });
  const [createLuggageTag, { loading: creatingLuggageTag }] = useMutation<CreateLuggageTag>(CREATE_LUGGAGE_TAG_QUERY, { notifyOnNetworkStatusChange: true, });


  const fileInputRef = React.createRef<HTMLInputElement>();


  const doDeleteLuggage = async () => {
    try {
      await deleteCheckedLuggage({
        variables: {
          guid: luggageGuid,
        },
      });
      onClose();
    } catch (err) {
      console.error("Failed to get delete Checked Luggage");
      console.error(JSON.stringify(err, null, 2));
    }
  };

  const doVerifyLuggage = async (state: boolean) => {
    try {
      await verifyCheckedLuggage({
        variables: {
          guid: luggageGuid,
          state,
        },
      });
      onClose();
      onCreated();
    } catch (err) {
      console.error("Failed to get change verification of luggage");
      console.error(JSON.stringify(err, null, 2));
    }
  };

  const doGenerateLuggageTag = async () => {
    try {
      await createLuggageTag({
        variables: {
          creatingUserGuid: me?.guid,
          luggageGuid,
          currentAirportGuid: airportGuid,
        },
      });
      refetchLuggage();
    } catch (err) {
      console.error("Failed to create luggage tag");
      console.error(JSON.stringify(err, null, 2));
    }
  };


  return (
    <Modal
      aria-label="View Checked Luggage"
      title="View Checked Luggage"
      isOpen={true}
      variant={ModalVariant.medium}
      onClose={() => { onClose(); }}
      actions={[
        <Button key="accept" variant="primary" onClick={() => { onClose(); }}>
          Accept
        </Button>,
        <Button key="cancel" variant="link" onClick={() => { onClose(); }}>
          Close
        </Button>
      ]}
    >
      {luggage === undefined ? (
        <Flex style={{ marginBottom: 40, }}>
          <FlexItem grow={{ default: "grow" }}>
            <Alert
              isInline
              title="Luggage Not Found"
              variant="danger"
            >
              <p>
                No such luggage could be found.
              </p>
            </Alert>
          </FlexItem>
        </Flex>
      ) : (
        <>
          <Flex style={{ marginBottom: 40, }} >
            <FlexItem grow={{ default: "grow" }}>
              {luggage?.verified ? (
                (luggage.luggageTag || luggage.luggageGroupTag ? (
                  <Alert
                    variant="success"
                    isInline
                    title="Luggage Verified"
                  >
                    <p>
                      This luggage has been registered, verified, and has a tracking tag.
                    </p>

                    <Flex>
                      <FlexItem>
                        <Button
                          isDisabled={readonly}
                          variant="danger"
                          onClick={() => {
                            if (confirm("Are you sure you want to unverify this luggage? All tracking information will be lost.")) {
                              doVerifyLuggage(false);
                            }
                          }}
                          style={{ marginTop: 20, }}
                        >
                          Unverify Luggage
                        </Button>
                      </FlexItem>
                    </Flex>
                  </Alert>
                ) : (
                  <Alert
                    variant="warning"
                    isInline
                    title="Luggage Verified, Missing Tag"
                  >
                    <p>
                      This luggage has been registered and verified, but is missing a tracking tag.
                    </p>

                    <Flex>
                      <FlexItem>
                        <Button
                          isDisabled={readonly}
                          variant="primary"
                          onClick={() => {
                            doGenerateLuggageTag();
                          }}
                          style={{ marginTop: 20, }}
                        >
                          Generate Tracking Tag
                        </Button>
                      </FlexItem>
                      <FlexItem>
                        <Button
                          isDisabled={readonly}
                          variant="danger"
                          onClick={() => {
                            if (confirm("Are you sure you want to unverify this luggage?")) {
                              doVerifyLuggage(false);
                            }
                          }}
                          style={{ marginTop: 20, }}
                        >
                          Unverify Luggage
                        </Button>
                      </FlexItem>
                    </Flex>
                  </Alert>
                ))
              ) : (
                <Alert
                  isInline
                  title="Luggage Requires Verification"
                  variant="danger"
                >
                  <p>
                    This luggage has been registered, but is not verified.
                    Once a luggage is verified, its information can not be changed.
                  </p>

                  <Flex>
                    <FlexItem>
                      <Button
                        isDisabled={readonly}
                        onClick={() => {
                          doVerifyLuggage(true);
                        }}
                        style={{ marginTop: 20, }}
                      >
                        Verify Luggage
                      </Button>
                    </FlexItem>
                    <FlexItem>
                      <Button
                        isDisabled={readonly}
                        variant="danger"
                        onClick={() => {
                          if (confirm("Are you sure you want to delete this luggage?")) {
                            doDeleteLuggage();
                          }
                        }}
                        style={{ marginTop: 20, }}
                      >
                        Delete Luggage
                      </Button>
                    </FlexItem>
                  </Flex>

                </Alert>
              )}
            </FlexItem>
          </Flex>


          <Flex direction={{ default: "row" }} style={{}} alignItems={{ default: "alignItemsFlexStart" }}>
            <FlexItem>
              <HorizontalTable
                rows={[
                  { title: "Registered At", value: moment(luggage.createdAt).format("MM/DD/YYYY HH:mm A"), valueType: "string", },
                  ...(luggage.verified ? [
                    { title: "Verified At", value: moment(luggage.verifiedAt).format("MM/DD/YYYY HH:mm A"), },
                  ] : []),
                  { title: "Weight (lbs)", value: luggage.weightInPounds, valueType: "number", },
                  // { title: "Weight (lbs)", value: luggage.verified ? luggage.weightInPounds : luggageWeight, valueType: "number", onValueUpdated: luggage.verified ? undefined : ((newVal) => { setLuggageWeight(newVal as number); }) },
                ]}
              />
            </FlexItem>
            <FlexItem>
              <div style={{ width: 150, height: 150, overflow: "hidden", borderRadius: 10, backgroundColor: "#f2f2f2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: 10, boxShadow: "0 0 10px rgb(0,0,0,10%)" }}>
                {luggage?.photoFilePathS3 === null ? (
                  <>
                    <CameraIcon size="lg" color="#a2a2a2" />
                    <Text style={{ color: "#a2a2a2" }}>No Photo</Text>
                  </>
                ) : (
                  <>
                    <img src={`${process.env.REACT_APP_S3_URL}/${luggage?.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", }} />
                  </>
                )}
              </div>
            </FlexItem>
            {luggage.luggageTag || luggage.luggageGroupTag ? (
              <FlexItem>
                <Flex direction={{ default: "column", }}>
                  <FlexItem style={{ width: 150, height: 150, boxShadow: "0 0 10px rgb(0,0,0,50%)" }}>
                    <Tooltip content={"Click to copy ID to clipboard"}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (luggage.luggageTag) {
                            navigator.clipboard.writeText(luggage.luggageTag.guid);
                          }
                        }}
                      >
                        <QRCode
                          size={150}
                          id="generated-qr"
                          value={luggage.luggageTag?.guid}
                          logoImage={logo}
                          qrStyle="squares"
                          bgColor="#FFFFFF"
                          fgColor="#000000"
                          quietZone={0}
                          ref={luggageQrRef}
                          style={{}}
                        />
                      </div>
                    </Tooltip>
                  </FlexItem>
                  <FlexItem>
                    <Button
                      variant="primary"
                      onClick={() => {
                        const ll = document.querySelector("#generated-qr");
                        if (!ll)
                          return;

                        const popup = window.open();
                        if (!popup)
                          return;

                        html2canvas(ll as HTMLElement).then(canvas => {
                          popup.document.body.appendChild(canvas);
                          popup.print();
                        });
                      }}
                    >
                      Print Label
                    </Button>
                  </FlexItem>
                </Flex>
              </FlexItem>
            ) : (
              <>
              </>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
};