import { useMutation, useQuery } from "@apollo/client";
import {
  Alert, Bullseye,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  List,
  ListItem, Modal, PageSection, Spinner, TextArea, TextInput, Title, TitleSizes
} from "@patternfly/react-core";
import { MinusCircleIcon, PlusCircleIcon } from "@patternfly/react-icons";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link } from "@reach/router";
import _, { uniqueId } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import PageContainer from "../Components/PageContainer";
import { GET_AIRPORTS_QUERY, GET_TERMINAL_DATA_QUERY, UPDATE_TERMINAL_DATA_QUERY } from "../graphql/queries";
import { GetAirports, GetAirports_airports, GetTerminalData, GetTerminalData_terminalData, UpdateTerminalData } from "../__generated__/api";

interface Props {
  guid: string | undefined;
}

export default function AirportTerminalData(props: Props) {
  const {
    guid,
  } = props;

  const [airport, setAirport] = useState<GetAirports_airports | undefined>(undefined);
  const [terminalData, setTerminalData] = useState<GetTerminalData_terminalData | undefined>(undefined);

  const [newHoursOfOperation, setNewHoursOfOperation] = useState<string | undefined>(undefined);
  const [newAmenities, setNewAmenities] = useState<string | undefined>(undefined);
  const [newParkingInfo, setNewParkingInfo] = useState<string | undefined>(undefined);
  const [newMoneyWithdrawal, setNewMoneyWithdrawal] = useState<string | undefined>(undefined);

  const [newLocalHotels, setNewLocalHotels] = useState<{ name: string; phone: string; guid: string; }[]>([]);
  const [newCarRentals, setNewCarRentals] = useState<{ name: string; phone: string; guid: string; }[]>([]);
  const [newRailways, setNewRailways] = useState<{ name: string; phone: string; guid: string; }[]>([]);


  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [previewModalVisible, setPreviewModalVisible] = useState<boolean>(false);


  const { loading: fetchingAirport, refetch: refetchAirport, } = useQuery<GetAirports>(
    GET_AIRPORTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        guids: [guid],
      },
      onCompleted: (resp) => {
        const fetched = resp.airports[0];
        setAirport(fetched);
      },
    }
  );
  const { loading: fetchingTerminalData, refetch: refetchTerminalData, } = useQuery<GetTerminalData>(
    GET_TERMINAL_DATA_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        airportGuid: guid,
      },
      onCompleted: (resp) => {
        const fetched = resp.terminalData;
        setTerminalData(fetched);
      },
    }
  );
  const [updateTerminalData, { loading: updatingTerminalData, }] = useMutation<UpdateTerminalData>(UPDATE_TERMINAL_DATA_QUERY, { notifyOnNetworkStatusChange: true, });


  const resetForm = () => {
    setNewHoursOfOperation(terminalData?.hoursOfOperation ?? "");
    setNewAmenities(terminalData?.amenities ?? "");
    setNewParkingInfo(terminalData?.parkingInfo ?? "");
    setNewMoneyWithdrawal(terminalData?.moneyWithdrawal ?? "");

    if ((terminalData?.localHotels ?? "").length > 0) {
      setNewLocalHotels((terminalData?.localHotels ?? "").split(",").map((s) => {
        const kvp = s.split(":");
        return ({
          guid: uniqueId(),
          name: kvp[0] ?? "",
          phone: kvp[1] ?? "",
        });
      }));
    }
    if ((terminalData?.carRentals ?? "").length > 0) {
      setNewCarRentals((terminalData?.carRentals ?? "").split(",").map((s) => {
        const kvp = s.split(":");
        return ({
          guid: uniqueId(),
          name: kvp[0] ?? "",
          phone: kvp[1] ?? "",
        });
      }));
    }
    if ((terminalData?.rail ?? "").length > 0) {
      setNewRailways((terminalData?.rail ?? "").split(",").map((s) => {
        const kvp = s.split(":");
        return ({
          guid: uniqueId(),
          name: kvp[0] ?? "",
          phone: kvp[1] ?? "",
        });
      }));
    }
  };

  useEffect(() => {
    resetForm();
  }, [terminalData]);

  const doUpdateTerminalData = async () => {
    try {
      await updateTerminalData({
        variables: {
          terminalDataGuid: terminalData?.guid,

          hoursOfOperation: newHoursOfOperation,
          amenities: newAmenities,
          parkingInfo: newParkingInfo,
          moneyWithdrawal: newMoneyWithdrawal,

          hotels: newLocalHotels.map((h) => `${h.name}:${h.phone}`).join(","),
          carRentals: newCarRentals.map((h) => `${h.name}:${h.phone}`).join(","),
          railways: newRailways.map((h) => `${h.name}:${h.phone}`).join(","),
        }
      });
      refetchTerminalData();
    } catch (err) {
      console.log("Failed to get update Airport");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <ConfirmationModal
          title="Confirm Terminal Data Changes"
          contents={(
            <>
              <p>
                Are you sure you want to save your changes to this airport&apos;s AMC data?
              </p>
              <List>
                {terminalData?.hoursOfOperation !== newHoursOfOperation && (<ListItem>{terminalData?.hoursOfOperation} {"->"} {newHoursOfOperation}</ListItem>)}
                {terminalData?.amenities !== newAmenities && (<ListItem>{terminalData?.amenities} {"->"} {newAmenities}</ListItem>)}
                {terminalData?.moneyWithdrawal !== newMoneyWithdrawal && (<ListItem>{terminalData?.moneyWithdrawal} {"->"} {newMoneyWithdrawal}</ListItem>)}
                {terminalData?.parkingInfo !== newParkingInfo && (<ListItem>{terminalData?.parkingInfo} {"->"} {newParkingInfo}</ListItem>)}
              </List>
            </>
          )}
          onClose={(): void => {
            setUpdateModalVisible(false);
          }}
          onConfirm={(): void => {
            setUpdateModalVisible(false);
            doUpdateTerminalData();
          }}
          visible={updateModalVisible}
        />

        <Modal
          width="80%"
          aria-label={"Preview Modal"}
          title={"Preview"}
          isOpen={previewModalVisible}
          onClose={() => {
            setPreviewModalVisible(false);
          }}
          header={(
            <>
              <Title id="custom-header-label" headingLevel="h1" size={TitleSizes["2xl"]}>
                AMC Gram - {airport?.name}
              </Title>
            </>
          )}
          footer={(
            <Title headingLevel="h4" size={TitleSizes.md}>
              <span className="pf-u-pl-sm">
                {/* <a href="https://www.jbmdl.jb.mil/Portals/47/documents/AFD-151016-004.pdf"> McGuire Air Force Base AMC Gram</a> */}
              </span>
              <br></br>
            </Title>
          )}
        >
          <Grid>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Hours of Operation</p>
              <p>{newHoursOfOperation}</p>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Parking Information</p>
              <p>{newParkingInfo}</p>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Airport Amenities</p>
              <p>{newAmenities}</p>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Check Cashing/ATM Machines</p>
              <p>{newMoneyWithdrawal}</p>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Local Hotels</p>
              <TableComposable
                variant="compact"
              >
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Phone Number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {newLocalHotels.map((h) => (
                    <Tr key={h.guid}>
                      <Td>{h.name}</Td>
                      <Td>{h.phone}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </TableComposable>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Local Railways</p>
              <TableComposable
                variant="compact"
              >
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Phone Number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {newRailways.map((h) => (
                    <Tr key={h.guid}>
                      <Td>{h.name}</Td>
                      <Td>{h.phone}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </TableComposable>
            </GridItem>
            <GridItem span={6} style={{ marginBottom: 20 }}>
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>Local Car Rentals</p>
              <TableComposable
                variant="compact"
              >
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Phone Number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {newCarRentals.map((h) => (
                    <Tr key={h.guid}>
                      <Td>{h.name}</Td>
                      <Td>{h.phone}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </TableComposable>
            </GridItem>
          </Grid>
        </Modal>

        {fetchingAirport ? (
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        ) : (
          <>
            {guid === undefined || airport === undefined ? (
              <Alert
                isInline
                variant="danger"
                title="Airport Not Found"
              >
                <p>Failed to load airport. No such ID exists: {guid}.</p>
              </Alert>
            ) : (
              <>
                <Grid style={{ marginBottom: 40, }}>
                  <GridItem span={4}>
                    {/* Name */}
                    <Form>
                      <FormGroup
                        label="Hours of Operation"
                        type="string"
                        fieldId="hours"
                      >
                        <TextArea
                          value={newHoursOfOperation}
                          onChange={(val) => { setNewHoursOfOperation(val); }}
                          aria-label="Hours of Operation"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Airport Amenities"
                        type="string"
                        fieldId="amenities"
                      >
                        <TextArea
                          value={newAmenities}
                          onChange={(val) => { setNewAmenities(val); }}
                          aria-label="Amenities"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Check Cashing/ATM Machines"
                        type="string"
                        fieldId="money"
                      >
                        <TextArea
                          value={newMoneyWithdrawal}
                          onChange={(val) => { setNewMoneyWithdrawal(val); }}
                          aria-label="Money Withdrawal"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Parking Information"
                        type="string"
                        fieldId="parking"
                      >
                        <TextArea
                          value={newParkingInfo}
                          onChange={(val) => { setNewParkingInfo(val); }}
                          aria-label="Parking"
                        />
                      </FormGroup>
                      <Flex>
                        <FlexItem>
                          <Title headingLevel="h3">
                            Local Hotels
                            <Button
                              variant="plain"
                              aria-label="New Hotel"
                              onClick={() => {
                                setNewLocalHotels([...newLocalHotels, { name: `New Hotel ${moment().milliseconds()}`, phone: "000-111-2345", guid: uniqueId(), }]);
                              }}
                            >
                              <PlusCircleIcon />
                            </Button>
                          </Title>
                          <TableComposable
                            aria-label="Hotels Table"
                            variant={"compact"}
                          >
                            <Thead className="pf-m-fit-content">
                              <Tr>
                                <Th>Name</Th>
                                <Th>Phone</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {newLocalHotels.map((h, idx) => (
                                <Tr key={h.guid}>
                                  <Td>
                                    <FormGroup fieldId="hotelName">
                                      <TextInput
                                        aria-label="Hotel Name"
                                        placeholder="Hotel Name"
                                        value={h.name}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newLocalHotels);
                                          arrCopy[idx].name = val;
                                          setNewLocalHotels(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <FormGroup fieldId="hotelPhone">
                                      <TextInput
                                        aria-label="Hotel Phone"
                                        placeholder="Hotel Phone"
                                        value={h.phone}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newLocalHotels);
                                          arrCopy[idx].phone = val;
                                          setNewLocalHotels(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <Button
                                      variant="plain"
                                      aria-label="Delete"
                                      onClick={() => {
                                        setNewLocalHotels(newLocalHotels.filter((hot) => hot.name !== h.name));
                                      }}
                                    >
                                      <MinusCircleIcon color="red" />
                                    </Button>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </TableComposable>
                        </FlexItem>
                      </Flex>
                      <Flex>
                        <FlexItem>
                          <Title headingLevel="h3">
                            Car Rentals
                            <Button
                              variant="plain"
                              aria-label="New Rental Place"
                              onClick={() => {
                                setNewCarRentals([...newCarRentals, { name: `New Dealership ${moment().milliseconds()}`, phone: "000-111-2345", guid: uniqueId(), }]);
                              }}
                            >
                              <PlusCircleIcon />
                            </Button>
                          </Title>
                          <TableComposable
                            aria-label="Car Rentals Table"
                            variant={"compact"}
                          >
                            <Thead className="pf-m-fit-content">
                              <Tr>
                                <Th>Name</Th>
                                <Th>Phone</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {newCarRentals.map((rental, idx) => (
                                <Tr key={rental.guid}>
                                  <Td>
                                    <FormGroup fieldId="rentalName">
                                      <TextInput
                                        aria-label="Car Rental Name"
                                        placeholder="Car Rental Name"
                                        value={rental.name}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newCarRentals);
                                          arrCopy[idx].name = val;
                                          setNewCarRentals(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <FormGroup fieldId="rentalPhone">
                                      <TextInput
                                        aria-label="Rental Phone"
                                        placeholder="Rental Phone"
                                        value={rental.phone}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newCarRentals);
                                          arrCopy[idx].phone = val;
                                          setNewCarRentals(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <Button
                                      variant="plain"
                                      aria-label="Delete"
                                      onClick={() => {
                                        setNewCarRentals(newCarRentals.filter((hot) => hot.name !== rental.name));
                                      }}
                                    >
                                      <MinusCircleIcon color="red" />
                                    </Button>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </TableComposable>
                        </FlexItem>
                      </Flex>
                      <Flex>
                        <FlexItem>
                          <Title headingLevel="h3">
                            Railways
                            <Button
                              variant="plain"
                              aria-label="New Railway"
                              onClick={() => {
                                setNewRailways([...newRailways, { name: `New Railway ${moment().milliseconds()}`, phone: "000-111-2345", guid: uniqueId(), }]);
                              }}
                            >
                              <PlusCircleIcon />
                            </Button>
                          </Title>
                          <TableComposable
                            aria-label="Railways Table"
                            variant={"compact"}
                          >
                            <Thead className="pf-m-fit-content">
                              <Tr>
                                <Th>Name</Th>
                                <Th>Phone</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {newRailways.map((railway, idx) => (
                                <Tr key={railway.guid}>
                                  <Td>
                                    <FormGroup fieldId="railwayName">
                                      <TextInput
                                        aria-label="Car Rental Name"
                                        placeholder="Car Rental Name"
                                        value={railway.name}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newRailways);
                                          arrCopy[idx].name = val;
                                          setNewRailways(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <FormGroup fieldId="railwayPhone">
                                      <TextInput
                                        aria-label="Rental Phone"
                                        placeholder="Rental Phone"
                                        value={railway.phone}
                                        onChange={(val) => {
                                          const arrCopy = _.cloneDeep(newRailways);
                                          arrCopy[idx].phone = val;
                                          setNewRailways(arrCopy);
                                        }}
                                      />
                                    </FormGroup>
                                  </Td>
                                  <Td>
                                    <Button
                                      variant="plain"
                                      aria-label="Delete"
                                      onClick={() => {
                                        setNewRailways(newRailways.filter((hot) => hot.name !== railway.name));
                                      }}
                                    >
                                      <MinusCircleIcon color="red" />
                                    </Button>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </TableComposable>
                        </FlexItem>
                      </Flex>


                      <Button
                        variant="secondary"
                        onClick={() => { setPreviewModalVisible(true); }}
                      >
                        Preview Changes
                      </Button>
                      <Button
                        disabled={updatingTerminalData}
                        onClick={() => { setUpdateModalVisible(true); }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </GridItem>
                </Grid>
              </>
            )}
          </>
        )}
      </PageSection>
    </PageContainer>
  );
}

const styles: {
  [key: string]: React.CSSProperties;
} = {
  splitScreen: {
    display: "flex",
    flexDirection: "row",
  },
  splitLeft: {
    width: "50%",
  },
  splitRight: {
    width: "50%",
  },
  paddingLower: {
    paddingLeft: "3px",
  },
  tABPadding: {
    paddingTop: "5px",
    paddingBottom: "5px",
  }
};