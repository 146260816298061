import { useContext } from "react";
import { UserContext } from "./UserContext";

export const useUser = () => {
    const {
        me
    } = useContext(UserContext);

    return {
        me,
    };
};
