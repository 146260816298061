import { gql } from "@apollo/client";

export const GET_LUGGAGE_QUERY = gql`
query GetLuggage (
  $guids: [String],
  $owningTravelerGuid: String,

  $pageNumber: Int,
  $resultsPerPage: Int
) {
  luggage(
    guids: $guids,
    owningTravelerGuid: $owningTravelerGuid,
    pageNumber: $pageNumber,
    resultsPerPage: $resultsPerPage
  ) {
    guid
    createdAt

    weightInPounds
    photoFilePathS3
    verified
    verifiedAt

    boardingPass {
      guid
      owner {
        guid
        sponsor {
          guid
        }
      }
    }

    luggageTag {
      guid
      createdAt
      locationHistory {
        guid
        createdAt

        airport {
          guid
          code
          name
        }
        
        state
      }
    }

    luggageGroupTag {
      guid
      createdAt

      locationHistory {
        guid
        createdAt

        airport {
          guid
          code
          name
        }
        
        state
      }
    }
  }
}
`;


export const TOGGLE_CHECKED_LUGGAGE_VERIFIED_QUERY = gql`
mutation ToggleCheckedLuggageVerified (
  $guid: String!,
  $state: Boolean!
) {
  toggleCheckedLuggageVerified(
    guid: $guid,
    state: $state
  )
}
`;

export const CREATE_CHECKED_LUGGAGE_QUERY = gql`
mutation CreateCheckedLuggage(
    $boardingPassGuid: String!
    $weightInPounds: Float,
    $image: Upload,
) {
  createCheckedLuggage(
    boardingPassGuid: $boardingPassGuid,
    weightInPounds: $weightInPounds,
    image: $image,
  )
}
`;

export const DELETE_CHECKED_LUGGAGE_QUERY = gql`
mutation DeleteCheckedLuggage(
    $guid: String!
) {
  deleteCheckedLuggage(
    guid: $guid,
  )
}
`;

export const SET_CHECKED_LUGGAGE_PHOTO_QUERY = gql`
mutation SetCheckedLuggagePhoto(
  $guid: String!,
  $file: Upload
) {
  setCheckedLuggagePhoto(
    guid: $guid,
    file: $file
  )
}
`;
