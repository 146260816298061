import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem, PageSection
} from "@patternfly/react-core";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import React, { useState } from "react";
import PageContainer from "../Components/PageContainer";
import { CREATE_AIRCRAFT_QUERY, GET_AIRCRAFTS_QUERY, GET_AIRCRAFT_MANUFACTURERS_QUERY } from "../graphql/queries";
import { CreateAircraft, GetAircraftManufacturers, GetAircraftManufacturers_aircraftManufacturers, GetAircrafts, GetAircrafts_aircrafts } from "../__generated__/api";

export default function Aircrafts() {
  const [aircraftManufacturers, setAircraftManufacturers] = useState<GetAircraftManufacturers_aircraftManufacturers[]>([]);
  const [aircrafts, setAircrafts] = useState<GetAircrafts_aircrafts[]>([]);

  const { loading: fetchingAircrafts, refetch: refetchAircrafts, } = useQuery<GetAircrafts>(
    GET_AIRCRAFTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAircrafts(resp.aircrafts);
      },
    }
  );
  const { loading: fetchingAircraftManufacturers, refetch: refetchAircraftManufacturers, } = useQuery<GetAircraftManufacturers>(
    GET_AIRCRAFT_MANUFACTURERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        setAircraftManufacturers(resp.aircraftManufacturers);
      },
    }
  );
  const [createAircraft, { loading: creatingAircraft }] = useMutation<CreateAircraft>(CREATE_AIRCRAFT_QUERY, { notifyOnNetworkStatusChange: true, });

  const doCreateAircraft = async () => {
    try {
      const createResp = await createAircraft({
        variables: {
          name: `New Aircraft ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          designation: "Placeholder",
          manufacturerGuid: aircraftManufacturers[0].guid,
        }
      });
      navigate(`/aircrafts/${createResp.data?.createAircraft}`);
    } catch (err) {
      console.log("Failed to get create Aircraft");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  return (
    <PageContainer>
      <PageSection>
        <Flex style={{ marginBottom: 20, }}>
          <FlexItem>
            <Button
              disabled={creatingAircraft}
              variant="primary"
              onClick={() => { doCreateAircraft(); }}
            >
              Create Aircraft
            </Button>
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Missions Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>Name</Th>
              <Th>Designation</Th>
              <Th>Manufacturer</Th>
              <Th>Created At</Th>
              <Th>Last Updated At</Th>

              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {aircrafts.map((aircraft) => (
              <Tr key={aircraft.guid}>
                <Td>{aircraft.name}</Td>
                <Td>{aircraft.designation}</Td>
                <Td>{aircraft.manufacturer.name}</Td>
                <Td>{moment(aircraft.createdAt).format("MM/DD/YYYY HH:mm")}</Td>
                <Td>{moment(aircraft.lastUpdatedAt).format("MM/DD/YYYY HH:mm")}</Td>

                <Td>
                  <Link to={`/aircrafts/${aircraft.guid}`}>
                    <Button isSmall>Edit</Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableComposable>
      </PageSection>
    </PageContainer>
  );
}
