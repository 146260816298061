import { gql } from "@apollo/client";

export const GET_FLIGHTS_QUERY = gql`
query GetFlights ($guids: [String], $departureAirportGuid: String, $arrivalAirportGuid: String, $departureDate: DateTime) {
  flights(guids: $guids, departureAirportGuid: $departureAirportGuid, arrivalAirportGuid: $arrivalAirportGuid, departureDate: $departureDate) {
      guid
      spaceAvailable
      mission {
        guid
        externalId
        description
      }
      aircraft {
        guid
        name
        designation
        manufacturer {
          name
        }
      }
      departureSchedule
      departureActual
      departureAirport {
        guid
        name
        code
      }
      arrivalSchedule
      arrivalActual
      arrivalAirport {
        guid
        name
        code
      }
      publishTime
      rollCallTime
      hasRollCallStarted
      
      flightStatuses {
        guid
        createdAt
        status
        spaceRequest {
          guid
          createdAt
          travelAuthorization {
            guid
            category
            travelers {
              guid
              firstName
              middleName
              lastName
              sponsorRelationship
            }
          }
        }
      }
    }
  }
`;

export const GET_FLIGHT_BACKLOGS_QUERY = gql`
query GetFlightBacklogs($guids: [String]!, $sponsorGuid: String) {
  flightBacklogs(guids: $guids, sponsorGuid: $sponsorGuid) {
    guid
    totalAvailableSeats
    queueSize
    positionInQueueStart
    positionInQueueEnd
  }
}
`;

export const CREATE_FLIGHT_QUERY = gql`
 mutation CreateFlight(
  $missionGuid: String!,

  $aircraftGuid: String!,

  $spaceAvailable: Int!,

  $departureSchedule: DateTime!,
  $departureAirportGuid: String!,

  $arrivalSchedule: DateTime!,
  $arrivalAirportGuid: String!,

  $publishTime: DateTime,
  $rollCallTime: DateTime,
){
  createFlight(
    missionGuid: $missionGuid,

    aircraftGuid: $aircraftGuid,

    spaceAvailable: $spaceAvailable,

    departureSchedule: $departureSchedule,
    departureAirportGuid: $departureAirportGuid,

    arrivalSchedule: $arrivalSchedule,
    arrivalAirportGuid: $arrivalAirportGuid,

    publishTime: $publishTime,
    rollCallTime: $rollCallTime,
  )
}
`;


export const UPDATE_FLIGHT_QUERY = gql`
 mutation UpdateFlight(
  $guid: String!,

  $aircraftGuid: String,

  $spaceAvailable: Int,

  $departureSchedule: DateTime,
  $departureActual: DateTime,
  $departureAirportGuid: String,

  $arrivalSchedule: DateTime,
  $arrivalActual: DateTime,
  $arrivalAirportGuid: String,

  $publishTime: DateTime,
  $rollCallTime: DateTime,
){
  updateFlight(
    guid: $guid,

    aircraftGuid: $aircraftGuid,

    spaceAvailable: $spaceAvailable,

    departureSchedule: $departureSchedule,
    departureActual: $departureActual,
    departureAirportGuid: $departureAirportGuid,

    arrivalSchedule: $arrivalSchedule,
    arrivalActual: $arrivalActual,
    arrivalAirportGuid: $arrivalAirportGuid,

    publishTime: $publishTime,
    rollCallTime: $rollCallTime,
  )
}
`;

export const DELETE_FLIGHT_QUERY = gql`
mutation DeleteFlight($guid: String!) {
  deleteFlight(guid: $guid)
}
`;

export const TOGGLE_FLIGHT_ROLL_CALL_QUERY = gql`
mutation ToggleFlightRollCall($guid: String!, $state: Boolean!) {
  toggleFlightRollCall(guid: $guid, state: $state)
}
`;

export const GET_FLIGHT_MANIFEST_QUERY = gql`
query GetFlightManifest ($flightGuid: String!) {
  flightManifest(flightGuid: $flightGuid) {
    rows {
      travelerGuid
      firstName
      middleName
      lastName
      rank
      dodId
      service
      retired
      checkedBagsCount
      checkedBagsWeight
      passengerWeight
      carryOnWeight
      epocName
      epocPhone
    }
  }
}
`;
