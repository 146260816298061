import {
  faBox,
  faBuilding,
  faChartArea, faClipboardCheck, faClipboardList, faLuggageCart, faMapMarked, faPersonWalkingLuggage, faPlane, faPlaneDeparture, faUsers, IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Nav, NavItem, NavList, Page,
  PageProps, PageSidebar
} from "@patternfly/react-core";
import { Link, useLocation } from "@reach/router";
import React, { useState } from "react";
import Header from "../Header";

interface INavItem {
  title: string;
  href: string;
  icon: IconDefinition;
}

const navItems: INavItem[] = [
  { title: "Dashboard", href: "/", icon: faChartArea, },
  { title: "Missions", href: "/missions", icon: faPlaneDeparture, },
  { title: "Roll Call", href: "/roll-call", icon: faClipboardList, },
  { title: "Passengers", href: "/passengers", icon: faUsers, },
  { title: "Travel Authorizations", href: "/travel-authorizations", icon: faClipboardCheck, },
  { title: "Aircrafts", href: "/aircrafts", icon: faPlane, },
  { title: "Airports", href: "/airports", icon: faBuilding, },
  { title: "Check-In", href: "/check-in", icon: faClipboardList, },
  { title: "Boarding", href: "/boarding", icon: faPersonWalkingLuggage, },
  { title: "Track Luggage", href: "/track-luggage", icon: faMapMarked, },
  { title: "Luggage Pallets", href: "/pallets", icon: faBox, },
  { title: "Track Pallet", href: "/track-pallet", icon: faMapMarked, },
  { title: "Lost & Found", href: "/lost-and-found", icon: faLuggageCart, },
];

const styles: { [key: string]: React.CSSProperties } = {
  masthead: {
    // color: '#1c2449'
    color: "white",
  },
  mastheadMain: {
    fontFamily: "ethnocentric",
  },
  loginHeaderContainer: {
    textAlign: "center",
  },
  loginHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
  afLogo: {
    maxHeight: 40,
    marginRight: 20,
    marginLeft: 20,
  },
  link: {
    alignItems: "center",
  },
  linkIcon: {
    marginRight: 5,
  },
  footer: {
    // bottom: 0,
    // position: 'absolute',
    // minWidth: '100%',
  }
};

export default function PageContainer({ children }: PageProps) {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const location = useLocation();

  const Sidebar = (
    <PageSidebar
      isNavOpen={isNavOpen}
      tabIndex={0}
      nav={(
        <Nav>
          <NavList>
            {navItems.map((navItem) => (
              <NavItem
                key={navItem.href}
                isActive={(navItem.href === "/" && location.pathname === "/") || (navItem.href !== "/" && location.pathname.startsWith(navItem.href))}
              >
                <Link style={styles.link} to={navItem.href}>
                  <FontAwesomeIcon style={styles.linkIcon} icon={navItem.icon} />
                  {navItem.title}
                </Link>
              </NavItem>
            ))}
          </NavList>
        </Nav>
      )}
    />
  );

  return (
    <Page
      header={<Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />}
      sidebar={Sidebar}
      isManagedSidebar
    >
      {/* <AlertsBox alerts={alerts.alerts} /> */}
      {children}
    </Page>
  );
}
