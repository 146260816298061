import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionToggle,
  Alert, Avatar, Button, CodeBlock, CodeBlockCode, Flex,
  FlexItem, Grid, GridItem, List, ListItem, PageSection,
  Panel,
  PanelFooter,
  PanelMain,
  PanelMainBody, Tab,
  Tabs,
  TabTitleText, Text, Title,
  TitleSizes,
  Tooltip
} from "@patternfly/react-core";
import { AddCircleOIcon, ImageIcon, UserIcon } from "@patternfly/react-icons";
import { Link, navigate } from "@reach/router";
import { FileWithPath } from "file-selector";
import moment from "moment";
import { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import avatarImg from "../assets/avatar.svg";
import CreateTravelAuthorizationModal from "../Components/CreateTravelAuthorizationModal";
import DocumentManager from "../Components/DocumentManager";
import Empty from "../Components/Empty";
import HorizontalTable from "../Components/HorizontalTable";
import PageContainer from "../Components/PageContainer";
import TravelerManager from "../Components/TravelerManager";
import { CREATE_TRAVELER_DOCUMENT_QUERY, CREATE_TRAVELER_QUERY, DELETE_TRAVELER_DOCUMENT_QUERY, DELETE_TRAVELER_QUERY, GET_BOARDING_PASSES_QUERY, GET_SPACE_REQUESTS_QUERY, GET_TRAVELERS_QUERY, GET_TRAVEL_AUTHORIZATIONS_QUERY, GET_USERS_QUERY, UPDATE_EMERGENCY_CONTACT_MUTATION, UPDATE_TRAVELER_ACCOMMODATIONS, UPDATE_TRAVELER_QUERY } from "../graphql/queries";
import { organizeTravelAuths } from "../utils/upgrade-utils";
import { CreateTraveler, CreateTravelerDocument, DeleteTraveler, DeleteTravelerDocument, FlightStatusType, GetBoardingPasses, GetBoardingPasses_boardingPasses, GetSpaceRequests, GetSpaceRequests_spaceRequests, GetSpaceRequests_spaceRequests_flightStatus_flight, GetTravelAuthorizations, GetTravelAuthorizations_travelAuthorizations, GetTravelers, GetTravelers_travelers, GetUsers, GetUsers_users, SponsorRelationship, UpdateEmergencyContact, UpdateTraveler, UpdateTravelerAccommodations } from "../__generated__/api";


interface UpdatedTravelerInfo {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  dodId?: string;
  sponsorRelationship?: SponsorRelationship;
}

interface UpdatedEmergencyInfo {
  firstName?: string;
  lastName?: string;
  phone?: string;
}

interface UpdatedTravelerAccommodationsInfo {
  requiresLargerSeat?: boolean;
  requiresSeatBeltExtension?: boolean;
  requiresWheelchairAccessibleSpace?: boolean;
  notes?: string;
}

interface Props {
  guid: string | undefined;
}

export default function Passenger(props: Props) {
  const {
    guid,
  } = props;

  const apolloClient = useApolloClient();

  const [traveler, setTraveler] = useState<GetTravelers_travelers | undefined>(undefined);
  const [travelerIsSponsor, setTravelerIsSponsor] = useState<boolean>(false);
  const [sponsorTraveler, setSponsorTraveler] = useState<GetTravelers_travelers | undefined>(undefined);
  const [sponsoredTravelers, setSponsoredTravelers] = useState<GetTravelers_travelers[]>([]);
  const [sponsorUser, setSponsorUser] = useState<GetUsers_users | undefined>(undefined);
  const [sponsorTravelAuthorizations, setSponsorTravelAuthorizations] = useState<{
    unsubmitted: GetTravelAuthorizations_travelAuthorizations[];
    submitted: GetTravelAuthorizations_travelAuthorizations[];
    approvedActive: GetTravelAuthorizations_travelAuthorizations[];
    approvedExpired: GetTravelAuthorizations_travelAuthorizations[];
    denied: GetTravelAuthorizations_travelAuthorizations[];
  }>({
    unsubmitted: [],
    submitted: [],
    approvedActive: [],
    approvedExpired: [],
    denied: [],
  });
  const [sponsorSpaceRequests, setSponsorSpaceRequests] = useState<GetSpaceRequests_spaceRequests[]>([]);
  const [boardingPasses, setBoardingPasses] = useState<GetBoardingPasses_boardingPasses[]>([]);


  const [travelAuthTabIndex, setTravelAuthTabIndex] = useState<string | number>(0);
  const [expandedTravelAuth, setExpandedTravelAuth] = useState<string>();
  const [expandedFlight, setExpandedFlight] = useState<string>();
  const [createTravelAuthModalVisible, setCreateTravelAuthModalVisible] = useState<boolean>(false);
  const [registerFlightModalVisible, setRegisterFlightModalVisible] = useState<boolean>(false);

  const { loading: fetchingTraveler, refetch: refetchTraveler, } = useQuery<GetTravelers>(
    GET_TRAVELERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        guids: [guid],
      },
      onCompleted: async (resp) => {
        const fetchedTraveler = resp.travelers[0];
        setTraveler(fetchedTraveler);

        if (fetchedTraveler === undefined) return;
        try {
          const queryResp = await apolloClient.query<GetUsers>({
            query: GET_USERS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              guids: [fetchedTraveler.sponsor?.guid],
            },
          });
          const fetchedSponsorUser = queryResp.data.users[0];
          setSponsorUser(fetchedSponsorUser);

          // get sponsor traveler 
          if (fetchedSponsorUser !== undefined) {
            const travelersQueryResp = await apolloClient.query<GetTravelers>({
              query: GET_TRAVELERS_QUERY,
              notifyOnNetworkStatusChange: true,
              fetchPolicy: "no-cache",
              variables: {
                sponsorGuid: fetchedSponsorUser.guid,
              },
            });
            const fetchedSponsorTravelers = travelersQueryResp.data.travelers;
            const fetchedSponsorTraveler = fetchedSponsorTravelers.find((t) => t.sponsorRelationship === SponsorRelationship.SELF);
            setSponsorTraveler(fetchedSponsorTraveler);
            setSponsoredTravelers(fetchedSponsorTravelers.filter((t) => t.sponsorRelationship !== SponsorRelationship.SELF));

            if (fetchedSponsorTraveler !== undefined) {
              setTravelerIsSponsor(fetchedSponsorTraveler.guid === guid);
            }
          }

          // get travel authorizations
          const travelAuthQueryResp = await apolloClient.query<GetTravelAuthorizations>({
            query: GET_TRAVEL_AUTHORIZATIONS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              ownerGuid: fetchedSponsorUser.guid,
            },
          });
          const fetchedTravelAuths = travelAuthQueryResp.data.travelAuthorizations;
          const organized = organizeTravelAuths(fetchedTravelAuths);
          setSponsorTravelAuthorizations(organized);


          // get space requests
          const spaceRequestQueryResp = await apolloClient.query<GetSpaceRequests>({
            query: GET_SPACE_REQUESTS_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              ownerGuid: fetchedSponsorUser.guid,
            },
          });
          const fetchedRequests = spaceRequestQueryResp.data.spaceRequests;
          setSponsorSpaceRequests(fetchedRequests);


          // get boarding passes
          const boardingPassesQueryResp = await apolloClient.query<GetBoardingPasses>({
            query: GET_BOARDING_PASSES_QUERY,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            variables: {
              ownerGuid: guid,
            },
          });
          const fetchedPasses = boardingPassesQueryResp.data.boardingPasses;
          setBoardingPasses(fetchedPasses);
        } catch (err) {
          console.log(JSON.stringify(err, null, 2));
        }
      },
    }
  );
  const [createTraveler, { loading: creatingTraveler }] = useMutation<CreateTraveler>(CREATE_TRAVELER_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateTraveler, { loading: updatingTraveler }] = useMutation<UpdateTraveler>(UPDATE_TRAVELER_QUERY, { notifyOnNetworkStatusChange: true, });
  const [deleteTraveler, { loading: deletingTraveler, }] = useMutation<DeleteTraveler>(DELETE_TRAVELER_QUERY, { notifyOnNetworkStatusChange: true, });
  const [updateEmergencyContact, { loading: updatingEmergencyContact }] = useMutation<UpdateEmergencyContact>(UPDATE_EMERGENCY_CONTACT_MUTATION, { notifyOnNetworkStatusChange: true, });
  const [updateTravelerAccommodations, { loading: updatingTravelerAccommodations }] = useMutation<UpdateTravelerAccommodations>(UPDATE_TRAVELER_ACCOMMODATIONS, { notifyOnNetworkStatusChange: true, });
  const [createTravelerDocument, { loading: creatingTravelerDocument }] = useMutation<CreateTravelerDocument>(CREATE_TRAVELER_DOCUMENT_QUERY, { notifyOnNetworkStatusChange: true, });
  const [deleteTravelerDocument, { loading: deletingTravelerDocument }] = useMutation<DeleteTravelerDocument>(DELETE_TRAVELER_DOCUMENT_QUERY, { notifyOnNetworkStatusChange: true, });

  const doCreateTraveler = async () => {
    try {
      const createTravelerResp = await createTraveler({
        variables: {
          sponsorGuid: sponsorUser?.guid,
          email: `new_passenger.${Math.random().toString(36).substring(2, 15)}@upgrade.test`,
          firstName: `New Passenger ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          middleName: "",
          lastName: "",
          dodId: "",
          sponsorRelationship: SponsorRelationship.CHILD,
        }
      });
      navigate(`/passengers/${createTravelerResp.data?.createTraveler}`);
    } catch (err) {
      console.error("Failed to get create Traveler");
      console.error(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateTraveler = async (updatedInfo: UpdatedTravelerInfo) => {
    try {
      await updateTraveler({
        variables: {
          guid,
          ...(updatedInfo.email !== undefined && ({ email: updatedInfo.email, })),
          ...(updatedInfo.firstName !== undefined && ({ firstName: updatedInfo.firstName, })),
          ...(updatedInfo.middleName !== undefined && ({ middleName: updatedInfo.middleName, })),
          ...(updatedInfo.lastName !== undefined && ({ lastName: updatedInfo.lastName, })),
          ...(updatedInfo.dodId !== undefined && ({ dodId: updatedInfo.dodId, })),
          ...(updatedInfo.sponsorRelationship !== undefined && ({ sponsorRelationship: updatedInfo.sponsorRelationship, })),
        }
      });
      await refetchTraveler();
    } catch (err) {
      console.error("Failed to get update Traveler");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateEmergencyContact = async (updatedInfo: UpdatedEmergencyInfo) => {
    if (sponsorUser === undefined) {
      console.error("Failed to update EPOC info because sponsor user is undefined");
      return;
    }
    try {
      await updateEmergencyContact({
        variables: {
          userGuid: sponsorUser.guid,
          ...(updatedInfo.phone !== undefined && ({ phone: updatedInfo.phone, })),
          ...(updatedInfo.firstName !== undefined && ({ firstName: updatedInfo.firstName, })),
          ...(updatedInfo.lastName !== undefined && ({ lastName: updatedInfo.lastName, })),
        }
      });
      await refetchTraveler();
    } catch (err) {
      console.error("Failed to get update Emergency Contact");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doUpdateTravelerAccommodations = async (updatedInfo: UpdatedTravelerAccommodationsInfo) => {
    try {
      await updateTravelerAccommodations({
        variables: {
          travelerGuid: guid,
          ...(updatedInfo.requiresLargerSeat !== undefined && ({ requiresLargerSeat: updatedInfo.requiresLargerSeat, })),
          ...(updatedInfo.requiresSeatBeltExtension !== undefined && ({ requiresSeatBeltExtension: updatedInfo.requiresSeatBeltExtension, })),
          ...(updatedInfo.requiresWheelchairAccessibleSpace !== undefined && ({ requiresWheelchairAccessibleSpace: updatedInfo.requiresWheelchairAccessibleSpace, })),
          ...(updatedInfo.notes !== undefined && ({ notes: updatedInfo.notes, })),
        }
      });
      await refetchTraveler();
    } catch (err) {
      console.error("Failed to get update Traveler Accommodations");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doDeleteTraveler = async () => {
    try {
      await deleteTraveler({
        variables: {
          guid,
        }
      });
      navigate("/passengers/");
    } catch (err) {
      console.log("Failed to get delete Traveler");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doCreateTravelerDocument = async (file: any) => {
    try {
      await createTravelerDocument({
        variables: {
          travelerGuid: guid,
          file,
        },
      });
      await refetchTraveler();
    } catch (err) {
      console.log("Failed to get create document for Passenger");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doDeleteTravelerDocument = async (guid: string) => {
    try {
      await deleteTravelerDocument({
        variables: {
          guid,
        },
      });
      await refetchTraveler();
    } catch (err) {
      console.log("Failed to get delete document for Passenger");
      console.log(JSON.stringify(err, null, 2));
    }
  };

  const doCreateTravelAuthorizationDocument = async (auth: GetTravelAuthorizations_travelAuthorizations, file: FileWithPath | DataTransferItem) => {
    // try {
    //   await createTravelAuthorizationDocument({
    //     variables: {
    //       travelerGuid: guid,
    //       file,
    //     },
    //   });
    //   await refetchTraveler();
    // } catch (err) {
    //   console.log("Failed to get create document for Passenger");
    //   console.log(JSON.stringify(err, null, 2));
    // }
  };

  const doDeleteTravelAuthorizationDocument = async (guid: string) => {
    // try {
    //   await deleteTravelAuthorizationDocument({
    //     variables: {
    //       guid,
    //     },
    //   });
    //   await refetchTraveler();
    // } catch (err) {
    //   console.log("Failed to get delete document for Passenger");
    //   console.log(JSON.stringify(err, null, 2));
    // }
  };

  const getFlights = (filterOptions?: { status?: FlightStatusType, when?: "FUTURE" | "PAST" }) => {
    let requests = sponsorSpaceRequests;
    if (filterOptions?.status !== undefined) {
      requests = requests.filter((sr) => sr.flightStatus!.status === filterOptions.status);
    }
    if (filterOptions?.when !== undefined) {
      if (filterOptions?.when === "FUTURE") {
        requests = requests.filter((sr) => moment(sr.flightStatus!.flight.departureSchedule).isSameOrAfter(moment()));
      }
      else if (filterOptions?.when === "PAST") {
        requests = requests.filter((sr) => moment(sr.flightStatus!.flight.departureSchedule).isBefore(moment()));
      }
    }
    return requests.map((r) => r.flightStatus!.flight);
  };

  const getBoardingPassForFlight = (flightGuid: string) => boardingPasses.find((bp) => bp.spaceRequest.flightStatus?.flight.guid === flightGuid);

  const profileTemplate = () => {
    if (guid === undefined || traveler === undefined) {
      return (
        <Alert
          isInline
          variant="danger"
          title="Passenger Not Found"
        >
          <p>Failed to load passenger. No such ID exists: {guid}.</p>
        </Alert>
      );
    }

    const travelAuthTemplate = (auth: GetTravelAuthorizations_travelAuthorizations) => (
      <AccordionItem key={auth.guid}>
        <AccordionToggle
          onClick={() => {
            setExpandedTravelAuth(expandedTravelAuth === auth.guid ? "" : auth.guid);
          }}
          isExpanded={expandedTravelAuth === auth.guid}
          id={`travel-auth-toggle-${auth.guid}`}
        >
          {auth.category.replace("_", " ")}
          {" - "}
          {moment(auth.startDate).format("MM/DD/YYYY")}
          {" to "}
          {moment(auth.endDate).format("MM/DD/YYYY")}
        </AccordionToggle>
        <AccordionContent isHidden={expandedTravelAuth !== auth.guid}>
          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h3" size={TitleSizes.xl}>
                Travelers ({auth.travelers.length})
              </Title>
            </FlexItem>
            <FlexItem>
              <List isPlain>
                {auth.travelers.map((t) => (
                  <ListItem icon={<UserIcon />} key={t.guid}>
                    <Link to={`/passengers/${t.guid}`}>
                      <p>
                        {t.firstName}
                        {" "}
                        <Tooltip
                          content={
                            <span>{t.middleName}</span>
                          }
                        >
                          <span>
                            {t.middleName?.substring(0, 1)}
                          </span>
                        </Tooltip>
                        {" "}
                        {t.lastName}
                        {" "}
                        {t.guid === traveler.guid && (
                          "(SELF)"
                        )}
                      </p>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </FlexItem>
          </Flex>
          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h3" size={TitleSizes.xl}>
                Travel Authorization Documents
              </Title>
            </FlexItem>
            <FlexItem>
              <DocumentManager
                documents={auth.travelAuthorizationDocuments}
                onDelete={doDeleteTravelerDocument}
                onCreate={(file) => { doCreateTravelAuthorizationDocument(auth, file); }}
                readonly
              />
            </FlexItem>
          </Flex>
          <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
            <FlexItem>
              <Title headingLevel="h3" size={TitleSizes.xl}>
                Travel Authorization Review History
              </Title>
            </FlexItem>
            <FlexItem>
              <CodeBlock>
                <CodeBlockCode>
                  {auth.reviewHistory.map((rh) => (
                    <p key={rh.guid}>
                      {moment(rh.createdAt).format("MM/DD/YYYY HH:mm:SS")}
                      {" "}
                      {rh.status.replace("_", " ")}
                    </p>
                  ))}
                </CodeBlockCode>
              </CodeBlock>
            </FlexItem>
          </Flex>

        </AccordionContent>
      </AccordionItem>
    );

    const flightTemplate = (flight: GetSpaceRequests_spaceRequests_flightStatus_flight) => {
      const bp = getBoardingPassForFlight(expandedFlight ?? "");
      return (
        <AccordionItem key={flight.guid}>
          <AccordionToggle
            onClick={() => {
              setExpandedFlight(expandedFlight === flight.guid ? "" : flight.guid);
            }}
            isExpanded={expandedFlight === flight.guid}
            id={`travel-auth-toggle-${flight.guid}`}
          >
            {flight.departureAirport.code}
            {" -> "}
            {flight.arrivalAirport.code}
            {" - "}
            {moment(flight.departureSchedule).format("MM/DD/YYYY HH:mm")}
          </AccordionToggle>
          <AccordionContent isHidden={expandedFlight !== flight.guid}>
            <Flex direction={{ default: "column" }} style={{ marginBottom: 20, }}>
              <FlexItem>
                <Title headingLevel="h3" size={TitleSizes.xl}>
                  Boarding Pass
                </Title>
                <div style={{ width: 150, height: 150, }}>
                  <Tooltip content={"Click to copy ID to clipboard"}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (bp === undefined)
                          return;
                        navigator.clipboard.writeText(bp.guid);
                      }}
                    >
                      <QRCode
                        size={150}
                        value={bp?.guid}
                        qrStyle="squares"
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        quietZone={0} />
                    </div>
                  </Tooltip>
                </div>
              </FlexItem>
              <FlexItem>
                <Title headingLevel="h3" size={TitleSizes.xl}>
                  Luggage
                </Title>
                {bp?.checkedLuggage.length === 0 ? (
                  <Empty title={"No Checked Luggage"} description={"There is no checked luggage registered for this flight."} />
                ) : (
                  <Flex direction={{ default: "row" }}>
                    {bp?.checkedLuggage.map((cl) => (
                      <FlexItem key={cl.guid}>
                        <Tooltip content={"Click to copy ID to clipboard"}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(cl.guid);
                            }}
                          >
                            <div style={{ width: 150, height: 150, borderRadius: 10, backgroundColor: "#f2f2f2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: 10, cursor: "pointer", }}>
                              {cl.photoFilePathS3 === null ? (
                                <>
                                  <ImageIcon size="lg" color="#a2a2a2" />
                                  <Text style={{ color: "#a2a2a2" }}>No Image</Text>
                                </>
                              ) : (
                                <>
                                  <img src={`${process.env.REACT_APP_S3_URL}/${cl.photoFilePathS3}`} style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 10, }} />
                                </>
                              )}
                            </div>
                          </div>
                        </Tooltip>
                      </FlexItem>
                    ))}
                  </Flex>
                )}
              </FlexItem>
            </Flex>
          </AccordionContent>
        </AccordionItem>
      );
    };

    const upcomingFlights = getFlights({ status: FlightStatusType.ROSTER, when: "FUTURE" });
    const pastFlights = getFlights({ status: FlightStatusType.ROSTER, when: "PAST" });
    return (
      <>

        {/* Include sponsored notice if sponsored */}
        {!travelerIsSponsor && (
          <Flex style={{ marginBottom: 100, }}>
            <FlexItem grow={{ default: "grow" }}>
              <Alert
                title="Sponsored Traveler"
              >
                <p>
                  This passenger is sponsored by another passenger.
                  Some sections of this page may be unavailable.
                </p>
              </Alert>
            </FlexItem>
          </Flex>
        )}

        <Panel style={{ position: "relative", padding: 20, marginTop: 50, }}>
          {/* Absolutely positioned center avatar */}
          <div style={{ position: "absolute", width: 128, height: 128, marginLeft: "auto", marginRight: "auto", left: 0, right: 0, top: -50, borderRadius: "100%", backgroundColor: "#FFFFFF", outline: "6px solid #FFFFFF", }}>
            <Avatar src={avatarImg} alt="avatar" size="xl" style={{}} />
            {/* Absolutely positioned edit button */}
            {/* <div style={{ position: "absolute", width: 40, height: 40, borderRadius: "100%", backgroundColor: "#FFFFFF", right: -5, top: -5, }}>
              <Link to={`/passengers/${guid}/edit`} style={{ color: "#000000" }}>
                <Flex justifyContent={{ default: "justifyContentCenter" }} alignItems={{ default: "alignItemsCenter" }} style={{ width: "100%", height: "100%" }}>
                  <FlexItem>
                    <PencilAltIcon />
                  </FlexItem>
                </Flex>
              </Link>
            </div> */}
          </div>

          <PanelMain>
            <PanelMainBody>

              {/* Header area */}
              <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }} style={{ marginTop: 50, marginBottom: 40, }}>
                <FlexItem style={{ marginBottom: 0, }}>
                  <Title headingLevel="h1" size={TitleSizes['4xl']}>
                    {traveler.firstName}
                    {" "}
                    {traveler.middleName?.substring(0, 1)}
                    {" "}
                    {traveler.lastName}
                  </Title>
                </FlexItem>
                {/* Include sponsor information if sponsored */}
                {!travelerIsSponsor && sponsorTraveler !== undefined && (
                  <FlexItem>
                    <Title headingLevel="h3" size={TitleSizes.md}>
                      Sponsored by
                      {" "}
                      <Link to={`/passengers/${sponsorTraveler.guid}`}>
                        {sponsorTraveler?.firstName} {sponsorTraveler?.lastName}
                      </Link>
                    </Title>
                  </FlexItem>
                )}
              </Flex>

              {/* All information about Traveler */}
              <Grid hasGutter span={12} style={{ marginBottom: 40, }}>
                {/* Personal Info */}
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Personal Information
                  </Title>
                  <HorizontalTable
                    rows={[
                      { title: "First Name", value: traveler.firstName, valueType: "string", onValueUpdated: (v) => { doUpdateTraveler({ firstName: v as string, }) }, },
                      { title: "Middle Name", value: traveler.middleName, valueType: "string", onValueUpdated: (v) => { doUpdateTraveler({ middleName: v as string, }) }, },
                      { title: "Last Name", value: traveler.lastName, valueType: "string", onValueUpdated: (v) => { doUpdateTraveler({ lastName: v as string, }) }, },
                      { title: "Email Address", value: traveler.email, valueType: "string", valueHref: `mailto:${traveler.email}`, onValueUpdated: (v) => { doUpdateTraveler({ email: v as string, }) }, },
                    ]}
                  />
                </GridItem>
                {/* Other information including government/military */}
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Service Information
                  </Title>
                  <HorizontalTable
                    rows={[
                      { title: "DOD ID", value: traveler.dodId, valueType: "string", onValueUpdated: (v) => { doUpdateTraveler({ dodId: v as string, }) }, },
                      ...(travelerIsSponsor ? [
                        {
                          title: "Service Branch",
                          value: sponsorUser?.rank?.service.replace("_", " "),
                        },
                        {
                          title: "Rank",
                          value: sponsorUser?.rank?.name,
                        },
                        {
                          title: "Grade",
                          value: sponsorUser?.rank?.grade,
                        },
                        {
                          title: "Retired",
                          value: sponsorUser?.retired ?? false,
                        },
                      ] : [
                        {
                          title: "Relationship to Sponsor",
                          value: traveler.sponsorRelationship as string,
                          // TODO figure out why typescript is complaining about the types here
                          // valueType: "string",
                          // onValueUpdated: (v: any) => { doUpdateTraveler({ sponsorRelationship: v as string, }) },
                        },
                      ]),
                    ]}
                  />
                </GridItem>
              </Grid>

              <Grid hasGutter span={12} style={{ marginBottom: 40, }}>
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Emergency Contact Information
                  </Title>
                  <HorizontalTable
                    rows={[
                      { title: "First Name", value: sponsorUser?.emergencyContact?.firstName, valueType: "string", onValueUpdated: (v) => { doUpdateEmergencyContact({ firstName: v as string }); }, },
                      { title: "Last Name", value: sponsorUser?.emergencyContact?.lastName, valueType: "string", onValueUpdated: (v) => { doUpdateEmergencyContact({ lastName: v as string }); }, },
                      { title: "Phone #", value: sponsorUser?.emergencyContact?.phone, valueType: "string", valueHref: `tel:+${sponsorUser?.emergencyContact?.phone}`, onValueUpdated: (v) => { doUpdateEmergencyContact({ phone: v as string }); }, },
                    ]}
                  />
                </GridItem>
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Accommodation Information
                  </Title>
                  <HorizontalTable
                    rows={[
                      { title: "Requires Larger Seat", value: traveler.accommodations?.requiresLargerSeat, valueType: "boolean", onValueUpdated: (v) => { doUpdateTravelerAccommodations({ requiresLargerSeat: v as boolean }); }, },
                      { title: "Requires Seatbelt Extension", value: traveler.accommodations?.requiresSeatBeltExtension, valueType: "boolean", onValueUpdated: (v) => { doUpdateTravelerAccommodations({ requiresSeatBeltExtension: v as boolean }); }, },
                      { title: "Requires Wheelchair Accessible Space", value: traveler.accommodations?.requiresWheelchairAccessibleSpace, valueType: "boolean", onValueUpdated: (v) => { doUpdateTravelerAccommodations({ requiresWheelchairAccessibleSpace: v as boolean }); }, },
                      { title: "Notes", value: traveler.accommodations?.notes, valueType: "string", onValueUpdated: (v) => { doUpdateTravelerAccommodations({ notes: v as string }); }, },
                    ]}
                  />
                </GridItem>
              </Grid>


              {travelerIsSponsor && (
                <>
                  {/* Travelers */}
                  <Flex direction={{ default: "column" }} style={{ marginBottom: 40, }}>
                    <FlexItem>
                      <Title headingLevel="h3" size={TitleSizes["2xl"]}>
                        Sponsored Travelers
                        <Button variant="plain" onClick={() => { doCreateTraveler(); }}>
                          <AddCircleOIcon size="md" />
                        </Button>
                      </Title>
                    </FlexItem>
                    <FlexItem>
                      <Flex style={{ rowGap: 20, }}>
                        <TravelerManager
                          travelers={sponsoredTravelers}
                          showRelationship
                          noneTitle="No Associated Travelers"
                          noneMessage="There are no travelers registered to this sponsor"
                        />
                      </Flex>
                    </FlexItem>
                  </Flex>


                  <Flex direction={{ default: "column" }} style={{ marginBottom: 40, }}>
                    <FlexItem>
                      <Title headingLevel="h3" size={TitleSizes["2xl"]}>
                        Travel Authorizations
                        <Button variant="plain" onClick={() => { setCreateTravelAuthModalVisible(true); }}>
                          <AddCircleOIcon size="md" />
                        </Button>
                      </Title>
                      <Tabs
                        activeKey={travelAuthTabIndex}
                        onSelect={(e, index) => { setTravelAuthTabIndex(index); setExpandedTravelAuth(undefined); }}
                        isBox={true}
                        aria-label="Travel Authorization tabs"
                        style={{ marginBottom: 0, }}
                      >
                        {/* Active Travel Authorizations */}
                        <Tab eventKey={0} title={<TabTitleText>Active</TabTitleText>}>
                          {sponsorTravelAuthorizations.approvedActive.length === 0 ? (
                            <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                              <Empty
                                title={"No Travel Authorizations Found"}
                                description={"There are no active travel authorizations for this passenger."}
                              />
                            </div>
                          ) : (
                            <Accordion asDefinitionList isBordered>
                              {sponsorTravelAuthorizations.approvedActive.map(travelAuthTemplate)}
                            </Accordion>
                          )}
                        </Tab>
                        {/* Pending Travel Authorizations */}
                        <Tab eventKey={1} title={<TabTitleText>Pending</TabTitleText>}>
                          {sponsorTravelAuthorizations.submitted.length === 0 ? (
                            <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                              <Empty
                                title={"No Travel Authorizations Found"}
                                description={"There are no pending travel authorizations for this passenger."}
                              />
                            </div>
                          ) : (
                            <Accordion asDefinitionList isBordered>
                              {sponsorTravelAuthorizations.submitted.map(travelAuthTemplate)}
                            </Accordion>
                          )}
                        </Tab>
                        {/* Rejected Travel Authorizations */}
                        <Tab eventKey={2} title={<TabTitleText>Rejected</TabTitleText>}>
                          {sponsorTravelAuthorizations.denied.length === 0 ? (
                            <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                              <Empty
                                title={"No Travel Authorizations Found"}
                                description={"There are no rejected travel authorizations for this passenger."}
                              />
                            </div>
                          ) : (
                            <Accordion asDefinitionList isBordered>
                              {sponsorTravelAuthorizations.denied.map(travelAuthTemplate)}
                            </Accordion>
                          )}
                        </Tab>
                        {/* Expired Travel Authorizations */}
                        <Tab eventKey={3} title={<TabTitleText>Expired</TabTitleText>}>
                          {sponsorTravelAuthorizations.approvedExpired.length === 0 ? (
                            <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                              <Empty
                                title={"No Travel Authorizations Found"}
                                description={"There are no expired travel authorizations for this passenger."}
                              />
                            </div>
                          ) : (
                            <Accordion asDefinitionList isBordered>
                              {sponsorTravelAuthorizations.approvedExpired.map(travelAuthTemplate)}
                            </Accordion>
                          )}
                        </Tab>
                      </Tabs>
                    </FlexItem>
                  </Flex>


                  <Flex direction={{ default: "column" }} style={{ marginBottom: 40, }}>
                    <FlexItem>
                    </FlexItem>

                    <Title headingLevel="h3" size={TitleSizes["2xl"]}>
                      Traveler Documents
                      <Button variant="plain" onClick={() => { }}>
                        <AddCircleOIcon size="md" />
                      </Button>
                    </Title>

                    <DocumentManager
                      documents={traveler.travelerDocuments}
                      onDelete={doDeleteTravelerDocument}
                      onCreate={doCreateTravelerDocument}
                    />
                  </Flex>
                </>
              )}

              {/* Upcoming trips */}
              <Grid hasGutter span={12}>
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Upcoming Flights
                    <Button variant="plain" onClick={() => { setRegisterFlightModalVisible(true); }}>
                      <AddCircleOIcon size="md" />
                    </Button>
                  </Title>
                  {getFlights({ status: FlightStatusType.ROSTER, when: "FUTURE" }).length === 0 ? (
                    <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                      <Empty
                        title={"No Flights Found"}
                        description={"There are no upcoming flights for this passenger."}
                      />
                    </div>
                  ) : (
                    <Accordion asDefinitionList isBordered>
                      {upcomingFlights.map(flightTemplate)}
                    </Accordion>
                  )}
                </GridItem>
                <GridItem sm={12} xl={6}>
                  <Title headingLevel="h1" size={TitleSizes["3xl"]} style={{ marginBottom: 10, }}>
                    Previous Flights
                  </Title>
                  {getFlights({ status: FlightStatusType.ROSTER, when: "PAST" }).length === 0 ? (
                    <div style={{ border: "1px solid #d2d2d2", backgroundColor: "#f0f0f1", }}>
                      <Empty
                        title={"No Flights Found"}
                        description={"There are no previous flights for this passenger."}
                      />
                    </div>
                  ) : (
                    <Accordion asDefinitionList isBordered>
                      {pastFlights.map(flightTemplate)}
                    </Accordion>
                  )}
                </GridItem>
              </Grid>



            </PanelMainBody>
          </PanelMain>
          <PanelFooter style={{ marginTop: 100, }}>
            {!travelerIsSponsor && (
              <Flex direction={{ default: "column" }} alignItems={{ default: "alignItemsCenter" }}>
                <FlexItem>
                  <Button
                    variant="link"
                    isDanger
                    onClick={() => {
                      if (confirm("Are you sure you want to delete this traveler?")) {
                        doDeleteTraveler();
                      }
                    }}
                  >
                    Delete Traveler
                  </Button>
                </FlexItem>
              </Flex>
            )}
          </PanelFooter>
        </Panel>
      </>
    );
  };

  return (
    <PageContainer>
      <PageSection>

        {createTravelAuthModalVisible && (
          <CreateTravelAuthorizationModal
            sponsorGuid={sponsorUser?.guid}
            onClose={() => {
              setCreateTravelAuthModalVisible(false);
            }}
            onCreated={() => {
              refetchTraveler();
            }}
          />
        )}

        {profileTemplate()}
      </PageSection>
    </PageContainer>
  );
}
