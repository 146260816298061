import { gql } from "@apollo/client";

export const GET_MISSIONS_QUERY = gql`
query GetMissions($guids: [String], $pageNumber: Int, $resultsPerPage: Int) {
  missions(guids: $guids, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid
    createdAt
    lastUpdatedAt

    description
    externalId

    flights {
      guid
      spaceAvailable
      aircraft {
        name
        designation
        manufacturer {
          name
        }
      }
      departureSchedule
      departureActual
      departureAirport {
        guid
        name
        code
      }
      arrivalSchedule
      arrivalActual
      arrivalAirport {
        guid
        name
        code
      }
      rollCallTime
    }
  }
}
`;

export const CREATE_MISSION_QUERY = gql`
mutation CreateMission($externalId: String!, $description: String) {
    createMission(externalId: $externalId, description: $description)
}
`;

export const UPDATE_MISSION_QUERY = gql`
mutation UpdateMission($guid: String!, $externalId: String, $description: String) {
    updateMission(guid: $guid, externalId: $externalId, description: $description)
}
`;

export const DELETE_MISSION_QUERY = gql`
mutation DeleteMission($guid: String!) {
    deleteMission(guid: $guid)
}
`;
