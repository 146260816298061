import { EmptyState, EmptyStateIcon, Title, EmptyStateBody, Button, EmptyStateSecondaryActions } from "@patternfly/react-core";
import { CubesIcon } from "@patternfly/react-icons";

interface Props {
    title: string;
    description: string;
    action?: JSX.Element;
}

export default (props: Props) => {
    return (
        <EmptyState>
            <EmptyStateIcon icon={CubesIcon} />
            <Title headingLevel="h4" size="lg">
                {props.title}
            </Title>
            <EmptyStateBody>
                {props.description}
            </EmptyStateBody>
            {props.action}
        </EmptyState>
    );
};