import { useQuery } from "@apollo/client";
import {
  Button, Flex, FlexItem, Label, OverflowMenu, OverflowMenuContent, OverflowMenuGroup, OverflowMenuItem, PageSection, SearchInput
} from "@patternfly/react-core";
import { TableComposable, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Link } from "@reach/router";
import _ from "lodash";
import { createRef, useEffect, useState } from "react";
import PageContainer from "../Components/PageContainer";
import { GET_TRAVELERS_QUERY } from "../graphql/queries";
import { GetTravelers, GetTravelers_travelers, SponsorRelationship } from "../__generated__/api";


enum FilterMode {
  All,
  Sponsors,
  Sponsees,
}


export default function Passengers() {
  const [allPassengers, setAllPassengers] = useState<GetTravelers_travelers[]>([]);

  const [filteredPassengers, setFilteredPassengers] = useState<GetTravelers_travelers[]>([]);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.All);
  const [passengerNameSearchValue, setPassengerNameSearchValue] = useState<string>("");
  const [passengerIdSearchValue, setPassengerIdSearchValue] = useState<string>("");
  const [passengerEmailSearchValue, setPassengerEmailSearchValue] = useState<string>("");

  const passengerNameSearchRef = createRef<HTMLInputElement>();

  const { loading: fetchingTravelers, refetch: refetchTravelers, } = useQuery<GetTravelers>(
    GET_TRAVELERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      pollInterval: 1000,
      onCompleted: (resp) => {
        setAllPassengers(resp.travelers);
      },
    }
  );

  const getSponsorAsTraveler = (traveler: GetTravelers_travelers): GetTravelers_travelers | undefined => {
    const allSponsors = allPassengers.filter((p) => p.sponsorRelationship === SponsorRelationship.SELF);
    return allSponsors.find((sp) => sp.sponsor?.guid === traveler.sponsor?.guid);
  };

  const getAllSponsors = () => allPassengers.filter((p) => p.sponsorRelationship === SponsorRelationship.SELF);
  const getAllSponsees = () => allPassengers.filter((p) => p.sponsorRelationship !== SponsorRelationship.SELF);

  const getFilteredPassengers = (mode: FilterMode) => {
    let newFiltered = [];
    switch (mode) {
      case FilterMode.All:
        newFiltered = allPassengers;
        break;
      case FilterMode.Sponsors:
        newFiltered = getAllSponsors();
        break;
      case FilterMode.Sponsees:
        newFiltered = getAllSponsees();
        break;
    }

    let newSorted = _.orderBy(
      newFiltered,
      ["firstName"],
      ["asc"]
    );


    if (passengerNameSearchValue !== "") {
      newSorted = newSorted.filter((p) => {
        return (
          `${p.firstName.toLowerCase()} ${p.middleName?.toLowerCase()} ${p.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
          || `${p.firstName.toLowerCase()} ${p.lastName.toLowerCase()}`.includes(passengerNameSearchValue.toLowerCase())
        );
      });
    }
    if (passengerIdSearchValue !== "") {
      newSorted = newSorted.filter((p) => p.dodId.includes(passengerIdSearchValue));
    }
    if (passengerEmailSearchValue !== "") {
      newSorted = newSorted.filter((p) => p.email.includes(passengerEmailSearchValue.toLowerCase()));
    }

    return newSorted;
  };

  useEffect(() => {
    passengerNameSearchRef.current?.focus();
  }, []);

  useEffect(() => {
    const newSorted = getFilteredPassengers(filterMode);
    setFilteredPassengers(newSorted);
  }, [allPassengers]);

  useEffect(() => {
    let newFiltered = getFilteredPassengers(filterMode);
    setFilteredPassengers(newFiltered);
  }, [passengerNameSearchValue, passengerIdSearchValue, passengerEmailSearchValue]);

  const filterDisplayedData = (mode: FilterMode) => {
    setFilterMode(mode);
    setFilteredPassengers(getFilteredPassengers(mode));
    passengerNameSearchRef.current?.focus();
  };

  return (
    <PageContainer>
      <PageSection>

        <Flex style={{ marginBottom: 10 }}>
          <FlexItem>
            <Label>Quick Filters</Label>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.All ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.All);
              }}
            >
              All ({getFilteredPassengers(FilterMode.All).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.Sponsors ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.Sponsors);
              }}
            >
              Sponsors ({getFilteredPassengers(FilterMode.Sponsors).length})
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={filterMode === FilterMode.Sponsees ? "primary" : "secondary"}
              onClick={() => {
                filterDisplayedData(FilterMode.Sponsees);
              }}
            >
              Sponsored ({getFilteredPassengers(FilterMode.Sponsees).length})
            </Button>
          </FlexItem>
        </Flex>
        <Flex style={{ marginBottom: 10, }}>
          <FlexItem>
            <Label>Search</Label>
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by name"
              value={passengerNameSearchValue}
              onChange={(newVal) => { setPassengerNameSearchValue(newVal); }}
              onClear={() => { setPassengerNameSearchValue(""); }}
              ref={passengerNameSearchRef}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by DOD ID"
              value={passengerIdSearchValue}
              onChange={(newVal) => { setPassengerIdSearchValue(newVal); }}
              onClear={() => { setPassengerIdSearchValue(""); }}
            />
          </FlexItem>
          <FlexItem>
            <SearchInput
              placeholder="Search by email"
              value={passengerEmailSearchValue}
              onChange={(newVal) => { setPassengerEmailSearchValue(newVal); }}
              onClear={() => { setPassengerEmailSearchValue(""); }}
            />
          </FlexItem>
        </Flex>
        <TableComposable
          aria-label="Passengers Table"
          variant={"compact"}
          borders
        >
          <Thead className="pf-m-fit-content">
            <Tr>
              <Th>First Name</Th>
              <Th>Middle Name</Th>
              <Th>Last Name</Th>
              <Th>DOD ID</Th>
              <Th>Email</Th>
              <Th>Sponsor</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPassengers.map((passenger) => {
              const sponsorAsTraveler = getSponsorAsTraveler(passenger);
              return (
                <Tr key={passenger.guid}>
                  <Td>{passenger.firstName}</Td>
                  <Td>{passenger.middleName}</Td>
                  <Td>{passenger.lastName}</Td>
                  <Td>{passenger.dodId}</Td>
                  <Td>{passenger.email}</Td>
                  <Td style={{ fontWeight: sponsorAsTraveler?.guid === passenger.guid ? "bold" : undefined }}>
                    {sponsorAsTraveler?.firstName + " " + sponsorAsTraveler?.lastName}
                  </Td>
                  <Td isActionCell>
                    <OverflowMenu breakpoint="lg">
                      <OverflowMenuContent>
                        <OverflowMenuGroup groupType="button">
                          <OverflowMenuItem>
                            <Link to={`/passengers/${passenger.guid}`}>
                              <Button isSmall>View Profile</Button>
                            </Link>
                          </OverflowMenuItem>
                        </OverflowMenuGroup>
                      </OverflowMenuContent>
                    </OverflowMenu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableComposable>
      </PageSection>
    </PageContainer>
  );
}
