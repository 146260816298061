import { gql } from "@apollo/client";

export const GET_TRAVEL_AUTHORIZATIONS_QUERY = gql`
query GetTravelAuthorizations($guids: [String], $ownerGuid: String, $pageNumber: Int, $resultsPerPage: Int) {
  travelAuthorizations(guids: $guids, ownerGuid: $ownerGuid, pageNumber: $pageNumber, resultsPerPage: $resultsPerPage) {
    guid

    category

    leaveStatusDate
    startDate
    endDate

    reviewHistory {
      guid
      createdAt

      status
      message
    }

    travelAuthorizationDocuments {
      guid
      
      fileName
      fileMimeType
      filePathS3

      documentId
          
      createdAt
      dateExpires

      reviewHistory {
        guid
        status
        message
      }
    }

    sponsor {
      guid
      travelers {
        guid
        email
        firstName
        middleName
        lastName
        dodId
        sponsorRelationship
      }
    }

    travelers {
      guid
      email
      firstName
      middleName
      lastName
      dodId
      sponsorRelationship
    }
  }
}
`;


export const CREATE_TRAVEL_AUTHORIZATION_MUTATION = gql`
mutation CreateTravelAuthorization(
  $sponsorGuid: String!,
  $travelerGuids: [String]!,
  $leaveStatusDate: DateTime!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $category: TravelAuthorizationCategory!,
  $forceStatus: ReviewStatus,
) {
  createTravelAuthorization(
    sponsorGuid: $sponsorGuid,
    travelerGuids: $travelerGuids,
    leaveStatusDate: $leaveStatusDate,
    startDate: $startDate,
    endDate: $endDate,
    category: $category,
    forceStatus: $forceStatus
  )
}
`;


export const CREATE_TRAVEL_AUTHORIZATION_DOCUMENT_MUTATION = gql`
mutation CreateTravelAuthorizationDocument(
  $travelAuthorizationGuid: String!,
  $file: Upload!
) {
  createTravelAuthorizationDocument(
    travelAuthorizationGuid: $travelAuthorizationGuid,
    file: $file
  )
}
`;


export const UPDATE_TRAVEL_AUTHORIZATION_STATUS_QUERY = gql`
mutation UpdateTravelAuthorizationStatus($guid: String!, $status: ReviewStatus!, $reviewerGuid: String, $statusMessage: String) {
  updateTravelAuthorizationStatus(guid: $guid, status: $status, reviewerGuid: $reviewerGuid, statusMessage: $statusMessage)
}
`;
